import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Box, Button, useTheme, TableFooter, TablePagination, Grid, TableContainer, InputBase, IconButton, Typography,
  Divider, Fade, Modal, Table, TableHead, TableBody, TableRow, TableCell, Autocomplete, Checkbox, FormControlLabel
} from "@mui/material";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { tokens } from "../../theme";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import api from "../../components/confs";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from 'prop-types';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import CloseIcon from '@mui/icons-material/Close';

import TextBox from '../../components/TextBox';


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="Primeira página"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Anterior"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Próxima"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Última página"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};



const Historic = ({ id }) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(0);
  const rowsPerPage = 8;
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [messageModalName, setMessageName] = useState("");
  const [messageModal, setOpenMessage] = useState(false);
  const [search_name_team, setSearchNameTeam] = useState("");
  const [severity, setSeverity] = useState("success")
  const [associations, setAssociations] = useState([]);
  const [associationSelected, setAssociationSelected] = useState(null);
  const [teams, setTeams] = useState([]);
  const [teamSelected, setTeamSelected] = useState({});
  const [seasons, setSeasons] = useState([]);
  const [seasonSelected, setSeasonSelected] = useState({});
  const [actual, setActual] = useState(false);
  const [transfer, setTransfer] = useState([]);
  const [gradeSelected, setGradeSelected] = useState({});
  const [grades, setGrades] = useState([]);


  function submitNewTransfer() {
    setLoader(true);
    const data = {
      "player": parseInt(id),
      "team": teamSelected,
      "season": seasonSelected,
      "grade": gradeSelected,
      "actual": actual
    }
    api.post('/api/player/transfer', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {
        setSeverity("success")
        setMessageName("Transferência criada!")
        setOpenMessage(true);
        setOpenTransferModal(false);
      })
      .catch(error => {
        setSeverity("error")
        setMessageName(error)
        setOpenMessage(true);
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      })
      .finally(() => {
        //get_teams_data();
        setLoader(false);

      });
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  //--------------------------- ON START ---------------------------//


  useEffect(() => {
    get_teams_data();
    get_associations();
    get_all_seasons();
    get_grades();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);



  function get_associations() {
    api.get('/api/associations', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {
        setAssociations(response.data);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }



  //--------------------------*----------------------------------//

  //------------------- Search Teams ---------------------------//

  useEffect(() => {
    get_teams_data();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [search_name_team]);

  const get_teams_data = () => {
    const data = {};
    if (search_name_team.length > 0) {
      data.name =  search_name_team ;
    };
    setPage(0)
    api.get('/api/player/transfer/' + id,
      {
        params: data,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then(response => {
        setTransfer(response.data);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      }).finally(() => {

      });
  }
  //-----------------------------------------------------------//


  useEffect(() => {
    if (associationSelected !== null) {
      api.get('/api/teams/search',
        {
          params: { name: " ", association: associationSelected },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        })
        .then(response => {
          setTeams(response.data);
        })
        .catch(error => {
          console.error(error);
          if (error.response.data.detail === "unauthorized login") {
            setToken(null)
          }
        }).finally(() => {

        });
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [associationSelected])

  const get_all_seasons = () => {
    api.get('/api/seasons', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then((response) => {
        setSeasons(response.data)
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }


  const get_grades = () => {
    api.get('/api/grades', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {
        setGrades(response.data);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }


  return (
    <>

      <Loader loader={loader} />
      <Message messageModal={messageModal} messageModalName={messageModalName} severity={severity} setOpenMessage={setOpenMessage} />

      <Grid container spacing={2}>
        <Grid item xs={9} mt={2}>

          <Box
            display="flex"
            backgroundColor={colors.primary[400]}
            borderRadius="3px"
          >
            <InputBase sx={{ ml: 2, mt: 1, mb: 1, flex: 1 }} placeholder="Procurar Equipa"
              onChange={event => {
                setSearchNameTeam(event.target.value);
              }}
              value={search_name_team || ''}
              inputProps={{ style: { fontSize: 14 } }} />
            <IconButton type="button" sx={{ p: 1 }}>
              <SearchIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={3} mt={2}>

          <Box style={{ textAlign: 'right' }} >
            <Button
              fullWidth
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
              onClick={() => { setOpenTransferModal(true) }}
            >
              <SyncAltIcon sx={{ mr: "10px" }} />
              Nova Transferência
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} mt={2}>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Fotografia</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Época</TableCell>
                  <TableCell>Escalão</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? transfer.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : transfer
                ).map((row, i) => (
                  <TableRow key={"team-" + i}>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <img alt={row.team} src={process.env.REACT_APP_API_BASE_URL + "team/photo/" + row.team_id} style={{ margin: '0 auto', display: 'block' }} height={40} />
                    </TableCell>
                    <TableCell>{row.team}</TableCell>
                    <TableCell>{row.season}</TableCell>
                    <TableCell>{row.grade}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    //rowsPerPageOptions={[5, 6,7,8,9, 10, 25, { label: 'Tudo', value: -1 }]}
                    rowsPerPageOptions={[8]}
                    colSpan={5}
                    count={transfer.length}
                    //rowsPerPage={rowsPerPage1}
                    rowsPerPage={8}

                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'Linhas por página',
                      },
                      native: true,
                    }}
                    labelRowsPerPage='Linhas por página'
                    onPageChange={handleChangePage}
                    //onRowsPerPageChange={handleChangeRowsPerPage1}
                    ActionsComponent={TablePaginationActions}
                    labelDisplayedRows={(page) =>
                      `${page.from}-${page.to === -1 ? page.count : page.to} de ${page.count
                      }`
                    }
                  >

                  </TablePagination>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>



      {/** Team Modal */}
      < Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openTransferModal}
        onClose={() => { setOpenTransferModal(false); }}
        closeAfterTransition
      >
        <Fade in={openTransferModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 800,
              height: 700,
              bgcolor: colors.primary[400],
              boxShadow: 24,
              p: 4,
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{
                paddingBottom: '10px',
                borderStyle: 'solid',
                borderColor: colors.blueAccent[300],
                borderWidth: '0 0 2px 0'
              }}>
                <Typography component={'span'} variant="h3">Criação de Transferência</Typography>
                <IconButton
                  aria-label="close"
                  onClick={() => { setOpenTransferModal(false); }}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Divider flexItem variant="middle" />
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Autocomplete
                      options={associations}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <TextBox {...params} label="Associação" variant="outlined" />
                      )}
                      onChange={(event, value) => { setAssociationSelected(value.id) }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      options={teams}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <TextBox {...params} label="Equipa" variant="outlined" />
                      )}
                      onChange={(event, value) => { setTeamSelected(value.id) }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      options={seasons}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <TextBox {...params} label="Época" variant="outlined" />
                      )}
                      onChange={(event, value) => { setSeasonSelected(value.id) }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      options={grades}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <TextBox {...params} label="Escalão" variant="outlined" />
                      )}
                      onChange={(event, value) => { setGradeSelected(value.id) }}
                    />
                  </Grid>
                  <Grid item xs={6}>

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={actual}
                          onChange={() => { setActual(!actual) }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label="Atual"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              pt={4}
              spacing={2}
            >
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => { setOpenTransferModal(false); }}>Cancelar</Button>
              </Grid>
              <Grid item>
                <Button onClick={() => { submitNewTransfer(); }} variant="contained" color="success" startIcon={<AddOutlinedIcon />} >Criar</Button>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal >
    </>


  );
};

export default Historic;