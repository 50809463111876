import React from "react";
import App from "./App";
import { BrowserRouter } from 'react-router-dom';

import { UserProvider } from "./context/UserContext";



import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <UserProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </UserProvider>
  </React.StrictMode>);
