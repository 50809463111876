import { Box, Button, MenuItem } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextBox from '../../components/TextBox';
import Slide from '@mui/material/Slide';
import api from "../../components/confs";
import { useNavigate } from "react-router-dom";

const Editprofile = (props) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [open, setOpen] = useState(false);
  const [first_name, setFirstName] = useState("")
  const [last_name, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [locality, setLocal] = useState("")
  const [birthday, setBirth] = useState("")
  const [sex, setSex] = useState("")

  const navigate = useNavigate();

  useEffect(() => {
    setFirstName(user.first_name);
    setLastName(user.last_name);
    setEmail(user.email);
    setPhone(user.phone);
    setLocal(user.locality);
    setBirth(dateConvert(user.birthday));
    setSex(user.sex);
  }, [user]);

  const handleClickOpen = (event) => {
    event.preventDefault();
    if (first_name !== ""
      && last_name !== ""
      && email !== ""
      && emailRegExp.test(email)
      && phone !== ""
      && phoneRegExp.test(phone)
      && sex !== ""
      && locality !== ""
    ) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };


  const submitUpdate = async () => {
    const headers = {
      "accept": "application/json",
      Authorization: "Bearer " + token,
    };
    const data =
    {
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone: phone,
      locality: locality,
      birthday: "2023-01-01 11:54:04.807",
      sex: sex,
      photo: "",
      description: "",
      is_active: user.is_active,
      is_admin: user.is_admin,
      group_id: user.group_id
    };
    api.put("/api/users/" + user.id, data, { headers: headers })
      .then(response => { 
        navigate('/profile')
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  };


  return (
    <>
      <Box m="20px" sx={{
        position: "block",
        marginLeft: props.collapse === true ? 15 : 40
      }}>
        <Header title="Editar perfil" subtitle="Editar o seu perfil" />


        <form onSubmit={handleClickOpen} >
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >


            <TextBox
              fullWidth
              variant="filled"
              type="text"
              label="Primeiro nome"
              onChange={(e) => setFirstName(e.target.value)}
              value={first_name || ""}
              name="first_name"
              sx={{ gridColumn: "span 2" }}
              error={first_name === ""}
              helperText={first_name === "" ? 'Campo vazio' : ' '}
            />


            <TextBox
              fullWidth
              variant="filled"
              type="text"
              label="Último nome"
              onChange={(e) => setLastName(e.target.value)}
              value={last_name || ""}
              name="last_name"
              sx={{ gridColumn: "span 2" }}
              error={last_name === ""}
              helperText={last_name === "" ? 'Campo vazio' : ' '}
            />



            <TextBox
              fullWidth
              variant="filled"
              type="text"
              label="Email"
              value={email || ""}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              sx={{ gridColumn: "span 4" }}
              error={!emailRegExp.test(email)}
              helperText={email === "" ? "Campo vazio" : (emailRegExp.test(email) ? "" : "E-mail inválido")}

            />

            <TextBox
              fullWidth
              variant="filled"
              type="text"
              label="Telemóvel"
              value={phone || ""}
              onChange={(e) => setPhone(e.target.value)}
              name="phone"
              sx={{ gridColumn: "span 4" }}
              error={!phoneRegExp.test(phone)}
              helperText={phone === "" ? "Campo vazio" : (phoneRegExp.test(phone) ? "" : "Telemóvel inválido")}
            />

            <TextBox
              id="outlined-select-currency"
              variant="filled"
              select
              label="Sexo"
              value={sex || ""}
              onChange={(e) => { setSex(e.target.value); }}
              sx={{ gridColumn: "span 2" }}
              error={sex === ""}
              helperText={sex === "" ? "Campo vazio" : ""}
            >
              {genres.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextBox>


            <TextBox
              id="datetime-local"
              variant="filled"
              label={"Data de nascimento"}
              type="date"
              value={birthday}
              onChange={(e) => setBirth(e.target.value)}
              sx={{ gridColumn: "span 2" }}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextBox
              fullWidth
              variant="filled"
              type="text"
              label="Localidade"
              value={locality || ""}
              onChange={(e) => setLocal(e.target.value)}
              name="locality"
              sx={{ gridColumn: "span 2" }}
              error={locality === ""}
              helperText={locality === "" ? "Campo vazio" : ""}
            />


          </Box>

          <Box display="flex" justifyContent="end" mt="20px">
            <Button type="submit" color="secondary" variant="contained">
              Editar perfil
            </Button>
          </Box>

        </form>

      </Box>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >
          <DialogTitle id="alert-dialog-title" sx={{ "fontSize": 25 }}>
            {"Deseja atualizar os seus dados?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Isto não é revertível.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={handleClose} sx={{ "fontSize": 15 }}>Não</Button>
            <Button variant="contained" color="secondary" onClick={submitUpdate} autoFocus sx={{ "fontSize": 15 }}> Sim </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const dateConvert = (originalDate) => {
  const date = new Date(originalDate);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  if (month < 10) {
    month = `0${month}`;
  }

  if (day < 10) {
    day = `0${day}`;
  }

  return `${year}-${month}-${day}`
}

const genres = [
  {
    value: 'masc',
    label: 'Masculino',
  },
  {
    value: 'fem',
    label: 'Feminino',
  },
  {
    value: 'other',
    label: 'Outro',
  },
  {
    value: '',
    label: '',
  }
];
const phoneRegExp = /^[0-9]{9,12}$/;

const emailRegExp = /^\S+@\S+\.\S+$/;



export default Editprofile;




