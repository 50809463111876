import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import {
    Box,   useTheme, Tab, Tabs, Typography,   Grid, TableRow, TableBody, Table, TableContainer, TableCell
} from "@mui/material";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import { styled } from "@mui/material/styles"; 

import { tokens } from "../../theme";
import countries from "../../components/countries";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import api from "../../components/confs";

import ScaleIcon from '@mui/icons-material/Scale';
import HeightIcon from '@mui/icons-material/Height';
import CakeTwoToneIcon from '@mui/icons-material/CakeTwoTone';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import FootballFieldToChoose from '../../components/FootballFieldToChoose'

const ReportFinish = (props) => {
    // eslint-disable-next-line
    const { token, setToken, user } = useContext(UserContext);
    const theme = useTheme();
    const { id } = useParams();

    const colors = tokens(theme.palette.mode);
    const [loader, setLoader] = useState(false);
    
    const [state, setState] = useState({});
    const [selectedTab, setSelectedTab] = useState(0);
    const [others, setOthers] = useState("");
    const [attributes, setattributes] = useState([]);

    useEffect(() => {
        get_report_data();
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, []);

    const StyledTab = styled((props) => <Tab {...props} />)({
        "&.Mui-selected": {
            color: colors.blueAccent[500],
            fontSize: 16
        }
    });
    function findCodeByLabel(label) {
        const country = countries.find((country) => country.label === label);

        if (country) {
            return country.code;
        } else {
            return null;
        }
    }
    const navigate = useNavigate();

    const get_report_data = () => {
        setLoader(true);
        api.get('/api/report?id=' + id,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                
                setState(response.data);
                if (response.data.report.outros != null && response.data.report.outros !== "0" && response.data.report.outros !== "") {
                    setOthers(response.data.report.outros)
                }
                setattributes(response.data.attributes)
            }).catch(error => {
                console.error(error);
                //if (error.response.data.detail === "unauthorized login") {
                //    setToken(null);
                //}
                navigate('/report');
            }).finally(() => {
                setLoader(false);
            });
    }

    function calcularIdade(dataNascimento) {
        if(dataNascimento==null){
            return "-"
        }
        var dataAtual = new Date();
        var anoAtual = dataAtual.getFullYear();
        var mesAtual = dataAtual.getMonth() + 1;
        var diaAtual = dataAtual.getDate();

        var partesData = dataNascimento.split('-');
        var anoNascimento = parseInt(partesData[0]);
        var mesNascimento = parseInt(partesData[1]);
        var diaNascimento = parseInt(partesData[2]);

        var idade = anoAtual - anoNascimento;

        if (mesAtual < mesNascimento || (mesAtual === mesNascimento && diaAtual < diaNascimento)) {
            idade--;
        }

        return idade;
    }
    function obterData(data) {
        var dataObj = new Date(data);
        var dia = dataObj.getDate();
        var mes = dataObj.getMonth() + 1; // Lembrando que os meses são indexados em 0
        var ano = dataObj.getFullYear();
        return dia + '/' + mes + '/' + ano;
    }
    function obterHora(data) {
        var dataObj = new Date(data);
        var hora = dataObj.getHours().toString().padStart(2, '0');
        var minuto = dataObj.getMinutes().toString().padStart(2, '0');
        return hora + ':' + minuto;
    }

    function changePosition(p) {
        setState(
            (prevObject) => ({
                ...prevObject,
                report: {
                    ...prevObject.report,
                    posicao: p
                }
            }));
        //updateAttributes(p);
    }

    const updateAttributes = (position) => {
        var stringbool = "false";
        if (position === 1 || position === "1") { stringbool = "true" }
        setLoader(true);
        if (position < 1 || position > 11) { setLoader(false); return; }
        api.get('/api/attributes/active?grade_id=' + state.player_grade.id + '&goalkeeper=' + stringbool,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                var new_list = [];
                for (var i = 0; i < response.data.length; i++) {
                    var att = findValueByAttribute(response.data[i].attribute_id);
                    var value = {
                        "classe_id": response.data[i].classe_id,
                        "classe_name": response.data[i].classe_name,
                        "attribute_id": response.data[i].attribute_id,
                        "attribute_name": response.data[i].attribute_name,
                        "value": "",
                    };
                    if (att) {
                        value = {
                            "classe_id": response.data[i].classe_id,
                            "classe_name": response.data[i].classe_name,
                            "attribute_id": response.data[i].attribute_id,
                            "attribute_name": response.data[i].attribute_name,
                            "value": String(att),
                        };
                    }
                    new_list.push(value);
                }
                setattributes(new_list)
            }).catch(error => {
                console.error(error)
                if (error.response.data.detail && error.response.data.detail === "unauthorized login") {
                    setToken(null)
                }
            }).finally(() => {
                setLoader(false);
            });
    }

    function findValueByAttribute(attribute_id) {

        for (var i = 0; i < attributes.length; i++) {
            var objeto = attributes[i];
            if (objeto.attribute_id === attribute_id && objeto.value) {
                return objeto.value;
            }
        }
        return null; // Caso o nome não seja encontrado na lista
    }

    function getClasses(data) {
        const uniqueClasseIds = [...new Set(data.map(obj => obj.classe_id))];
        const uniqueClasseObjects = uniqueClasseIds.map(id => {
            const classeObj = data.find(obj => obj.classe_id === id);
            return { id: classeObj.classe_id, name: classeObj.classe_name };
        });
        return uniqueClasseObjects
    }

    function getAttribute(id) {
        return attributes.filter(objeto => objeto.classe_id === id);
    }





    function changeTab(tab) {
        if (tab === 1 && state.report.posicao !== null && state.report.posicao !== 0) {
            updateAttributes(state.report.posicao)
        }
        setSelectedTab(tab)
    }
    return (
        <>
            <Loader loader={loader} /> 
            <Box m="20px" sx={{
                position: "block",
                marginLeft: props.collapse === true ? 15 : 40
            }}>
                {/* HEADER */}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title="Relatório de Observação" />
                </Box>
                <Grid container mt={4}>
                    <Grid item xs={2}>
                        {state.player && (
                            <Grid container direction="column">
                                <Grid item mt={2} xs={12}>
                                    <img alt={state.name} src={process.env.REACT_APP_API_BASE_URL+"api/photo/player/" + id}
                                        style={{
                                            display: 'block',
                                            maxWidth: 190,
                                            maxHeight: 250,
                                            width: 'auto',
                                            height: 'auto',
                                            objectFit: 'cover',
                                        }}
                                        loading="lazy" />
                                </Grid>
                                <Grid item mt={2} xs={12}>
                                    <Typography variant="h4">{state.player.complete_name}</Typography>
                                    <Typography variant="h6">{state.player.shirt_name + " - " + state.player.shirt_number}</Typography>
                                </Grid>

                                {findCodeByLabel(state.player.naturality) &&
                                    <Grid item mt={2} xs={12}>
                                        <Box display="flex" alignItems="center">
                                            <img
                                                loading="lazy"
                                                width="20"
                                                src={`https://flagcdn.com/w20/${findCodeByLabel(state.player.naturality).toLowerCase()}.png`}
                                                srcSet={`https://flagcdn.com/w40/${findCodeByLabel(state.player.naturality).toLowerCase()}.png 2x`}
                                                alt=""
                                            />
                                            <Box ml={1}>{"     " + state.player.naturality}</Box>
                                        </Box>
                                    </Grid>
                                }

                                <Grid item xs={12} mt={1}>
                                    <Box display="flex" alignItems="center">
                                        <CakeTwoToneIcon />
                                        <Typography variant="h5" ml={1}>
                                            {state.player.birthday + " (" + calcularIdade(state.player.birthday) + " anos)"}

                                        </Typography>
                                    </Box>

                                </Grid>

                                <Grid item xs={12} mt={1}>
                                    <Box display="flex" alignItems="center">
                                        <ScaleIcon />
                                        <Box ml={1}>{(state.player.weight) + "Kg - " + state.player.weight_last_update}</Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} mt={1}>
                                    <Box display="flex" alignItems="center">
                                        <HeightIcon />
                                        <Box ml={1}>{(state.player.height) + "cm - " + state.player.height_last_update}</Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={10}>
                        {(state.report && state.game) && (
                            <Grid container>
                                <Grid item xs={8}>
                                    <Grid container>
                                        <Grid item xs={12}  >
                                            <Typography variant="h4">{state.game.team1} X {state.game.team2} </Typography>
                                            <Typography variant="h5">{state.game.grade} - {state.game.type_of_game} </Typography>
                                        </Grid>
                                        <Grid item xs={12} mt={1}>
                                            <Box display="inline-flex" >
                                                <CalendarMonthOutlinedIcon />
                                                <Typography variant="h5" ml={1}>
                                                    {obterData(state.game.date)}
                                                </Typography>
                                            </Box>
                                            <Box display="inline-flex" ml={1}>
                                                <AccessTimeOutlinedIcon />
                                                <Typography variant="h5" ml={1}>
                                                    {obterHora(state.game.date)}
                                                </Typography>
                                            </Box>
                                            <Box display="inline-flex" ml={1}>
                                                <FmdGoodOutlinedIcon />
                                                <Typography variant="h5" ml={1}>
                                                    {state.game.local}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box display="flex" alignItems="center">
                                        <Box>
                                            <Typography variant="h2">Avaliação: {state.report.nota}</Typography>
                                        </Box>
                                    </Box>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Tabs value={selectedTab} onChange={(ev, value) => { changeTab(value) }}
                                            TabIndicatorProps={{ style: { background: colors.blueAccent[500] } }}>
                                            <StyledTab label="Geral" />
                                            <StyledTab label="Avaliação" />
                                        </Tabs>
                                        {selectedTab === 0 && (
                                            <Grid container spacing={2}>
                                                <Grid item xs={8} >
                                                    <Grid container spacing={2} mt={2}>
                                                        <Grid item xs={6} >
                                                            <TableContainer sx={{ backgroundColor: colors.primary[400] }} >
                                                                <Table aria-label="custom pagination table">
                                                                    <TableBody>
                                                                        <TableRow>
                                                                            <TableCell >Lateralidade</TableCell>
                                                                            <TableCell >{state.report.lateralidade}</TableCell>
                                                                        </TableRow>

                                                                        <TableRow>
                                                                            <TableCell >Morfologia</TableCell>
                                                                            <TableCell >{state.report.morfologia}</TableCell>
                                                                        </TableRow>

                                                                        <TableRow>
                                                                            <TableCell >Rendimento Atual</TableCell>
                                                                            <TableCell >{state.report.rendimento_atual}</TableCell>
                                                                        </TableRow>

                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                        <Grid item xs={6} >
                                                            <TableContainer sx={{ backgroundColor: colors.primary[400] }} >
                                                                <Table aria-label="custom pagination table">
                                                                    <TableBody>
                                                                        <TableRow>
                                                                            <TableCell >Estatura</TableCell>
                                                                            <TableCell >{state.report.estatura}</TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell >Maturacao</TableCell>
                                                                            <TableCell >{state.report.maturacao}</TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell >Rendimento Futuro</TableCell>
                                                                            <TableCell >{state.report.rendimento_futuro}</TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                        <Grid item xs={12} >
                                                            <TableContainer sx={{ backgroundColor: colors.primary[400] }} >
                                                                <Table aria-label="custom pagination table">
                                                                    <TableBody>
                                                                        <TableRow>
                                                                            <TableCell >Observação</TableCell>
                                                                            <TableCell >{state.report.obs}</TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={2} mt={2} >
                                                    <FootballFieldToChoose
                                                        playerNumbers={[state.report.posicao]}
                                                        footeballType={11}
                                                        size={350}
                                                        fieldColor={colors.grey[900]}
                                                        fieldColorAux={colors.blueAccent}
                                                        linesColor={colors.grey[100]}
                                                        changePosition={changePosition}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid container spacing={2}>
                                            {(selectedTab === 1 && attributes.length === 0) && (
                                                <Grid item >
                                                    <Typography >Tem que escolher a Posição</Typography>
                                                </Grid>
                                            )}
                                            {(selectedTab === 1 && attributes.length > 0) && (
                                                getClasses(attributes).map((classe) => {
                                                    return (
                                                        <Grid item xs={6} mt={2} key={"class-name-" + classe.id}>
                                                            <Typography  variant="h3">{classe.name}</Typography>
                                                            <TableContainer sx={{ backgroundColor: colors.primary[400] }} key={"table-" + classe.id}>
                                                                <Table aria-label="custom pagination table">
                                                                    <TableBody>
                                                                        {getAttribute(classe.id).map((attribute) => (
                                                                            <TableRow key={"class-attribute-" + classe.id + "-" + attribute.attribute_id}>
                                                                                <TableCell >{attribute.attribute_name}</TableCell>
                                                                                <TableCell >{attribute.value}</TableCell>
                                                                            </TableRow>

                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>

                                                    )
                                                })
                                            )}
                                            {(selectedTab === 1 && attributes.length > 0) && (
                                                (
                                                    <Grid item xs={12} mt={2}>
                                                        <Typography variant="h3">Outros</Typography>
                                                        <Grid container spacing={2} mt={2} mb={3}>
                                                            <Grid item xs={4}>
                                                                <TableContainer sx={{ backgroundColor: colors.primary[400] }} >
                                                                    <Table aria-label="custom pagination table">
                                                                        <TableBody>
                                                                            <TableRow>
                                                                                <TableCell >Outros</TableCell>
                                                                                <TableCell >{others}</TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>)
                                            )}

                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                    </Grid>
                                </Grid>
                        )}
                            </Grid>
                </Grid>
            </Box>
        </>
    );
};


export default ReportFinish;