import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Box, Button, useTheme, TableFooter, TablePagination, Grid, TableContainer, Avatar, InputBase, IconButton, Typography,
  Tabs, Divider, Fade, Modal, Tab, Table, TableHead, TableBody, TableRow, TableCell, Chip, Stack,
} from "@mui/material";
import TextBox from '../../components/TextBox';
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import { tokens } from "../../theme";
import api from "../../components/confs";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SearchIcon from "@mui/icons-material/Search";
import { useParams } from "react-router-dom";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { styled } from "@mui/material/styles";
import PropTypes from 'prop-types';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import AddIcCallOutlinedIcon from '@mui/icons-material/AddIcCallOutlined';
import CloseIcon from '@mui/icons-material/Close';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import FootballFields from "./football_fields";
import Games from "./games";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="Primeira página"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Anterior"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Próxima"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Última página"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};



const Team = (props) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const [loader, setLoader] = useState(false);

  const [state, setState] = useState({
    id: id,
    name: "",
    address: "",
    association_id: "",
    city: "",
    created_on: "",
    email: "",
    fax: "",
    federation_url: "",
    lat: "",
    long: "",
    phone: "",
    photo: "",
    updated_by: "",
    updated_on: "",
  })

  const [stateTeam, setStateTeam] = useState([
    {
      "id": 1,
      "name": "Pedro",
      "position1": 4,
      "position2": 3,
      "position3": 2,
      "age": 15
    },
    {
      "id": 2,
      "name": "Paulo",
      "position1": 9,
      "position2": 10,
      "position3": 11,
      "age": 18
    }
  ])
  const [stateContacts, setStateContacts] = useState([])


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  const [page1, setPage1] = useState(0);
  const rowsPerPage1 = 8;

  const [openModal, setOpenModal] = useState(false);
  const [messageModalName, setMessageName] = useState("");
  const [messageModal, setOpenMessage] = useState(false);
  const [search_name, setSearchName] = useState("");
  const [search_name_player, setSearchNameTeam] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const severity = "success";

  const selectedFile="";
  const [preview, setPreview] = useState()


  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [selectedFile])

 

  const submitNewContact = (event) => {
    event.preventDefault();
    setLoader(true)
    const formData = new FormData(event.currentTarget);
    formData.append('table_id', parseInt(id, 10))
    const data = {
      "name": formData.get('name'),
      "phone": formData.get('phone'),
      "email": formData.get('email'),
      "address": formData.get('address'),
      "table_id": parseInt(id, 10)
    }
    api.post('/api/team/contact', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {
        setMessageName("Contacto criado!")
        setOpenMessage(true);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      })
      .finally(() => {
        get_teams_contact_searching();
        setLoader(false);
        setOpenModal(false);

      });
  }
 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
   

  //--------------------------- ON START ---------------------------//


  useEffect(() => {
    get_team_data();
    get_players();
    get_teams_contact_searching();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  const get_team_data = () => {
    api.get('/api/team/' + id, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {
        setState(response.data)
      }
      )
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }

  //--------------------------*----------------------------------//


  //--------------------------- TABS ---------------------------//


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const StyledTab = styled((props) => <Tab {...props} />)({
    "&.Mui-selected": {
      color: colors.blueAccent[500],
      fontSize: 16
    }
  });
  //-------------------------------------------------------------//



  //------------------- Search Teams ---------------------------//

  useEffect(() => {
    get_players();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [search_name_player]);

  const get_players = () => {
    setPage1(0)
    const config = {
      params: {
        id: id,
        search: search_name || ""
      },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    };
    api.get("/api/player/team/search", config)
      .then(response => {
        setStateTeam(response.data);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      }).finally(() => {

      });
  }
  //-----------------------------------------------------------//




  //------------------- Search Contacts ---------------------------//
  useEffect(() => {
    get_teams_contact_searching();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [search_name]);


  const get_teams_contact_searching = () => {
    setPage(0)
    const config = {
      params: {
        id: id,
        search: search_name || ""
      },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    };
    api.get("/api/team/contact/search", config)
      .then(response => {
        setStateContacts(response.data)

      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }

  //-----------------------------------------------------------//

  return (
    <>

      <Loader loader={loader} />
      <Message messageModal={messageModal} messageModalName={messageModalName} severity={severity} setOpenMessage={setOpenMessage} />


      <Box m="20px" sx={{
        position: "block",
        marginLeft: props.collapse === true ? 15 : 40
      }}>



        <Grid container

          mt={4}
        >
          <Grid item xs={2}>
            <Grid container direction="column"
            >
              <Grid item mt={2} xs={12}>
                <Avatar alt={state.name} src={process.env.REACT_APP_API_BASE_URL+"team/photo/" + id} sx={{ width: 121, height: 121 }} />
              </Grid>
              <Grid item mt={2} xs={12}>
                <Typography variant="h3">{state.name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  <LocationOnOutlinedIcon sx={{ width: 16, height: 16 }} />   {state.address + ", " + state.country}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <a
                  href={"http://www.google.com/maps/place/" + state.lat + "," + state.long}
                  rel="noreferrer"
                  target="_blank"
                  style={{ textDecoration: 'none' }}
                  color="inherit"
                >
                  <IconButton aria-label="delete" size="small">
                    <MapOutlinedIcon fontSize="inherit" />
                    <Typography variant="subtitle1" color={colors.primary[100]} ml={2}>
                      Como chegar
                    </Typography>
                  </IconButton>
                </a>
              </Grid>
              <Grid item xs={12}>
                <a
                  href={state.federation_url}
                  target="_blank" rel="noreferrer"
                  style={{ textDecoration: 'none' }}
                  color="inherit"
                >
                  <IconButton aria-label="delete" size="small">
                    <AccountBalanceOutlinedIcon fontSize="inherit" />
                    <Typography variant="subtitle1" color={colors.primary[100]} ml={2}>
                      Federação
                    </Typography>
                  </IconButton>
                </a>
              </Grid>
              <Grid item xs={12}>
                <a
                  href={state.website}
                  target="_blank" rel="noreferrer"
                  style={{ textDecoration: 'none' }}
                  color="inherit"
                >
                  <IconButton aria-label="delete" size="small">
                    <OutlinedFlagIcon fontSize="inherit" />
                    <Typography variant="subtitle1" color={colors.primary[100]} ml={2}>
                      Clube
                    </Typography>
                  </IconButton>
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={10}>
            <Tabs value={selectedTab} onChange={handleTabChange}
              TabIndicatorProps={{ style: { background: colors.blueAccent[500] } }}>
              <StyledTab label="Atletas" />
              <StyledTab label="Contatos" />
              <StyledTab label="Campos" />
              <StyledTab label="Jogos" />
            </Tabs>
            {selectedTab === 0 && (


              <Grid container>
                <Grid item xs={12} mt={2}>

                  <Box
                    display="flex"
                    backgroundColor={colors.primary[400]}
                    borderRadius="3px"
                  >
                    <InputBase sx={{ ml: 2, mt: 1, mb: 1, flex: 1 }} placeholder="Procurar Atleta"
                      onChange={event => {
                        setSearchNameTeam(event.target.value);
                      }}
                      value={search_name_player || ''}
                      inputProps={{ style: { fontSize: 14 } }} />
                    <IconButton type="button" sx={{ p: 1 }}>
                      <SearchIcon />
                    </IconButton>
                  </Box>
                </Grid>
               
               
                <Grid item xs={12} mt={2}>

                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Nome</TableCell>
                          <TableCell>Idade</TableCell>
                          <TableCell>Posições</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(rowsPerPage1 > 0
                          ? stateTeam.slice(page1 * rowsPerPage1, page1 * rowsPerPage1 + rowsPerPage1)
                          : stateTeam
                        ).map((row, i) => (
                          <TableRow key={"team-" + i}>
                            <TableCell>{row.complete_name}</TableCell>
                            <TableCell>{row.birthday}</TableCell>
                            <TableCell>
                              <Stack direction="row" spacing={1} align="center">
                                {row.position1 !== 0 &&
                                  <Chip label={row.position1} />
                                }
                                {row.position2 !== 0 &&
                                  <Chip label={row.position2} />
                                }
                                {row.position3 !== 0 &&
                                  <Chip label={row.position3} />
                                }
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            //rowsPerPageOptions={[5, 6,7,8,9, 10, 25, { label: 'Tudo', value: -1 }]}
                            rowsPerPageOptions={[8]}
                            colSpan={3}
                            count={stateTeam.length}
                            //rowsPerPage={rowsPerPage1}
                            rowsPerPage={8}

                            page={page1}
                            SelectProps={{
                              inputProps: {
                                'aria-label': 'Linhas por página',
                              },
                              native: true,
                            }}
                            labelRowsPerPage='Linhas por página'
                            onPageChange={handleChangePage1}
                            //onRowsPerPageChange={handleChangeRowsPerPage1}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={(page1) =>
                              `${page1.from}-${page1.to === -1 ? page1.count : page1.to} de ${page1.count
                              }`
                            }
                          >

                          </TablePagination>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>

            )}
            {selectedTab === 1 && (



              <Grid container>
                <Grid item xs={10} mt={2}>

                  <Box
                    display="flex"
                    backgroundColor={colors.primary[400]}
                    borderRadius="3px"
                  >
                    <InputBase sx={{ ml: 2, mt: 1, mb: 1, flex: 1 }} placeholder="Procurar Contacto"
                      onChange={(e) => {
                        setSearchName(e.target.value);
                      }}
                      value={search_name || ''}
                      inputProps={{ style: { fontSize: 14 } }} />
                    <IconButton type="button" sx={{ p: 1 }}>
                      <SearchIcon />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item xs={2} mt={2}>

                  <Box style={{ textAlign: 'right' }}>
                    <Button
                      sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                      }}
                      onClick={() => { setOpenModal(true) }}
                    >
                      <  AddIcCallOutlinedIcon sx={{ mr: "10px" }} />
                      Criar Contacto
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} mt={2}>

                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Nome</TableCell>
                          <TableCell>Morada</TableCell>
                          <TableCell>Telemóvel</TableCell>
                          <TableCell>E-mail</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(rowsPerPage > 0
                          ? stateContacts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          : stateContacts
                        ).map((row, i) => (
                          <TableRow key={"contact-" + i}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.address}</TableCell>
                            <TableCell>{row.phone}</TableCell>
                            <TableCell>{row.email}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            //rowsPerPageOptions={[5,   10, 25, { label: 'Tudo', value: -1 }]}
                            rowsPerPageOptions={[8]}
                            colSpan={4}
                            count={stateContacts.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                              inputProps: {
                                'aria-label': 'Linhas por página',
                              },
                              native: true,
                            }}
                            labelRowsPerPage='Linhas por página'
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={(page) =>
                              `${page.from}-${page.to === -1 ? page.count : page.to} de ${page.count
                              }`
                            }
                          >

                          </TablePagination>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>

            )}
            {selectedTab === 2 && (
              <FootballFields id={id} />
            )}
            {selectedTab === 3 && (
              <Games id={id} />
            )}
          </Grid>
        </Grid>



      </Box >

      {/** Contact Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={() => { setOpenModal(false); }}
        closeAfterTransition
      >
        <Fade in={openModal}>
          <Box component="form"
            noValidate
            onSubmit={submitNewContact}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 700,
              height: 480,
              bgcolor: colors.primary[400],
              boxShadow: 24,
              p: 4,
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{
                paddingBottom: '10px',
                borderStyle: 'solid',
                borderColor: colors.blueAccent[300],
                borderWidth: '0 0 2px 0'
              }}>
                <Typography component={'span'} variant="h3">Criação de novo contacto para o clube</Typography>
                <IconButton
                  aria-label="close"
                  onClick={() => { setOpenModal(false); }}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Divider flexItem variant="middle" />
              <Grid item xs={12}>
                <Grid container spacing={2} mt={2}>
                  <Grid item xs={12}>
                    <TextBox fullWidth name="name" id="name" label="Nome" variant="outlined" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextBox fullWidth name="address" id="address" label="Morada" variant="outlined" />
                  </Grid>

                  <Grid item xs={12}>
                    <TextBox fullWidth name="phone" id="phone" label="Telemóvel" variant="outlined" />
                  </Grid>

                  <Grid item xs={12}>
                    <TextBox fullWidth name="email" id="email" label="E-mail" variant="outlined" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              pt={4}
              spacing={2}
            >
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => setOpenModal(false)}>Cancelar</Button>
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="success" startIcon={<AddOutlinedIcon />} >Criar</Button>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>



    </>


  );
};

export default Team;