import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Box,  useTheme,  Tab, Tabs, Table, TableBody, TableHead, TableCell, TableContainer, TablePagination, TableRow, Paper, IconButton
} from "@mui/material";
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { styled } from "@mui/material/styles"; 
import ActiveProfile from '../scenes/Settings/active_profile'
import EvaluationProfileModal from '../scenes/Settings/evaluation_profile_modal'
import api from './confs';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function EvaluationProfiles({ token,
  setToken,
  setLoader,
  colors }) {
  const [value, setValue] = React.useState(0);
  const [profiles, setProfiles] = React.useState([]);

  const [evaluationProfileModal, setOpenEvaluationProfileModal] = React.useState(false)
  const [profileToModal, setProfileToModal] = React.useState(null)
  const [name, setName] = React.useState("");
  const [goalkeeper, setGoalKeeper] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    getProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  React.useEffect(() => {
    if (value === 1) {
      getProfiles();
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])


  function getProfiles() {
    setLoader(true)
    api.get('/api/evaluation_profiles', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then((response) => {
        setProfiles(response.data)
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      }).finally(() => {
        setLoader(false)
      });
  }




  /**----------------------Table ---------------------- */
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  /**-------------------End Table ---------------------- */



  const StyledTab = styled((props) => <Tab {...props} />)({
    "&.Mui-selected": {
      color: colors.blueAccent[500],
      fontSize: 16
    }
  });

  


  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <StyledTab label="Ativo" {...a11yProps(0)} />
            <StyledTab label="Todos" {...a11yProps(1)} />
            <StyledTab label="Histórico" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <ActiveProfile token={token} setToken={setToken} setLoader={setLoader} colors={colors} />


        </TabPanel>
        <TabPanel value={value} index={1}>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell> Perfil</TableCell>
                    <TableCell> Escalão</TableCell>
                    <TableCell> Idade</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(Array.isArray(profiles)) &&
                    profiles
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.id} onClick={() => { setOpenEvaluationProfileModal(true); setProfileToModal(row.id); setName(row.name); setGoalKeeper(row.goalkeeper) }} style={{ cursor: 'pointer' }}>
                            <TableCell> {row.name} </TableCell>
                            <TableCell> {row.grade_name} </TableCell>
                            <TableCell> {row.age} </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={profiles.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
          </Paper>
        </TabPanel>
        <TabPanel value={value} index={2}>
          Talvez mostrar um histórico de de épocas dos perfis utilizados, ou colocar na tab das épocas (ANALISAR MELHOR SE É PROVEITOSO)
        </TabPanel>
      </Box>
      <EvaluationProfileModal
        openModal={evaluationProfileModal}
        setOpenModal={setOpenEvaluationProfileModal}
        value_id={profileToModal}
        setLoader={setLoader}
        name={name}
        goalkeeper={goalkeeper}
      />
    </>
  );
}


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

