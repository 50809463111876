import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Box, Button, useTheme, Grid,
  Avatar, IconButton, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Chip, Stack,
  TableFooter, TablePagination, Typography, Autocomplete, Divider, Modal, Fade
} from "@mui/material";
import Header from "../../components/Header";
import Loader from "../../components/Loader"; 
import TextBox from '../../components/TextBox';
import { tokens } from "../../theme";
import api from "../../components/confs";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import CloseIcon from '@mui/icons-material/Close';
import Message from "../../components/Message";
import { debounce } from 'lodash';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
const Players = (props) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  const [state, setState] = useState([])
  const [loader, setLoader] = useState(false);
  const [messageModal, setOpenMessage] = useState(false);
  const [messageModalName, setMessageName] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

 
  const [date_birth, setDate] = useState(null);
  const [sex, setSex] = useState(null);
  const [foot, setFoot] = useState(null);



  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState()

  const [openPlayerModal, setOpenPlayerModal] = useState(false);
  const [severity, setSeverity] = useState("success")
  const [players, setPlayers] = useState(
    {
      complete_name: "",
      shirt_number: 0,
      shirt_name: "",
      minAge: 0,
      maxAge: 0,
      naturality: "",
      position: 0,
      foot: "",
      sex: "",
      minHeight: 0,
      maxHeight: 250,
      minWeight: 0,
      maxWeight: 250,
      agent: "",
    }
  )




  // to table 
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - state.length) : 0;

  // to table
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  // to table
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };





  // To navigate to team page
  const navigate = useNavigate();
  const callRoute = (id) => {
    navigate('/player/' + id);
  }

  // function to create new team

  const submitNewPlayer = (event) => {
    event.preventDefault();
    setLoader(true)

    const date = new Date(date_birth)
    date.setDate(date.getDate());


    const formData = new FormData(event.currentTarget);
    const data = {
      'complete_name': formData.get('complete_name'),
      'shirt_name': formData.get('shirt_name'),
      'shirt_number': parseInt(formData.get('shirt_number').trim() === "" ? 0 : formData.get('shirt_number')),
      'birthday': date.toISOString().slice(0, 10),
      'naturality': formData.get('naturality'),
      'position1': parseInt(formData.get('position1').trim() === "" ? 0 : formData.get('position1')),
      'position2': parseInt(formData.get('position2').trim() === "" ? 0 : formData.get('position2')),
      'position3': parseInt(formData.get('position3').trim() === "" ? 0 : formData.get('position3')),
      'foot': foot,
      'sex': sex,
      'height': parseFloat(formData.get('height').trim() === "" ? 0 : formData.get('height')),
      'weight': parseFloat(formData.get('weight').trim() === "" ? 0 : formData.get('weight')),
      'agent': formData.get('agent'),
      'observations': formData.get('observations'),
    }

    if (formData.get('complete_name').trim().length === 0) {
      setSeverity("warning")
      setMessageName("O nome do atleta é um campo obrigatório")
      setOpenMessage(true);
      setLoader(false)
      return;
    }

    api.post('/api/players', { 'photo': formData.get('photo') }, {
      params: data,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {
        setSeverity("success")
        setMessageName("Atleta criado!")
        setOpenMessage(true);
        setPreview("")
        setOpenPlayerModal(false);
      })
      .catch(error => {
        setSeverity("error")
        setMessageName(error)
        setOpenMessage(true);
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      })
      .finally(() => {
        search_player();
        setLoader(false);

      });

  }



  // To show image on create team
  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    setSelectedFile(e.target.files[0])
  }

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])


  // function to get team with filter
  function search_player() {

    api.get('api/players/',
      {
        params: players,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then(response => {
        setState(response.data);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }

  const debouncedFetchData = debounce(search_player, 1000); // Ajuste o tempo de atraso conforme necessário (500ms neste exemplo)


  // On change data filters, update table
  useEffect(() => {

    debouncedFetchData(players);

    // Limpe o debounce quando o componente for desmontado
    return () => {
      debouncedFetchData.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [players]);


  // On start
  useEffect(() => {
    search_player();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 
  function handleFavoriteClick(rowId, e) {
    e.stopPropagation();
    api.get('/api/favorite/'+rowId, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then((response) => {
        const updatedAthletes = state.map((athlete) => {
          if (athlete.id === rowId) {
            return { ...athlete, favorite: !athlete.favorite };
          }
          return athlete;
        });
        setState(updatedAthletes);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }
  function handleStarClick(rowId, e) {
    e.stopPropagation();
    api.get('/api/star/'+rowId, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then((response) => {
        const updatedAthletes = state.map((athlete) => {
          if (athlete.id === rowId) {
            return { ...athlete, star: !athlete.star };
          }
          return athlete;
        });
        setState(updatedAthletes);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }


  
  return (
    <>
      <Loader loader={loader} />
      <Message messageModal={messageModal} messageModalName={messageModalName} severity={severity} setOpenMessage={setOpenMessage} />
      <Box m="20px" sx={{
        position: "block",
        marginLeft: props.collapse === true ? 15 : 40
      }}>
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Atletas" subtitle="Pesquise um atleta" />
          <Box>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
              onClick={() => { setOpenPlayerModal(true); }}
            >
              <AddOutlinedIcon sx={{ mr: "10px" }} />
              Criar Atleta
            </Button>
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Grid container spacing={2}>
              <Grid item xl={12}>
                <TextBox
                  fullWidth
                  name="name"
                  id="name"
                  label="Nome do atleta"
                  variant="outlined"
                  sx={{ backgroundColor: colors.primary[400] }}
                  onChange={event => {
                    setPlayers((prevState) => ({
                      ...prevState,
                      "complete_name": event.target.value,
                      "shirt_name": event.target.value,
                    }));
                  }}
                />

              </Grid>
              
              <Grid item xl={12}>

                <TextBox
                  fullWidth
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  name="shirt_number"
                  id="shirt_number"
                  label="Número na camisola"
                  variant="outlined"
                  sx={{ backgroundColor: colors.primary[400] }}
                  onChange={event => {
                    setPlayers((prevState) => ({
                      ...prevState,
                      "shirt_number": parseInt(event.target.value === "" ? 0 : event.target.value, 10),
                    }));
                  }}
                />

              </Grid>
              <Grid item xl={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>

                    <TextBox
                      fullWidth
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      name="minAge"
                      id="minAge"
                      label="Idade mínima"
                      variant="outlined"
                      sx={{ backgroundColor: colors.primary[400] }}
                      onChange={event => {
                        setPlayers((prevState) => ({
                          ...prevState,
                          "minAge": parseInt(event.target.value === "" ? 0 : event.target.value, 10),
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>

                    <TextBox
                      fullWidth
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      name="maxAge"
                      id="maxAge"
                      label="Idade máxima"
                      variant="outlined"
                      sx={{ backgroundColor: colors.primary[400] }}
                      onChange={event => {
                        setPlayers((prevState) => ({
                          ...prevState,
                          "maxAge": parseInt(event.target.value === "" ? 0 : event.target.value, 10),
                        }));
                      }}
                    />
                  </Grid>

                </Grid>
              </Grid>
              <Grid item xl={12}>
                <Autocomplete
                  id="country-select-demo"
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  sx={{ backgroundColor: colors.primary[400] }}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label} ({option.code})
                    </Box>
                  )}
                  onChange={(event, value) => {
                    if (value) {
                      setPlayers((prevState) => ({
                        ...prevState,
                        "naturality": value.label,
                      }));
                    } else {
                      setPlayers((prevState) => ({
                        ...prevState,
                        "naturality": null,
                      }));
                    }
                  }}
                  renderInput={(params) => (
                    <TextBox
                      name="naturality" id="naturality"
                      {...params}
                      label="Escolha a nacionalidade"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xl={12}>
                <Autocomplete
                  disablePortal
                  name="foot" id="foot"
                  options={[
                    { label: "Todos", value: 0 },
                    { label: "1", value: 1 },
                    { label: "2", value: 2 },
                    { label: "3", value: 3 },
                    { label: "4", value: 4 },
                    { label: "5", value: 5 },
                    { label: "6", value: 6 },
                    { label: "7", value: 7 },
                    { label: "8", value: 8 },
                    { label: "9", value: 9 },
                    { label: "10", value: 10 },
                    { label: "11", value: 11 },

                  ]}
                  renderInput={(params) => <TextBox {...params} label="Posição" />}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  variant="outlined"
                  sx={{ backgroundColor: colors.primary[400] }}
                  onChange={(event, value) => {
                    if (value) {
                      setPlayers((prevState) => ({
                        ...prevState,
                        "position": value.value,
                      }));
                    } else {
                      setPlayers((prevState) => ({
                        ...prevState,
                        "position": 0,
                      }));
                    }
                  }}
                />

              </Grid>
              <Grid item xl={12}>
                <Autocomplete
                  disablePortal
                  name="foot" id="foot"
                  options={[
                    { label: "Não identificado", value: "Não identificado" },
                    { label: "Direito", value: "Direito" },
                    { label: "Esquerdo", value: "Esquerdo" }
                  ]}
                  sx={{ backgroundColor: colors.primary[400] }}
                  renderInput={(params) => <TextBox {...params} label="Pé preferido" />}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  variant="outlined"
                  onChange={(event, value) => {
                    if (value) {
                      setPlayers((prevState) => ({
                        ...prevState,
                        "foot": value.label,
                      }));
                    } else {
                      setPlayers((prevState) => ({
                        ...prevState,
                        "foot": null,
                      }));
                    }
                  }}
                />

              </Grid>
              <Grid item xl={12}>
                <Autocomplete
                  disablePortal
                  name="foot" id="foot"
                  options={[
                    { label: "Outro", value: "" },
                    { label: "Masculino", value: "masculino" },
                    { label: "Feminino", value: "feminino" }
                  ]}
                  sx={{ backgroundColor: colors.primary[400] }}
                  renderInput={(params) => <TextBox {...params} label="Sexo" />}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  variant="outlined"
                  onChange={(event, value) => {
                    if (value) {
                      setPlayers((prevState) => ({
                        ...prevState,
                        "sex": value.label,
                      }));
                    } else {
                      setPlayers((prevState) => ({
                        ...prevState,
                        "sex": null,
                      }));
                    }
                  }}
                />

              </Grid>
              <Grid item xl={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>

                    <TextBox
                      fullWidth
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      name="minWeight"
                      id="minWeight"
                      label="Peso mínimo (Kg)"
                      variant="outlined"
                      sx={{ backgroundColor: colors.primary[400] }}
                      onChange={event => {
                        setPlayers((prevState) => ({
                          ...prevState,
                          "minWeight": parseInt(event.target.value === "" ? 0 : event.target.value, 10),
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>

                    <TextBox
                      fullWidth
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      name="maxWeight"
                      id="maxWeight"
                      label="Peso máximo (Kg)"
                      variant="outlined"
                      sx={{ backgroundColor: colors.primary[400] }}
                      onChange={event => {
                        setPlayers((prevState) => ({
                          ...prevState,
                          "maxWeight": parseInt(event.target.value === "" ? 0 : event.target.value, 10),
                        }));
                      }}
                    />
                  </Grid>

                </Grid>
              </Grid>
              <Grid item xl={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>

                    <TextBox
                      fullWidth
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      name="minHeight"
                      id="minHeight"
                      label="Altura mínima (cm)"
                      variant="outlined"
                      sx={{ backgroundColor: colors.primary[400] }}
                      onChange={event => {
                        setPlayers((prevState) => ({
                          ...prevState,
                          "minHeight": parseInt(event.target.value === "" ? 0 : event.target.value, 10),
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>

                    <TextBox
                      fullWidth
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      name="maxHeight"
                      id="maxHeight"
                      label="Altura máxima (cm)"
                      variant="outlined"
                      sx={{ backgroundColor: colors.primary[400] }}
                      onChange={event => {
                        setPlayers((prevState) => ({
                          ...prevState,
                          "maxHeight": parseInt(event.target.value === "" ? 0 : event.target.value, 10),
                        }));
                      }}
                    />
                  </Grid>

                </Grid>
              </Grid>
              <Grid item xl={12}>

                <TextBox
                  fullWidth
                  name="agent"
                  id="agent"
                  label="Agente/Agência"
                  variant="outlined"
                  sx={{ backgroundColor: colors.primary[400] }}
                  onChange={event => {
                    setPlayers((prevState) => ({
                      ...prevState,
                      "agent": event.target.value,
                    }));
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={10}>

            <Grid container
              backgroundColor={colors.primary[400]}
              alignItems="center"
              justifyContent="center"

            >
              <Grid item xs={12} >
                <div style={{ width: '94%', marginLeft: 28, marginRight: 28, marginTop: 20, marginBottom: 20 }}>
                  <TableContainer sx={{ backgroundColor: colors.primary[400] }}>
                    <Table aria-label="custom pagination table" >
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Nome</TableCell>
                          <TableCell align="left">Clube</TableCell>
                          <TableCell align="left">Pé</TableCell>
                          <TableCell align="center">Posição</TableCell>
                          <TableCell align="center">Favoritos</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(rowsPerPage > 0
                          ? state.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          : state
                        ).map((row) => (
                          <TableRow key={row.id} onClick={() => { callRoute(row.id) }}
                            hover
                            style={{ cursor: 'pointer' }}>

                            <TableCell>{row.complete_name + " - " + row.birthday + " anos"}</TableCell>
                            <TableCell >{row.team}</TableCell>
                            <TableCell >{row.foot}</TableCell>


                            <TableCell align="center">
                              <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" style={{ margin: 'auto' }}>

                                {row.position1 !== 0 &&
                                  <Chip label={row.position1} />
                                }
                                {row.position2 !== 0 &&
                                  <Chip label={row.position2} />
                                }
                                {row.position3 !== 0 &&
                                  <Chip label={row.position3} />
                                }
                              </Stack>
                            </TableCell>
                            {user.is_admin ?
                              <TableCell>
                                {row.favorite ?
                                  <IconButton variant="plain" color={colors.blueAccent[500]}
                                  onClick={(e) => handleFavoriteClick(row.id, e)}
                                  >
                                    <FavoriteIcon />
                                  </IconButton>
                                  :
                                  <IconButton variant="plain" color={colors.blueAccent[500]}
                                  onClick={(e) => handleFavoriteClick(row.id, e)}
                                  >
                                    <FavoriteBorderIcon />
                                  </IconButton>
                                }
                                {row.star  ?
                                  <IconButton variant="plain" color={colors.blueAccent[500]}
                                  onClick={(e) => handleStarClick(row.id, e)}>
                                    <StarIcon />
                                  </IconButton>
                                  :
                                  <IconButton variant="plain" color={colors.blueAccent[500]}
                                  onClick={(e) => handleStarClick(row.id, e)}>
                                    <StarBorderIcon />
                                  </IconButton>
                                }
                              </TableCell>
                              :
                              <TableCell>
                              {row.favorite ?
                                <IconButton variant="plain" color={colors.blueAccent[500]}
                                onClick={(e) => handleFavoriteClick(row.id, e)}
                                >
                                  <FavoriteIcon />
                                </IconButton>
                                :
                                <IconButton variant="plain" color={colors.blueAccent[500]}
                                onClick={(e) => handleFavoriteClick(row.id, e)}
                                >
                                  <FavoriteBorderIcon />
                                </IconButton>
                              }
                            </TableCell>
                              }
                          </TableRow>
                        ))}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={5} />
                          </TableRow>
                        )}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            colSpan={5}
                            count={state.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                              inputProps: {
                                'aria-label': 'Linhas por página',
                              },
                              native: true,
                            }}
                            labelRowsPerPage='Linhas por página'
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={(page) =>
                              `${page.from}-${page.to === -1 ? page.count : page.to} de ${page.count
                              }`
                            }
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>


            </Grid>
          </Grid>

        </Grid>

      </Box>
      {/** Team Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openPlayerModal}
        onClose={() => { setOpenPlayerModal(false); setPreview(""); }}
        closeAfterTransition
      >
        <Fade in={openPlayerModal}>
          <Box component="form"
            noValidate
            onSubmit={submitNewPlayer}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 800,
              height: 800,
              bgcolor: colors.primary[400],
              boxShadow: 24,
              p: 4,
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{
                paddingBottom: '10px',
                borderStyle: 'solid',
                borderColor: colors.blueAccent[300],
                borderWidth: '0 0 2px 0'
              }}>
                <Typography component={'span'} variant="h3">Criar atleta</Typography>
                <IconButton
                  aria-label="close"
                  onClick={() => { setOpenPlayerModal(false); setPreview(""); }}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Divider flexItem variant="middle" />
              <Grid item xs={12}>
                <Grid container spacing={2} mt={2}>
                  <Grid item xs={3}  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} style={{ display: "flex", justifyContent: 'center' }}>
                        <Avatar alt="Remy Sharp" src={preview} sx={{ width: 100, height: 100, fontSize: 15 }} />
                      </Grid>
                      <Grid item xs={12} style={{ display: "flex", justifyContent: 'center' }}>
                        <input
                          required
                          id="photo"
                          label="Fotografia"
                          name="photo"
                          type="file"
                          onChange={onSelectFile}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={9} >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextBox fullWidth name="complete_name" id="complete_name" label="Nome completo" variant="outlined" required />
                      </Grid>
                      <Grid item xs={8}>
                        <TextBox fullWidth name="shirt_name" id="shirt_name" label="Nome na camisola" variant="outlined" />
                      </Grid>
                      <Grid item xs={4}>
                        <TextBox fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} name="shirt_number" id="shirt_number" label="Número na camisola" variant="outlined" />
                      </Grid>
                      <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            views={['day']}
                            label="Data de nascimento"
                            value={date_birth}
                            onChange={(newValue) => {
                              setDate(newValue);
                            }}
                            renderInput={(params) => <TextBox {...params} />}
                            name="birthday"
                            id="birthday"
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={6}>
                        <Autocomplete
                          id="country-select-demo"
                          options={countries}
                          autoHighlight
                          getOptionLabel={(option) => option.label}
                          renderOption={(props, option) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                              <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                              />
                              {option.label} ({option.code})
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextBox
                              name="naturality" id="naturality"
                              {...params}
                              label="Escolha a nacionalidade"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />

                      </Grid>
                      <Grid item xs={3}>
                        <TextBox fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} name="position1" id="position1" label="Posição 1" variant="outlined" />
                      </Grid>
                      <Grid item xs={3}>
                        <TextBox fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} name="position2" id="position2" label="Posição 2" variant="outlined" />
                      </Grid>
                      <Grid item xs={3}>
                        <TextBox fullWidth inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} name="position3" id="position3" label="Posição 3" variant="outlined" />
                      </Grid>
                      <Grid item xs={4}>
                        <Autocomplete
                          disablePortal
                          name="foot" id="foot"
                          options={[{ label: "Não identificado", value: "Não identificado" }, { label: "Direito", value: "Direito" }, { label: "Esquerdo", value: "Esquerdo" }]}
                          sx={{}}
                          renderInput={(params) => <TextBox {...params} label="Pé preferido" />}
                          variant="outlined"
                          onChange={(event) => { setFoot(event.target.textContent) }}

                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Autocomplete
                          disablePortal
                          name="sex" id="sex"
                          options={[{ label: "Masculino", value: "Masculino" }, { label: "Feminino", value: "Feminino" }, { label: "Outro", value: "Outro" }]}
                          renderInput={(params) => <TextBox {...params} label="Sexo" />}
                          variant="outlined"
                          onChange={(event) => { setSex(event.target.textContent) }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextBox fullWidth name="height" id="height" label="Altura (cm)" variant="outlined" />
                      </Grid>
                      <Grid item xs={4}>
                        <TextBox fullWidth name="weight" id="weight" label="Peso (Kg)" variant="outlined" />
                      </Grid>
                      <Grid item xs={12}>
                        <TextBox fullWidth name="agent" id="agent" label="Agente/Agência" variant="outlined" />
                      </Grid>
                      <Grid item xs={12}>
                        <TextBox fullWidth multiline rows={4} name="observations" id="observations" label="Observações" variant="outlined" />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              pt={4}
              spacing={2}
            >
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => { setOpenPlayerModal(false); setPreview(""); }}>Cancelar</Button>
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="success" startIcon={<AddOutlinedIcon />} >Criar</Button>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>

    </>
  );
};


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="Primeira página"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Anterior"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Próxima"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Última página"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const countries = [
  {
    code: 'AD', label: 'Andorra'
  },
  {
    code: 'AE',
    label: 'United Arab Emirates',
  },
  {
    code: 'AF', label: 'Afghanistan'
  },
  {
    code: 'AG',
    label: 'Antigua and Barbuda'
  },
  {
    code: 'AI', label: 'Anguilla'
  },
  {
    code: 'AL', label: 'Albania'
  },
  { code: 'AM', label: 'Armenia' },
  { code: 'AO', label: 'Angola' },
  { code: 'AQ', label: 'Antarctica' },
  { code: 'AR', label: 'Argentina' },
  { code: 'AS', label: 'American Samoa' },
  { code: 'AT', label: 'Austria' },
  {
    code: 'AU',
    label: 'Australia',
  },
  {
    code: 'AW', label: 'Aruba'
  },
  {
    code: 'AX', label: 'Alland Islands'
  },
  {
    code: 'AZ', label: 'Azerbaijan'
  },
  {
    code: 'BA',
    label: 'Bosnia and Herzegovina',
  },
  {
    code: 'BB', label: 'Barbados'
  },
  {
    code: 'BD', label: 'Bangladesh'
  },
  {
    code: 'BE', label: 'Belgium'
  },
  {
    code: 'BF', label: 'Burkina Faso'
  },
  {
    code: 'BG', label: 'Bulgaria'
  },
  {
    code: 'BH', label: 'Bahrain'
  },
  {
    code: 'BI', label: 'Burundi'
  },
  {
    code: 'BJ', label: 'Benin'
  },
  {
    code: 'BL', label: 'Saint Barthelemy'
  },
  {
    code: 'BM', label: 'Bermuda'
  },
  {
    code: 'BN', label: 'Brunei Darussalam'
  },
  {
    code: 'BO', label: 'Bolivia'
  },
  {
    code: 'BR', label: 'Brazil'
  },
  {
    code: 'BS', label: 'Bahamas'
  },
  {
    code: 'BT', label: 'Bhutan'
  },
  {
    code: 'BV', label: 'Bouvet Island'
  },
  {
    code: 'BW', label: 'Botswana'
  },
  {
    code: 'BY', label: 'Belarus'
  },
  {
    code: 'BZ', label: 'Belize'
  },
  {
    code: 'CA',
    label: 'Canada',
  },
  {
    code: 'CC',
    label: 'Cocos (Keeling) Islands',
  },
  {
    code: 'CD',
    label: 'Congo, Democratic Republic of the',
  },
  {
    code: 'CF',
    label: 'Central African Republic',
  },
  {
    code: 'CG',
    label: 'Congo, Republic of the',
  },
  {
    code: 'CH', label: 'Switzerland'
  },
  {
    code: 'CI', label: "Cote d'Ivoire"
  },
  {
    code: 'CK', label: 'Cook Islands'
  },
  {
    code: 'CL', label: 'Chile'
  },
  {
    code: 'CM', label: 'Cameroon'
  },
  {
    code: 'CN', label: 'China'
  },
  {
    code: 'CO', label: 'Colombia'
  },
  {
    code: 'CR', label: 'Costa Rica'
  },
  {
    code: 'CU', label: 'Cuba'
  },
  {
    code: 'CV', label: 'Cape Verde'
  },
  {
    code: 'CW', label: 'Curacao'
  },
  {
    code: 'CX', label: 'Christmas Island'
  },
  {
    code: 'CY', label: 'Cyprus'
  },
  {
    code: 'CZ', label: 'Czech Republic'
  },
  {
    code: 'DE',
    label: 'Germany',
  },
  {
    code: 'DJ', label: 'Djibouti'
  },
  {
    code: 'DK', label: 'Denmark'
  },
  {
    code: 'DM', label: 'Dominica'
  },
  {
    code: 'DO',
    label: 'Dominican Republic',
  },
  {
    code: 'DZ', label: 'Algeria'
  },
  {
    code: 'EC', label: 'Ecuador'
  },
  {
    code: 'EE', label: 'Estonia'
  },
  {
    code: 'EG', label: 'Egypt'
    , phone: '20'
  },
  {
    code: 'EH', label: 'Western Sahara'
  },
  {
    code: 'ER', label: 'Eritrea'
  },
  {
    code: 'ES', label: 'Spain'
  },
  {
    code: 'ET', label: 'Ethiopia'
  },
  {
    code: 'FI', label: 'Finland'
  },
  {
    code: 'FJ', label: 'Fiji'
  },
  {
    code: 'FK',
    label: 'Falkland Islands (Malvinas)',
  },
  {
    code: 'FM',
    label: 'Micronesia, Federated States of',
  },
  {
    code: 'FO', label: 'Faroe Islands'
  },
  {
    code: 'FR',
    label: 'France',
  },
  {
    code: 'GA', label: 'Gabon'
  },
  {
    code: 'GB', label: 'United Kingdom'
  },
  {
    code: 'GD', label: 'Grenada'
  },
  {
    code: 'GE', label: 'Georgia'
  },
  {
    code: 'GF', label: 'French Guiana'
  },
  {
    code: 'GG', label: 'Guernsey'
  },
  {
    code: 'GH', label: 'Ghana'
  },
  {
    code: 'GI', label: 'Gibraltar'
  },
  {
    code: 'GL', label: 'Greenland'
  },
  {
    code: 'GM', label: 'Gambia'
  },
  {
    code: 'GN', label: 'Guinea'
  },
  {
    code: 'GP', label: 'Guadeloupe'
  },
  {
    code: 'GQ', label: 'Equatorial Guinea'
  },
  {
    code: 'GR', label: 'Greece'
  },
  {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
  },
  {
    code: 'GT', label: 'Guatemala'
  },
  {
    code: 'GU', label: 'Guam'
  },
  {
    code: 'GW', label: 'Guinea-Bissau'
  },
  {
    code: 'GY', label: 'Guyana'
  },
  {
    code: 'HK', label: 'Hong Kong'
  },
  {
    code: 'HM',
    label: 'Heard Island and McDonald Islands',
  },
  {
    code: 'HN', label: 'Honduras'
  },
  {
    code: 'HR', label: 'Croatia'
  },
  {
    code: 'HT', label: 'Haiti'
  },
  {
    code: 'HU', label: 'Hungary'
  },
  {
    code: 'ID', label: 'Indonesia'
  },
  {
    code: 'IE', label: 'Ireland'
  },
  {
    code: 'IL', label: 'Israel'
  },
  {
    code: 'IM', label: 'Isle of Man'
  },
  {
    code: 'IN', label: 'India'
  },
  {
    code: 'IO',
    label: 'British Indian Ocean Territory',
  },
  {
    code: 'IQ', label: 'Iraq'
  },
  {
    code: 'IR',
    label: 'Iran, Islamic Republic of',
  },
  {
    code: 'IS', label: 'Iceland'
  },
  {
    code: 'IT', label: 'Italy'
  },
  {
    code: 'JE', label: 'Jersey'
  },
  {
    code: 'JM', label: 'Jamaica'
  },
  {
    code: 'JO', label: 'Jordan'
  },
  {
    code: 'JP',
    label: 'Japan',
  },
  {
    code: 'KE', label: 'Kenya'
  },
  {
    code: 'KG', label: 'Kyrgyzstan'
  },
  {
    code: 'KH', label: 'Cambodia'
  },
  {
    code: 'KI', label: 'Kiribati'
  },
  {
    code: 'KM', label: 'Comoros'
  },
  {
    code: 'KN',
    label: 'Saint Kitts and Nevis',
  },
  {
    code: 'KP',
    label: "Korea, Democratic People's Republic of",
  },
  {
    code: 'KR', label: 'Korea, Republic of'
  },
  {
    code: 'KW', label: 'Kuwait'
  },
  {
    code: 'KY', label: 'Cayman Islands'
  },
  {
    code: 'KZ', label: 'Kazakhstan'
  },
  {
    code: 'LA',
    label: "Lao People's Democratic Republic",
  },
  {
    code: 'LB', label: 'Lebanon'
  },
  {
    code: 'LC', label: 'Saint Lucia'
  },
  {
    code: 'LI', label: 'Liechtenstein'
  },
  {
    code: 'LK', label: 'Sri Lanka'
  },
  {
    code: 'LR', label: 'Liberia'
  },
  {
    code: 'LS', label: 'Lesotho'
  },
  {
    code: 'LT', label: 'Lithuania'
  },
  {
    code: 'LU', label: 'Luxembourg'
  },
  {
    code: 'LV', label: 'Latvia'
  },
  {
    code: 'LY', label: 'Libya'
  },
  {
    code: 'MA', label: 'Morocco'
  },
  {
    code: 'MC', label: 'Monaco'
  },
  {
    code: 'MD',
    label: 'Moldova, Republic of',
  },
  {
    code: 'ME', label: 'Montenegro'
  },
  {
    code: 'MF',
    label: 'Saint Martin (French part)',
  },
  {
    code: 'MG', label: 'Madagascar'
  },
  {
    code: 'MH', label: 'Marshall Islands'
  },
  {
    code: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
  },
  {
    code: 'ML', label: 'Mali'
  },
  {
    code: 'MM', label: 'Myanmar'
  },
  {
    code: 'MN', label: 'Mongolia'
  },
  {
    code: 'MO', label: 'Macao'
  },
  {
    code: 'MP',
    label: 'Northern Mariana Islands',
  },
  {
    code: 'MQ', label: 'Martinique'
  },
  {
    code: 'MR', label: 'Mauritania'
  },
  {
    code: 'MS', label: 'Montserrat'
  },
  {
    code: 'MT', label: 'Malta'
  },
  {
    code: 'MU', label: 'Mauritius'
  },
  {
    code: 'MV', label: 'Maldives'
  },
  {
    code: 'MW', label: 'Malawi'
  },
  {
    code: 'MX', label: 'Mexico'
  },
  {
    code: 'MY', label: 'Malaysia'
  },
  {
    code: 'MZ', label: 'Mozambique'
  },
  {
    code: 'NA', label: 'Namibia'
  },
  {
    code: 'NC', label: 'New Caledonia'
  },
  {
    code: 'NE', label: 'Niger'
  },
  {
    code: 'NF', label: 'Norfolk Island'
  },
  {
    code: 'NG', label: 'Nigeria'
  },
  {
    code: 'NI', label: 'Nicaragua'
  },
  {
    code: 'NL', label: 'Netherlands'
  },
  {
    code: 'NO', label: 'Norway'
  },
  {
    code: 'NP', label: 'Nepal'
  },
  {
    code: 'NR', label: 'Nauru'
  },
  {
    code: 'NU', label: 'Niue'
  },
  {
    code: 'NZ', label: 'New Zealand'
  },
  {
    code: 'OM', label: 'Oman'
  },
  {
    code: 'PA', label: 'Panama'
  },
  {
    code: 'PE', label: 'Peru'
  },
  {
    code: 'PF', label: 'French Polynesia'
  },
  {
    code: 'PG', label: 'Papua New Guinea'
  },
  {
    code: 'PH', label: 'Philippines'
  },
  {
    code: 'PK', label: 'Pakistan'
  },
  {
    code: 'PL', label: 'Poland'
  },
  {
    code: 'PM',
    label: 'Saint Pierre and Miquelon',
  },
  {
    code: 'PN', label: 'Pitcairn'
  },
  {
    code: 'PR', label: 'Puerto Rico'
  },
  {
    code: 'PS',
    label: 'Palestine, State of',
  },
  {
    code: 'PT', label: 'Portugal'
  },
  {
    code: 'PW', label: 'Palau'
  },
  {
    code: 'PY', label: 'Paraguay'
  },
  {
    code: 'QA', label: 'Qatar'
  },
  {
    code: 'RE', label: 'Reunion'
  },
  {
    code: 'RO', label: 'Romania'
  },
  {
    code: 'RS', label: 'Serbia'
  },
  {
    code: 'RU', label: 'Russian Federation'
  },
  {
    code: 'RW', label: 'Rwanda'
  },
  {
    code: 'SA', label: 'Saudi Arabia'
  },
  {
    code: 'SB', label: 'Solomon Islands'
  },
  {
    code: 'SC', label: 'Seychelles'
  },
  {
    code: 'SD', label: 'Sudan'
  },
  {
    code: 'SE', label: 'Sweden'
  },
  {
    code: 'SG', label: 'Singapore'
  },
  {
    code: 'SH', label: 'Saint Helena'
  },
  {
    code: 'SI', label: 'Slovenia'
  },
  {
    code: 'SJ',
    label: 'Svalbard and Jan Mayen',
  },
  {
    code: 'SK', label: 'Slovakia'
  },
  {
    code: 'SL', label: 'Sierra Leone'
  },
  {
    code: 'SM', label: 'San Marino'
  },
  {
    code: 'SN', label: 'Senegal'
  },
  {
    code: 'SO', label: 'Somalia'
  },
  {
    code: 'SR', label: 'Suriname'
  },
  {
    code: 'SS', label: 'South Sudan'
  },
  {
    code: 'ST',
    label: 'Sao Tome and Principe',
  },
  {
    code: 'SV', label: 'El Salvador'
  },
  {
    code: 'SX',
    label: 'Sint Maarten (Dutch part)',
  },
  {
    code: 'SY',
    label: 'Syrian Arab Republic',
  },
  {
    code: 'SZ', label: 'Swaziland'
  },
  {
    code: 'TC',
    label: 'Turks and Caicos Islands',
  },
  {
    code: 'TD', label: 'Chad'
  },
  {
    code: 'TF',
    label: 'French Southern Territories',
  },
  {
    code: 'TG', label: 'Togo'
  },
  {
    code: 'TH', label: 'Thailand'
  },
  {
    code: 'TJ', label: 'Tajikistan'
  },
  {
    code: 'TK', label: 'Tokelau'
  },
  {
    code: 'TL', label: 'Timor-Leste'
  },
  {
    code: 'TM', label: 'Turkmenistan'
  },
  {
    code: 'TN', label: 'Tunisia'
  },
  {
    code: 'TO', label: 'Tonga'
  },
  {
    code: 'TR', label: 'Turkey'
  },
  {
    code: 'TT',
    label: 'Trinidad and Tobago',
  },
  {
    code: 'TV', label: 'Tuvalu'
  },
  {
    code: 'TW',
    label: 'Taiwan, Republic of China',
  },
  {
    code: 'TZ',
    label: 'United Republic of Tanzania',
  },
  {
    code: 'UA', label: 'Ukraine'
  },
  {
    code: 'UG', label: 'Uganda'
  },
  {
    code: 'US',
    label: 'United States',
  },
  {
    code: 'UY', label: 'Uruguay'
  },
  {
    code: 'UZ', label: 'Uzbekistan'
  },
  {
    code: 'VA',
    label: 'Holy See (Vatican City State)',
  },
  {
    code: 'VC',
    label: 'Saint Vincent and the Grenadines',
  },
  {
    code: 'VE', label: 'Venezuela'
  },
  {
    code: 'VG',
    label: 'British Virgin Islands',
  },
  {
    code: 'VI',
    label: 'US Virgin Islands',
  },
  {
    code: 'VN', label: 'Vietnam'
  },
  {
    code: 'VU', label: 'Vanuatu'
  },
  {
    code: 'WF', label: 'Wallis and Futuna'
  },
  {
    code: 'WS', label: 'Samoa'
  },
  {
    code: 'XK', label: 'Kosovo'
  },
  {
    code: 'YE', label: 'Yemen'
  },
  {
    code: 'YT', label: 'Mayotte'
  },
  {
    code: 'ZA', label: 'South Africa'
  },
  {
    code: 'ZM', label: 'Zambia'
  },
  {
    code: 'ZW', label: 'Zimbabwe'
  },
];
export default Players;