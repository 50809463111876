import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import { Box,  useTheme, Grid, Typography, Modal, Fade, Divider, IconButton, Checkbox, FormControlLabel } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import { tokens } from "../../theme";
import api from "../../components/confs";

import { Pie } from '@nivo/pie';


const EvaluationProfileModal = ({ openModal, setOpenModal, value_id, setLoader, name, goalkeeper }) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  const [profile, setProfile] = useState({});

  const [expanded, setExpanded] = React.useState(false);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (value_id !== null) {

      setLoader(true)
      api.get('/api/evaluation_profile?id=' + value_id, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
        .then((response) => {
          setProfile(response.data)
        })
        .catch(error => {
          console.error(error);
          if (error.response.data.detail === "unauthorized login") {
            setToken(null)
          }
        }).finally(() => {
          setLoader(false)
        });
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [value_id])

  function closeModal() {
    setOpenModal(false);
  }


  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={closeModal}
      closeAfterTransition
    >
      <Fade in={openModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 900,
            height: 600,
            bgcolor: colors.primary[400],
            boxShadow: 24,
            p: 4,
          }}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{
              paddingBottom: '10px',
              borderStyle: 'solid',
              borderColor: colors.blueAccent[300],
              borderWidth: '0 0 2px 0'
            }}>
              <Typography component={'span'} variant="h3">{name}</Typography>
              <IconButton
                aria-label="close"
                onClick={closeModal}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Divider flexItem variant="middle" />
            <Grid item xs={12}>

              <Grid container spacing={2}>
              <Grid item xs={12}>
                  <FormControlLabel
                  ml={2}
                  control={
                    <Checkbox
                    disabled={true}
                      ml={2}
                      checked={goalkeeper}
                      color={goalkeeper ? 'success' : 'default'}
                    />
                  }
                  label="Guarda Redes"
                />
                </Grid>
                <Grid item xs={6}>
                  <Pie
                    data={(Array.isArray(profile)) ? profile.map(({ name: id, value }) => ({ id, value })) : []}
                    width={400}
                    height={400}
                    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                    innerRadius={0.9}
                    padAngle={0.7}
                    cornerRadius={3}
                    colors={{ scheme: 'nivo' }}
                    borderWidth={1}
                    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                    arcLinkLabelsTextColor={colors.primary[100]}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    tooltip={(tooltipProps) => {
                      const { datum } = tooltipProps;
                      return (
                        <div style={{ background: '#f1f1f1', color: '#333', border: '1px solid #ccc', borderRadius: '5px', padding: '5px' }}>
                          <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>{datum.label} {`${datum.value}%`}</div>
                        </div>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6}>

                  {(Array.isArray(profile)) &&
                    profile
                      .map((classes_, idx) => {
                        return (
                          <Accordion expanded={expanded === 'panel' + idx} onChange={handleChangeAccordion('panel' + idx)} key={'panel' + idx}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={"panel" + idx + "bh-content"}
                              id={"panel" + idx + "bh-header"}
                            >
                              <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                {classes_.name}
                              </Typography>
                              <Typography sx={{ color: 'text.secondary' }}>{classes_.value + " %"}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>

                              <Grid container spacing={2}>
                                {(Array.isArray(classes_.attributes)) &&
                                  classes_.attributes
                                    .map((attributes, idx2) => {
                                      return (
                                        <Grid item key={"grid-" + idx2}>
                                          <Chip key={"chip-" + idx2} label={attributes} variant="outlined" />
                                        </Grid>
                                      )
                                    })}
                              </Grid>
                            </AccordionDetails>
                          </Accordion>

                        )
                      })}
                </Grid>
                
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>



  );
};


export default EvaluationProfileModal;