import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Box, useTheme, Grid, Typography, Modal, Fade, List, ListItem,
  ListItemText, Divider, Autocomplete, IconButton, Checkbox, FormControlLabel, Tooltip,
  Step, Stepper, StepLabel, Button, Chip, Stack, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { tokens } from "../../theme";
import api from "../../components/confs";

import { DateTimePicker } from '@mui/x-date-pickers';
import TextBox from "../../components/TextBox";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Message from "../../components/Message";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { pt } from "date-fns/locale";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import InfoIcon from '@mui/icons-material/Info';

const NewGameModal = ({ openModal, setOpenModal, setLoader, name, setForceTodayGames, forceTodayGames }) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  const [messageModalName, setMessageName] = useState("");
  const [messageModal, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("success")

  const [openModal1, setOpenModal1] = useState(false);

  const [associations, setAssociations] = useState([]);
  const [association1, setAssociation1] = useState(null);
  const [association2, setAssociation2] = useState(null);

  const [teams1, setTeams1] = useState([]);
  const [teams2, setTeams2] = useState([]);
  const [team1, setTeam1] = useState(null);
  const [team2, setTeam2] = useState(null);

  const [fields, setFields] = useState([]);
  const [grades, setGrades] = useState([]);
  const [grade, setGrade] = useState(null);
  const [typeGame, setTypeGame] = useState(null);
  const [local, setLocal] = useState(null);

  const [players1, setPlayers1] = useState([])
  const [players2, setPlayers2] = useState([])

  const [searchPlayer1, setSearchPlayer1] = useState("");
  const [searchPlayer2, setSearchPlayer2] = useState("");
  const [playersSelected1, setPlayersSelected1] = useState([]);
  const [playersSelected2, setPlayersSelected2] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [playersToChoose, setPlayersToChoose] = useState([]);
  const [otherField, setOtherField] = useState(false);
  const [teamChoosed, setTeamChoosed] = useState(0);
  const [observacao, setObservacao] = useState("")
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };


  //buscar a lista de campos
  useEffect(() => {
    if (team1 !== null && Object.keys(team1).length !== 0) {
      const data = { id: team1.id, other: otherField };
      api.get('/api/football_fields/search',
        {
          params: data,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        })
        .then(response => {

          setFields(response.data);
        })
        .catch(error => {
          console.error(error);
          if (error.response.data.detail === "unauthorized login") {
            setToken(null)
          }
        }).finally(() => {
        });

    }
    // eslint-disable-next-line
  }, [otherField, team1])




  useEffect(() => {
    get_associations();
    get_grades();
    // eslint-disable-next-line
  }, [])

  //Buscar lista de equipas 1
  useEffect(() => {
    setTeam1(null);
    if (association1 !== null) {
      api.get('/api/teams/search',
        {
          params: { name: " ", association: association1.value },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        })
        .then(response => {
          setTeams1(response.data);
        })
        .catch(error => {
          console.error(error);
          if (error.response.data.detail === "unauthorized login") {
            setToken(null)
          }
        }).finally(() => {
        });
    } else {
      setTeams1([])
    }
    // eslint-disable-next-line
  }, [association1])

  //Buscar lista de equipas 2
  useEffect(() => {
    setTeam2(null);
    if (association2 !== null) {

      api.get('/api/teams/search',
        {
          params: { name: " ", association: association2.value },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        })
        .then(response => {
          setTeams2(response.data);
        })
        .catch(error => {
          console.error(error);
          if (error.response.data.detail === "unauthorized login") {
            setToken(null)
          }
        }).finally(() => {

        });
    } else {
      setTeams2([])
    }
    // eslint-disable-next-line
  }, [association2])


  const get_associations = () => {
    api.get('api/associations', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {
        const options = response.data.map(({ id, name }) => ({ value: id, label: name }));
        setAssociations(options);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }

  const get_grades = () => {
    api.get('api/grades', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {

        setGrades(response.data);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }

  function closeModal() {
    setOpenModal(false);
  }


  function closeModal1() {
    setOpenModal1(false);
  }

  //*************Stepper ************************** */

  const steps = ['Informação do jogo', 'Atletas para observação', 'Observações'];
  const [activeStep, setActiveStep] = React.useState(0);


  const handleNext = () => {

    if (activeStep === steps.length - 1) {
      const playersToObs = [];
      const newplayersToObs = [];

      playersSelected1.forEach(array => {
        if (!array.newTeam) {
          playersToObs.push({ 'player': array.id, 'team': team1.id });
        } else {
          newplayersToObs.push({ 'id': array.id, 'team': team1.id });
        }
      });

      playersSelected2.forEach(array => {
        if (!array.newTeam) {
          playersToObs.push({ 'player': array.id, 'team': team2.id });
        } else {
          newplayersToObs.push({ 'id': array.id, 'team': team2.id });
        }
      });

      const data = {
        "game": {
          "date": selectedDate,
          "association1": association1.value,
          "team1": team1.id,
          "association2": association2.value,
          "team2": team2.id,
          "grade": grade.id,
          "type_of_game": typeGame.label,
          "local": local.id,
          "obs": observacao,
        },
        "players": playersToObs,
        "new_players": newplayersToObs,
      }

      submit(data)
      return;
    }

    if (
      team1 != null &&
      team2 != null &&
      grade != null &&
      selectedDate != null &&
      local != null &&
      typeGame != null
    ) {

      get_players1();
      get_players2();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    else {
      setMessageName("Todos os campos são obrigatórios!");
      setSeverity("warning");
      setOpenMessage(true);
    }


  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };



  const handleReset = () => {
    setActiveStep(0);
  };


  const get_players1 = () => {
    api.post('api/team/player',
      {
        "team_id": team1.id,
        "age": grade.age
      }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {
        setPlayers1(response.data);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }

  const get_players2 = () => {

    api.post('api/team/player',
      {
        "team_id": team2.id,
        "age": grade.age
      }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {
        setPlayers2(response.data);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }



  const handleToggle1 = (item) => () => {
    const currentIndex = playersSelected1.indexOf(item);
    const newCheckedItems = [...playersSelected1];

    if (currentIndex === -1) {
      newCheckedItems.push(item);
    } else {
      newCheckedItems.splice(currentIndex, 1);
    }

    setPlayersSelected1(newCheckedItems);
  };
  const handleToggle2 = (item) => () => {
    const currentIndex = playersSelected2.indexOf(item);
    const newCheckedItems = [...playersSelected2];

    if (currentIndex === -1) {
      newCheckedItems.push(item);
    } else {
      newCheckedItems.splice(currentIndex, 1);
    }

    setPlayersSelected2(newCheckedItems);
  };




  function searchUser1() {
    setTeamChoosed(1);
    setLoader(true);
    setOpenModal1(true);
    search_player(searchPlayer1, grade.age);
  }

  function searchUser2() {
    setTeamChoosed(2);
    setLoader(true);
    setOpenModal1(true);
    search_player(searchPlayer2, grade.age);
  }

  const search_player = (name, age) => {
    const playerFilter = {
      complete_name: name,
      shirt_number: 0,
      shirt_name: name,
      minAge: age - 1,
      maxAge: age + 1,
      naturality: "",
      position: 0,
      foot: "",
      sex: "",
      minHeight: 0,
      maxHeight: 250,
      minWeight: 0,
      maxWeight: 250,
      agent: "",
    }

    api.get('api/players',
      {
        params: playerFilter,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then(response => {
        setPlayersToChoose(response.data);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      }).finally(() => {
        setLoader(false);
      });
  }



  function submit(data) {
    setLoader(true);
    api.post('api/game', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {
        setOpenModal(false);
        setMessageName("Jogo criado com sucesso!");
        setSeverity("success");
        setOpenMessage(true);

      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
        setMessageName(error);
        setSeverity("error");
        setOpenMessage(true);

      }).finally(() => {
        setOpenModal(false);
        //clean data
        setAssociations([]);
        setAssociation1(null);
        setAssociation2(null);
        setTeams1([]);
        setTeams2([]);
        setTeam1(null);
        setTeam2(null);
        setFields([]);
        setGrades([]);
        setGrade(null);
        setTypeGame(null);
        setLocal(null);
        setPlayers1([])
        setPlayers2([])
        setSearchPlayer1("");
        setSearchPlayer2("");
        setPlayersSelected1([]);
        setPlayersSelected2([]);
        setSelectedDate(new Date());
        setActiveStep(0);
        setObservacao("");
        get_associations();
        get_grades();
        setForceTodayGames(!forceTodayGames);
        setLoader(false);

      });

  }


  function AddPlayer(player) {
    if (teamChoosed === 1) {
      const novoJogador = { id: player.id, label: player.complete_name + " - " + grade.name + " (Transferência)", team: team2.id, name: searchPlayer2, newTeam: true };
      setSearchPlayer1("")
      setPlayers1([...players1, novoJogador]);
      setOpenModal1(false)
      return;
    }
    if (teamChoosed === 2) {
      const novoJogador = { id: player.id, label: player.complete_name + " - " + grade.name + " (Transferência)", team: team2.id, name: searchPlayer2, newTeam: true };
      setSearchPlayer2("")
      setPlayers2([...players2, novoJogador]);
      setOpenModal1(false)
      return;
    }
  }
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={closeModal}
        closeAfterTransition
      >
        <Fade in={openModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 900,
              height: 600,
              bgcolor: colors.primary[400],
              boxShadow: 24,
              p: 4,
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{
                paddingBottom: '10px',
                borderStyle: 'solid',
                borderColor: colors.blueAccent[300],
                borderWidth: '0 0 2px 0'
              }}>
                <Typography component={'span'} variant="h3">{name}</Typography>
                <IconButton
                  aria-label="close"
                  onClick={closeModal}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Divider flexItem variant="middle" />
              <Grid item xs={12} mt={2}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}
                          StepIconProps={{
                            style: {
                              color: index === activeStep
                                ? colors.blueAccent[500]
                                : index < activeStep
                                  ? colors.greenAccent[500]
                                  : colors.grey[500],
                            },
                          }}
                        >{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Box sx={{ flex: '1 1 auto' }} />
                      <Button onClick={handleReset}>Reset</Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {activeStep === 0 ? (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>

                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="h4" mb={2}>Equipas</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <Autocomplete
                                    options={associations}
                                    getOptionLabel={(option) => option.label}
                                    isOptionEqualToValue={(option, value) => value && option === value}
                                    renderInput={(params) => (
                                      <TextBox {...params} label="Associação Visitada" variant="outlined" />
                                    )}
                                    onChange={(event, value) => { setAssociation1(value) }}
                                    value={association1}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Autocomplete
                                    options={teams1}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => value && option === value}
                                    renderInput={(params) => (
                                      <TextBox {...params} label="Equipa Visitada" variant="outlined" />
                                    )}
                                    onChange={(event, value) => { setTeam1(value) }}
                                    value={team1}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={6}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <Autocomplete
                                    options={associations}
                                    getOptionLabel={(option) => option.label}
                                    isOptionEqualToValue={(option, value) => value && option === value}
                                    renderInput={(params) => (
                                      <TextBox {...params} label="Associação Visitante" variant="outlined" />
                                    )}
                                    onChange={(event, value) => { setAssociation2(value) }}
                                    value={association2}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Autocomplete
                                    options={teams2}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => value && option === value}
                                    renderInput={(params) => (
                                      <TextBox {...params} label="Equipa Visitante" variant="outlined" />
                                    )}
                                    onChange={(event, value) => { setTeam2(value) }}
                                    value={team2}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} mt={3} ml={0} mr={0}>

                          <Divider flexItem variant="middle" />
                        </Grid>

                        <Grid item xs={12}>

                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="h4" mb={2}>Informações</Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pt}>
                                <DateTimePicker
                                  label="Data e hora"
                                  value={selectedDate}
                                  onChange={handleDateChange}
                                  renderInput={(props) => <TextBox {...props} />}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={8}  >
                              <Grid container spacing={2} alignItems="center">
                                <Grid item xs={8}>
                                  <Autocomplete
                                    options={fields}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => value && option === value}
                                    renderInput={(params) => (
                                      <TextBox {...params} label="Local" variant="outlined" />
                                    )}
                                    onChange={(event, value) => { setLocal(value) }}
                                    value={local}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={otherField}
                                        onChange={() => { setOtherField(!otherField) }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                      />
                                    }
                                    label="Outro"
                                  />
                                </Grid>
                              </Grid>

                            </Grid>
                            <Grid item xs={6}>
                              <Autocomplete
                                options={grades}
                                getOptionLabel={(option) => option.name || ""}
                                isOptionEqualToValue={(option, value) => value && option.id === value.id}
                                renderInput={(params) => (
                                  <TextBox {...params} label="Escalão" variant="outlined" />
                                )}
                                onChange={(event, value) => { setGrade(value); }}
                                value={grade}
                              />
                            </Grid>
                            <Grid item xs={6}>

                              <Autocomplete
                                options={[
                                  { id: 1, label: "Amigável" },
                                  { id: 2, label: "Torneio" },
                                  { id: 3, label: "Treino" },
                                  { id: 4, label: "Regional" },
                                  { id: 5, label: "Distrital" },
                                  { id: 6, label: "Nacional" },
                                  { id: 7, label: "Continental" },
                                  { id: 8, label: "Mundial" }]}
                                getOptionLabel={(option) => option.label}
                                isOptionEqualToValue={(option, value) => {
                                  return option.id === value.id; // Comparação usando a propriedade 'id'
                                }}
                                renderInput={(params) => (
                                  <TextBox {...params} label="Tipo de jogo" variant="outlined" />
                                )}
                                onChange={(event, value) => { setTypeGame(value) }}
                                value={typeGame}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                      </Grid>
                    ) :
                      <>
                        {activeStep === 1 ? (
                          <>
                            <Grid container justifyContent="flex-end">
                              <Tooltip title="Se pesquisar um novo atleta de outra equipa e na lista da equipa selecionar esse atleta, então irá ocorrer transferência no momento da criação do jogo!">
                                <IconButton>
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid container spacing={2}>

                              <Grid item xs={6}>
                                <Typography variant="h4">
                                  {team1 != null ? team1.name : ""}
                                </Typography>
                                <Grid container spacing={2} mt={2}>
                                  <Grid item xs={12}>
                                    <List style={{ maxHeight: 200, overflow: 'auto' }}>
                                      {players1.map((item) => (
                                        <ListItem key={item.id} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                                          <Checkbox
                                            checked={playersSelected1.some(objeto => objeto.id === item.id)}
                                            onChange={handleToggle1(item)} // Adicione esta linha
                                            tabIndex={-1}
                                            disableRipple
                                          />
                                          <ListItemText primary={item.label} />
                                        </ListItem>
                                      ))}
                                    </List>
                                  </Grid>
                                </Grid>

                                <Grid container spacing={2} mt={2}>

                                  <Grid item xs={8}>
                                    <TextBox
                                      fullWidth
                                      name="userNew1"
                                      id="userNew1"
                                      label="Novo atleta"
                                      variant="outlined"
                                      value={searchPlayer1}
                                      sx={{ backgroundColor: colors.primary[400] }}
                                      onChange={event => { setSearchPlayer1(event.target.value); }}
                                    />
                                  </Grid>
                                  <Grid item xs={4}>

                                    <Button
                                      sx={{
                                        backgroundColor: colors.blueAccent[700],
                                        color: colors.grey[100],
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        padding: "10px 20px",
                                      }}
                                      variant="contained" color="primary"
                                      onClick={() => { searchUser1() }}
                                    >
                                      Pesquisar
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs={6}>
                                <Typography variant="h4">
                                  {team2 != null ? team2.name : ""}
                                </Typography>
                                <Grid container spacing={2} mt={2}>
                                  <Grid item xs={12}>
                                    <List style={{ maxHeight: 200, overflow: 'auto' }}>
                                      {players2.map((item) => (
                                        <ListItem key={item.id} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                                          <Checkbox
                                            checked={playersSelected2.some(objeto => objeto.id === item.id)}
                                            onChange={handleToggle2(item)} // Adicione esta linha
                                            tabIndex={-1}
                                            disableRipple
                                          />
                                          <ListItemText primary={item.label} />
                                        </ListItem>
                                      ))}
                                    </List>
                                  </Grid>
                                </Grid>

                                <Grid container spacing={2} mt={2}>

                                  <Grid item xs={8}>
                                    <TextBox
                                      fullWidth
                                      name="userNew2"
                                      id="userNew2"
                                      label="Novo atleta"
                                      variant="outlined"
                                      value={searchPlayer2}
                                      sx={{ backgroundColor: colors.primary[400] }}
                                      onChange={event => { setSearchPlayer2(event.target.value); }}
                                    />
                                  </Grid>
                                  <Grid item xs={4}>

                                    <Button
                                      sx={{
                                        backgroundColor: colors.blueAccent[700],
                                        color: colors.grey[100],
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        padding: "10px 20px",
                                      }}
                                      variant="contained" color="primary"
                                      onClick={() => { searchUser2() }}
                                    >
                                      Adicionar
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid container justifyContent="flex-end" mt={3}>

                              <TextBox
                                id="outlined-multiline-static"
                                multiline
                                fullWidth
                                rows={10}
                                label="Observação"
                                value={observacao}
                                onChange={(e) => { setObservacao(e.target.value) }} />
                            </Grid>
                          </>
                        )}
                      </>


                    }

                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      {activeStep !== 0 &&
                        <Button
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                          variant="contained" color="success"
                        >
                          Voltar
                        </Button>
                      }
                      <Box sx={{ flex: '1 1 auto' }} />


                      <Button onClick={handleNext} variant="contained" color="success">
                        {activeStep === steps.length - 1 ? 'Gravar' : 'Próximo'}
                      </Button>
                    </Box>
                  </React.Fragment>
                )}


              </Grid>
            </Grid>

          </Box>
        </Fade>
      </Modal>

      <Message messageModal={messageModal} messageModalName={messageModalName} severity={severity} setOpenMessage={setOpenMessage} />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal1}
        onClose={closeModal1}
        closeAfterTransition
      >
        <Fade in={openModal1}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 600,
              height: 600,
              bgcolor: colors.primary[400],
              boxShadow: 24,
              p: 4,
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{
                paddingBottom: '10px',
                borderStyle: 'solid',
                borderColor: colors.blueAccent[300],
                borderWidth: '0 0 2px 0'
              }}>
                <Typography component={'span'} variant="h3">Escolher Jogador</Typography>
                <IconButton
                  aria-label="close"
                  onClick={closeModal1}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Divider flexItem variant="middle" />
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                  <Alert severity="info">
                    <AlertTitle>Atenção</AlertTitle>
                    Ao selecionar o atleta, esse atleta será transferido para a respetiva equipa!
                  </Alert>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer sx={{ maxHeight: 400 }}>
                    <Table stickyHeader aria-label="custom table sticky">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Nome</TableCell>
                          <TableCell align="left">Clube</TableCell>
                          <TableCell align="left">Pé</TableCell>
                          <TableCell align="center">Posição</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>

                        {playersToChoose.map((row) => (
                          <TableRow
                            key={row.id}
                            onClick={() => { AddPlayer(row) }}
                            hover
                            style={{ cursor: 'pointer' }}
                          >
                            <TableCell>{row.complete_name + " - " + row.birthday + " anos"}</TableCell>
                            <TableCell>{row.team}</TableCell>
                            <TableCell>{row.foot}</TableCell>
                            <TableCell align="center">
                              <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" style={{ margin: 'auto' }}>
                                {row.position1 !== 0 && (
                                  <Chip label={row.position1} />
                                )}
                                {row.position2 !== 0 && (
                                  <Chip label={row.position2} />
                                )}
                                {row.position3 !== 0 && (
                                  <Chip label={row.position3} />
                                )}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}

                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>

            </Grid>

          </Box>
        </Fade>
      </Modal>
    </>

  );
};


export default NewGameModal;