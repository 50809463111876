import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Box, Button, useTheme, Grid, Avatar, IconButton, Typography, Divider, Fade, Modal, Autocomplete
} from "@mui/material";
import { tokens } from "../../theme";
import api from "../../components/confs";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import countries from "../../components/countries";
import TextBox from '../../components/TextBox';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const UpdatePlayerModal = ({
  setOpenUpdateModal, setMessageName, setOpenMessage,
  setSeverity, setLoader, openUpdateModal, state
}) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [date_birth, setDate] = useState(null);
  const [preview, setPreview] = useState()
  const [selectedFile, setSelectedFile] = useState()
  const [playerData, setPlayerData] = useState(state)

  useEffect(() => {
    setPlayerData(state);
    // eslint-disable-next-line 
  }, [openUpdateModal])


  // To show image on create team
  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    setSelectedFile(e.target.files[0])
  }

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const updatePlayer = (event) => {
    event.preventDefault();
    setLoader(true)

    const date = new Date(date_birth)
    date.setDate(date.getDate());

    const formData = new FormData(event.currentTarget);
    const data = {
      'id': playerData.id,
      'complete_name': formData.get('complete_name'),
      'shirt_name': formData.get('shirt_name'),
      'shirt_number': parseInt(formData.get('shirt_number').trim() === "" ? 0 : formData.get('shirt_number')),
      'birthday': date.toISOString().slice(0, 10),
      'naturality': formData.get('naturality'),
      'position1': parseInt(formData.get('position1').trim() === "" ? 0 : formData.get('position1')),
      'position2': parseInt(formData.get('position2').trim() === "" ? 0 : formData.get('position2')),
      'position3': parseInt(formData.get('position3').trim() === "" ? 0 : formData.get('position3')),
      'foot': playerData.foot,
      'sex': playerData.sex,
      'height': parseFloat(formData.get('height').trim() === "" ? 0 : formData.get('height')),
      'weight': parseFloat(formData.get('weight').trim() === "" ? 0 : formData.get('weight')),
      'agent': formData.get('agent'),
      'observations': formData.get('observations'),
    }

    if (formData.get('complete_name').trim().length === 0) {
      setSeverity("warning")
      setMessageName("O nome do atleta é um campo obrigatório")
      setOpenMessage(true);
      setLoader(false)
      return;
    }
    api.put('/api/players', { 'photo': formData.get('photo') }, {
      params: data,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {
        setSeverity("success")
        setMessageName("Atleta atualizado!")
        setOpenMessage(true);
        setPreview("")
        setOpenUpdateModal(false);
      })
      .catch(error => {
        setSeverity("error")
        setMessageName(error)
        setOpenMessage(true);
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      })
      .finally(() => {
        setLoader(false);
      });
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openUpdateModal}
        onClose={() => { setOpenUpdateModal(false); setPreview(""); }}
        closeAfterTransition
      >
        <Fade in={openUpdateModal}>
          <Box component="form"
            noValidate
            onSubmit={updatePlayer}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 800,
              height: 800,
              bgcolor: colors.primary[400],
              boxShadow: 24,
              p: 4,
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{
                paddingBottom: '10px',
                borderStyle: 'solid',
                borderColor: colors.blueAccent[300],
                borderWidth: '0 0 2px 0'
              }}>
                <Typography component={'span'} variant="h3">Atualizar atleta</Typography>
                <IconButton
                  aria-label="close"
                  onClick={() => { setOpenUpdateModal(false); setPreview(""); }}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Divider flexItem variant="middle" />
              <Grid item xs={12}>
                <Grid container spacing={2} mt={2}>
                  <Grid item xs={3}  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} style={{ display: "flex", justifyContent: 'center' }}>
                        <Avatar alt="Remy Sharp" src={preview} sx={{ width: 100, height: 100, fontSize: 15 }} />
                      </Grid>
                      <Grid item xs={12} style={{ display: "flex", justifyContent: 'center' }}>
                        <input
                          required
                          id="photo"
                          label="Fotografia"
                          name="photo"
                          type="file"
                          onChange={onSelectFile}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={9} >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextBox fullWidth
                          value={playerData.complete_name || ""}
                          onChange={(ev) => {
                            setPlayerData(
                              (prevObject) => ({
                                ...prevObject,
                                complete_name: ev.target.value,
                              }))
                          }}

                          name="complete_name"
                          id="complete_name"
                          label="Nome completo"
                          variant="outlined"
                          required />
                      </Grid>
                      <Grid item xs={8}>
                        <TextBox fullWidth value={playerData.shirt_name || ""} name="shirt_name"
                          id="shirt_name" label="Nome na camisola" variant="outlined"
                          onChange={(ev) => {
                            setPlayerData(
                              (prevObject) => ({
                                ...prevObject,
                                shirt_name: ev.target.value,
                              }))
                          }} />
                      </Grid>
                      <Grid item xs={4}>
                        <TextBox fullWidth value={playerData.shirt_number || ""}
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} name="shirt_number"
                          id="shirt_number" label="Número na camisola" variant="outlined"
                          onChange={(ev) => {
                            setPlayerData(
                              (prevObject) => ({
                                ...prevObject,
                                shirt_number: ev.target.value,
                              }))
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            views={['day']}
                            label="Data de nascimento"
                            value={playerData.birthday || ""}
                            onChange={(newValue) => {
                              setDate(newValue);
                              setPlayerData(
                                (prevObject) => ({
                                  ...prevObject,
                                  birthday: newValue,
                                }))
                            }}
                            renderInput={(params) => <TextBox {...params} />}
                            name="birthday"
                            id="birthday"
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={6}>
                        <Autocomplete
                          id="country-select-demo"
                          options={countries}
                          autoHighlight
                          value={playerData.naturality || null}
                          //getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, value) => option.label === value}
                          onChange={(ev, a) => {
                            setPlayerData(
                              (prevObject) => ({
                                ...prevObject,
                                naturality: a.label,
                              }))
                          }}
                          renderOption={(props, option) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                              <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                              />
                              {option.label} ({option.code})
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextBox
                              name="naturality" id="naturality"
                              {...params}
                              label="Escolha a nacionalidade"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                              }}

                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextBox fullWidth value={playerData.position1 || ""}
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                          name="position1" id="position1" label="Posição 1" variant="outlined"
                          onChange={(ev) => {
                            setPlayerData(
                              (prevObject) => ({
                                ...prevObject,
                                position1: ev.target.value,
                              }))
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextBox fullWidth value={playerData.position2 || ""}
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} name="position2"
                          id="position2" label="Posição 2" variant="outlined"
                          onChange={(ev) => {
                            setPlayerData(
                              (prevObject) => ({
                                ...prevObject,
                                position2: ev.target.value,
                              }))
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextBox fullWidth value={playerData.position3 || ""}
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} name="position3"
                          id="position3" label="Posição 3" variant="outlined"
                          onChange={(ev) => {
                            setPlayerData(
                              (prevObject) => ({
                                ...prevObject,
                                position3: ev.target.value,
                              }))
                          }} />
                      </Grid>
                      <Grid item xs={4}>
                        <Autocomplete
                          disablePortal
                          name="foot" id="foot"
                          options={[{ label: "Não identificado", value: "Não identificado" }, { label: "Direito", value: "Direito" }, { label: "Esquerdo", value: "Esquerdo" }]}
                          value={playerData.foot || "Não identificado"}
                          renderInput={(params) => <TextBox {...params} label="Pé preferido" />}
                          variant="outlined"
                          onChange={(ev, a) => {
                            setPlayerData(
                              (prevObject) => ({
                                ...prevObject,
                                foot: a.value,
                              }))
                          }}
                          isOptionEqualToValue={(option, value) => option.value === value}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Autocomplete
                          disablePortal
                          name="sex" id="sex"
                          options={[{ label: "Masculino", value: "Masculino" }, { label: "Feminino", value: "Feminino" }, { label: "Outro", value: "Outro" }]}
                          renderInput={(params) => <TextBox {...params} label="Sexo" />}
                          variant="outlined"
                          value={playerData.sex || "Outro"}
                          onChange={(ev, a) => {

                            setPlayerData(
                              (prevObject) => ({
                                ...prevObject,
                                sex: a.value,
                              }))
                          }}
                          isOptionEqualToValue={(option, value) => option.value === value}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextBox fullWidth value={playerData.height || ""} name="height" id="height"
                          label="Altura (cm)" variant="outlined"
                          onChange={(ev) => {
                            setPlayerData(
                              (prevObject) => ({
                                ...prevObject,
                                height: ev.target.value,
                              }))
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextBox fullWidth value={playerData.weight || ""} name="weight" id="weight"
                          label="Peso (Kg)" variant="outlined"
                          onChange={(ev) => {
                            setPlayerData(
                              (prevObject) => ({
                                ...prevObject,
                                weight: ev.target.value,
                              }))
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextBox fullWidth value={playerData.agent || ""} name="agent" id="agent"
                          label="Agente/Agência" variant="outlined"
                          onChange={(ev) => {
                            setPlayerData(
                              (prevObject) => ({
                                ...prevObject,
                                agent: ev.target.value,
                              }))
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextBox fullWidth value={playerData.observations || ""} multiline rows={4}
                          name="observations" id="observations" label="Observações" variant="outlined"
                          onChange={(ev) => {
                            setPlayerData(
                              (prevObject) => ({
                                ...prevObject,
                                observations: ev.target.value,
                              }))
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              pt={4}
              spacing={2}
            >
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => { setOpenUpdateModal(false); setPreview(""); }}>Cancelar</Button>
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="success" startIcon={<AddOutlinedIcon />} >Atualizar</Button>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default UpdatePlayerModal;


