import { TextField } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { tokens } from "../theme";

const TextBox = styled(TextField)(() => {
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  return {
    '& label.Mui-focused': {
      color: colors.grey[100],
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colors.grey[100],
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: colors.grey[100],
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.grey[100],
      },
    },
  };
});

export default TextBox