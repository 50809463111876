import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { ColorModeContext, tokens } from "../../theme"; 
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"; 
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import { Link } from 'react-router-dom'; 
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
 import api from "../../components/confs";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
const Topbar = (props) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowNotifications, setRowsNotifications] = useState([]);
  const [notificationsCounter, setNotificationsCounter] = useState(0);


  const notifications = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const callLink = (link, id) => {
    const config = { 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    };
    api.get('/api/notifications/' + id, config)
      .then((response) => {
        if (response.status === 200) {
          setAnchorEl(null)
          navigate(link);
        }
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      })
      .finally(() => {
      });
  };



  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    };
    api.get('/api/notifications/not_viewed', config)
      .then((response) => {
        setRowsNotifications(response.data)
        setNotificationsCounter(response.data.length)
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      })
      .finally(() => {
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location]);

  const checkPermission = (id) => {
    return (
      user ? (user.is_admin ? true : (user.permissions.find(permission => permission.permissions === id)?.have)) : false
    )
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" p={2}
        sx={{
          position: "block",
          marginLeft: props.collapse === true ? 15 : 40
        }}>
        <Box
          display="flex"
          backgroundColor={colors.primary[400]}
          borderRadius="3px"
          >
          {/*  
          <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
          <IconButton type="button" sx={{ p: 1 }} title="Pesquisar">
            <SearchIcon />
          </IconButton>
        */}
        </Box>
        {/* ICONS */}
        <Box display="flex">
          <IconButton onClick={colorMode.toggleColorMode} title="Tons Escuros/Claros">
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>
          <IconButton
            aria-controls={notifications ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={notifications ? 'true' : undefined}
            onClick={handleClick}
            title="Notificações">
            <Badge badgeContent={notificationsCounter} color="warning">
              <NotificationsOutlinedIcon />
            </Badge>
          </IconButton>

         { (checkPermission(1) ) &&
          <IconButton
            component={Link}
            to="/settings"
            title="Definições">
            <SettingsOutlinedIcon />
          </IconButton>
          }

{/*
          <IconButton component={Link} to="/profile"
            title="Perfil">
            <PersonOutlinedIcon />

          </IconButton>
          */}
        </Box>
      </Box>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={notifications}
        onClose={handleClose}
        TransitionComponent={Fade}
        PaperProps={{
          style: {
            width: 350,
          },
        }}
      >
        <Typography variant="h4" p={2}>

          Notificações
        </Typography>


        {rowNotifications.length ? rowNotifications.map((row) => [
          <div>

            <MenuItem
              onClick={() => callLink(row.link, row.id)}
              key={row.id}>
              <ListItemIcon>
                <PersonAddOutlinedIcon fontSize="small" />
              </ListItemIcon>
              {row.name}
            </MenuItem>
            <Divider key={row.id} />
          </div>

        ]) :
          <Typography p={2} style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>Sem novas notificações</Typography>

        }

        <MenuItem component="a" href="/notifications" style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>Ver todas</MenuItem>
      </Menu>
    </>
  );
};

export default Topbar;