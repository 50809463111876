import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Box, Button, useTheme, TableFooter, TablePagination, Grid, TableContainer,  InputBase, IconButton, Typography,
  Tabs, Divider, Fade, Modal, Tab, Table, TableHead, TableBody, TableRow, TableCell
} from "@mui/material";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { tokens } from "../../theme";
import api from "../../components/confs";

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SearchIcon from "@mui/icons-material/Search";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import AddIcCallOutlinedIcon from '@mui/icons-material/AddIcCallOutlined';
import CloseIcon from '@mui/icons-material/Close';
import countries from "../../components/countries";
import ScaleIcon from '@mui/icons-material/Scale';
import HeightIcon from '@mui/icons-material/Height';
import CakeTwoToneIcon from '@mui/icons-material/CakeTwoTone';
import FootballField from "../../components/FootballField";

import TextBox from '../../components/TextBox';
import Historic from "./Historic";
import UpdateIcon from '@mui/icons-material/Update';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import UpdatePlayerModal from "./UpdatePlayerModal";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="Primeira página"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Anterior"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Próxima"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Última página"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};



const Player = (props) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const [loader, setLoader] = useState(false);

  const [state, setState] = useState({
    id: id,
    name: "",
    address: "",
    association_id: "",
    city: "",
    created_on: "",
    email: "",
    fax: "",
    federation_url: "",
    lat: "",
    long: "",
    phone: "",
    photo: "",
    updated_by: "",
    updated_on: "",
  })


  const [stateContacts, setStateContacts] = useState([])


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage1, setRowsPerPage1] = useState(8);


  const [openModal, setOpenModal] = useState(false);
  const [messageModalName, setMessageName] = useState("");
  const [messageModal, setOpenMessage] = useState(false);
  const [search_name, setSearchName] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [severity, setSeverity] = useState("success")

  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [reports, setReports] = useState([]);





  const [name, setName] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [address, setaddress] = useState("");


  function submitNewContact() {
    setLoader(true);
    const data = {
      "name": name,
      "phone": phone,
      "email": email,
      "address": address,
      "table_id": parseInt(id, 10)
    }
    api.post('/api/player/contact', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {
        setMessageName("Contacto criado!")
        setOpenMessage(true);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      })
      .finally(() => {
        get_teams_contact_searching();
        setLoader(false);
        setOpenModal(false);

      });
  }


 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

 

  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0);
  };
  function findCodeByLabel(label) {
    const country = countries.find((country) => country.label === label);

    if (country) {
      return country.code;
    } else {
      return null;
    }
  }

  //--------------------------- ON START ---------------------------//


  useEffect(() => {
    get_player_data();
    get_reports();
    //get_teams_data();
    //get_teams_contact_searching();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    get_player_data();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [openUpdateModal]);
  const get_player_data = () => {
    setLoader(true);
    api.get('/api/player/' + id, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {
        setState(response.data)
      }
      )
      .catch(error => {
        console.error(error);

        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
        navigate('/players');
      }).finally(() => {
        setLoader(false);

      });
  }
 





  //--------------------------*----------------------------------//


  //--------------------------- TABS ---------------------------//


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const StyledTab = styled((props) => <Tab {...props} />)({
    "&.Mui-selected": {
      color: colors.blueAccent[500],
      fontSize: 16
    }
  });
 

  //------------------- Search Contacts ---------------------------//
  useEffect(() => {
    get_teams_contact_searching();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [search_name]);


  const get_teams_contact_searching = () => {
    setPage(0)
    const config = {
      params: {
        id: id,
        search: search_name || ""
      },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    };
    api.get("/api/player/contact/search", config)
      .then(response => {
        setStateContacts(response.data)

      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }

  //-----------------------------------------------------------//



  const get_reports = () => {
    const config = {
 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    };
    api.get("/api/reports/player/"+id, config)
      .then(response => {
        setReports(response.data)
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }

  const ExportPDF = async () => {
    const response = await api.post('/api/player/export/' + id,  
    {
      responseType: 'blob', // Definir responseType como 'blob' para receber dados binários
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
    });
  
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Criar um link de download no navegador e forçar o clique nele
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'output.pdf');
        document.body.appendChild(link);
        link.click();

        // Limpar o URL temporário
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
  }
  const navigate = useNavigate();
  const callRoute = (id) => {
    navigate('/report/' + id);
  }

  return (
    <>

      <Loader loader={loader} />
      <Message messageModal={messageModal} messageModalName={messageModalName} severity={severity} setOpenMessage={setOpenMessage} />


      <Box m="20px" sx={{
        position: "block",
        marginLeft: props.collapse === true ? 15 : 40
      }}>

        <Grid container mt={4}>
          <Grid item xs={2}>
            <Grid container direction="column">

              <Grid item mt={2} xs={12}>
                <img alt={state.name} src={process.env.REACT_APP_API_BASE_URL+"api/photo/player/" + id}
                  style={{
                    display: 'block',
                    maxWidth: 190,
                    maxHeight: 250, 
                    width: 'auto',
                    height: 'auto',
                    objectFit: 'cover',
                  }}
                  loading="lazy" />
              </Grid>
              <Grid item mt={2} xs={12}>
                <Typography variant="h4">{state.complete_name}</Typography>
                <Typography variant="h6">{state.shirt_name + " - " + state.shirt_number}</Typography>
              </Grid>

              {findCodeByLabel(state.naturality) &&
                <Grid item mt={2} xs={12}>
                  <Box display="flex" alignItems="center">
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${findCodeByLabel(state.naturality).toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${findCodeByLabel(state.naturality).toLowerCase()}.png 2x`}
                      alt=""
                    />
                    <Box ml={1}>{"     " + state.naturality}</Box>
                  </Box>
                </Grid>
              }

              <Grid item xs={12} mt={1}>
                <Box display="flex" alignItems="center">

                  <CakeTwoToneIcon />
                  <Typography variant="h5" ml={1}>
                    {state.birthday + " (" + state.age + " anos)"}

                  </Typography>
                </Box>

              </Grid>

              <Grid item xs={12} mt={1}>
                <Box display="flex" alignItems="center">
                  <ScaleIcon />
                  <Box ml={1}>{(state.weight) + "Kg - " + state.weight_last_update}</Box>
                </Box>
              </Grid>
              <Grid item xs={12} mt={1}>
                <Box display="flex" alignItems="center">
                  <HeightIcon />
                  <Box ml={1}>{(state.height) + "cm - " + state.height_last_update}</Box>
                </Box>
              </Grid>

              <Grid item xs={12} mt={1}>
                <FootballField playerNumbers={[state.position1, state.position2, state.position3]} footeballType={11} size={250} fieldColor={colors.grey[900]} linesColor={colors.grey[100]} />
              </Grid>

              <Grid item mt={2} xs={12}>
                <Button
                  sx={{
                    backgroundColor: colors.grey[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                  }}
                  onClick={() => { setOpenUpdateModal(true); }}
                >
                  <UpdateIcon sx={{ mr: "10px" }} />
                  Atualizar dados
                </Button>
              </Grid>
              <Grid item mt={2} xs={12}>
                <Button
                  sx={{
                    backgroundColor: colors.grey[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                  }}
                  onClick={() => { ExportPDF(); }}
                >
                  <FileDownloadOutlinedIcon sx={{ mr: "10px" }} />
                  Exportar
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={10}>
            <Tabs value={selectedTab} onChange={handleTabChange}
              TabIndicatorProps={{ style: { background: colors.blueAccent[500] } }}>
              <StyledTab label="Histórico de equipas" />
              <StyledTab label="Contatos" />
              <StyledTab label="Relatórios de Observação" />
            </Tabs>
            {selectedTab === 0 && (
              <Historic id={id} />
            )}
            {selectedTab === 1 && (



              <Grid container>
                <Grid item xs={10} mt={2}>

                  <Box
                    display="flex"
                    backgroundColor={colors.primary[400]}
                    borderRadius="3px"
                  >
                    <InputBase sx={{ ml: 2, mt: 1, mb: 1, flex: 1 }} placeholder="Procurar Contacto"
                      onChange={(e) => {
                        setSearchName(e.target.value);
                      }}
                      value={search_name || ''}
                      inputProps={{ style: { fontSize: 14 } }} />
                    <IconButton type="button" sx={{ p: 1 }}>
                      <SearchIcon />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item xs={2} mt={2}>

                  <Box style={{ textAlign: 'right' }}>
                    <Button
                      sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                      }}
                      onClick={() => { setOpenModal(true) }}
                    >
                      <  AddIcCallOutlinedIcon sx={{ mr: "10px" }} />
                      Criar Contacto
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} mt={2}>

                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Nome</TableCell>
                          <TableCell>Morada</TableCell>
                          <TableCell>Telemóvel</TableCell>
                          <TableCell>E-mail</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(rowsPerPage > 0
                          ? stateContacts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          : stateContacts
                        ).map((row, i) => (
                          <TableRow key={"contact-" + i}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.address}</TableCell>
                            <TableCell>{row.phone}</TableCell>
                            <TableCell>{row.email}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            //rowsPerPageOptions={[5,   10, 25, { label: 'Tudo', value: -1 }]}
                            rowsPerPageOptions={[8]}
                            colSpan={4}
                            count={stateContacts.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                              inputProps: {
                                'aria-label': 'Linhas por página',
                              },
                              native: true,
                            }}
                            labelRowsPerPage='Linhas por página'
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={(page) =>
                              `${page.from}-${page.to === -1 ? page.count : page.to} de ${page.count
                              }`
                            }
                          >

                          </TablePagination>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>

            )}
            {selectedTab === 2 && (

              <Grid container>
                <Grid item xs={12} mt={2}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Equipa Visitante</TableCell>
                          <TableCell>Equipa Visitada</TableCell>
                          <TableCell>Posição</TableCell>
                          <TableCell>Escalão</TableCell>
                          <TableCell>Cotação</TableCell>
                          <TableCell>Scouter</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(rowsPerPage1 > 0
                          ? reports.slice(page1 * rowsPerPage1, page1 * rowsPerPage1 + rowsPerPage1)
                          : reports
                        ).map((row, i) => (
                          <TableRow key={"report-" + i} onClick={()=>callRoute(row.report_id)} hover style={{ cursor: 'pointer' }} >
                            <TableCell>{row.team1}</TableCell>
                            <TableCell>{row.team2}</TableCell>
                            <TableCell>{row.position}</TableCell>
                            <TableCell>{row.grade}</TableCell>
                            <TableCell>{row.avaliacao}</TableCell>
                            <TableCell>{row.scouter}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            //rowsPerPageOptions={[5,   10, 25, { label: 'Tudo', value: -1 }]}
                            rowsPerPageOptions={[8]}
                            colSpan={6}
                            count={reports.length}
                            rowsPerPage={rowsPerPage1}
                            page={page1}
                            SelectProps={{
                              inputProps: {
                                'aria-label': 'Linhas por página',
                              },
                              native: true,
                            }}
                            labelRowsPerPage='Linhas por página'
                            onPageChange={handleChangePage1}
                            onRowsPerPageChange={handleChangeRowsPerPage1}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={(page1) =>
                              `${page1.from}-${page1.to === -1 ? page1.count : page1.to} de ${page1.count
                              }`
                            }
                          >
                          </TablePagination>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            )}

          </Grid>
        </Grid>



      </Box >

      {/** Contact Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={() => { setOpenModal(false); }}
        closeAfterTransition
      >
        <Fade in={openModal}>
          <Box

            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 700,
              height: 480,
              bgcolor: colors.primary[400],
              boxShadow: 24,
              p: 4,
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{
                paddingBottom: '10px',
                borderStyle: 'solid',
                borderColor: colors.blueAccent[300],
                borderWidth: '0 0 2px 0'
              }}>
                <Typography component={'span'} variant="h3">Criação de novo contacto para associação</Typography>
                <IconButton
                  aria-label="close"
                  onClick={() => { setOpenModal(false); }}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Divider flexItem variant="middle" />
              <Grid item xs={12}>
                <Grid container spacing={2} mt={2}>
                  <Grid item xs={12}>
                    <TextBox fullWidth name="name" id="name" label="Nome" variant="outlined" onChange={(event) => { setName(event.target.value) }} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextBox fullWidth name="address" id="address" label="Morada" variant="outlined" onChange={(event) => { setaddress(event.target.value) }} />
                  </Grid>

                  <Grid item xs={12}>
                    <TextBox fullWidth name="phone" id="phone" label="Telemóvel" variant="outlined" onChange={(event) => { setphone(event.target.value) }} />
                  </Grid>

                  <Grid item xs={12}>
                    <TextBox fullWidth name="email" id="email" label="E-mail" variant="outlined" onChange={(event) => { setemail(event.target.value) }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              pt={4}
              spacing={2}
            >
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => setOpenModal(false)}>Cancelar</Button>
              </Grid>
              <Grid item>
                <Button onClick={() => { submitNewContact(); }} variant="contained" color="success" startIcon={<AddOutlinedIcon />} >Criar</Button>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>

      <UpdatePlayerModal
        setOpenUpdateModal={setOpenUpdateModal}
        setMessageName={setMessageName}
        setOpenMessage={setOpenMessage}
        setSeverity={setSeverity}
        setLoader={setLoader}
        openUpdateModal={openUpdateModal}
        state={state}
      />
    </>


  );
};

export default Player;