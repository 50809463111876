import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Box, Button, useTheme, Grid, Tab, Tabs,
  Avatar, IconButton, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Chip, Stack,
  TableFooter, TablePagination, Typography, Autocomplete, Divider, Modal, Fade
} from "@mui/material";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import TextBox from '../../components/TextBox';
import { tokens } from "../../theme";
import api from "../../components/confs";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { styled } from "@mui/material/styles";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import CloseIcon from '@mui/icons-material/Close';
import Message from "../../components/Message";
import { debounce } from 'lodash';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import CakeTwoToneIcon from '@mui/icons-material/CakeTwoTone';
import ScaleIcon from '@mui/icons-material/Scale';
import HeightIcon from '@mui/icons-material/Height';
import FootballField from "../../components/FootballField";

import SearchIcon from "@mui/icons-material/Search";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const PlayersTempList = (props) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [state, setState] = useState([])
  const [teamsSearch, setTeamsSearch] = useState([])
  const [gradesSearch, setGradesSearch] = useState([])
  const [loader, setLoader] = useState(false);
  const [messageModal, setOpenMessage] = useState(false);
  const [messageModalName, setMessageName] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [severity, setSeverity] = useState("success")
  const [openModal, setOpenModal] = useState(false);
  const [temporary, setTemporary] = useState(null);
  const [tempModal, setTempModal] = useState({});
  const [nameSearch, setNameSearch] = useState("");
  const [teamSearch, setTeamSearch] = useState(0);
  const [gradeSearch, setGradeSearch] = useState(0);
  const [athletesAssociates, setAthletesAssociates] = useState([]);
  const [showdetail, showDetail] = useState(false);
  const [idSelected, setIdSelected] = useState(0);


  const [playerInfo, setPlayerInfo] = useState({
    id: idSelected,
    name: "",
    address: "",
    association_id: "",
    city: "",
    created_on: "",
    email: "",
    fax: "",
    federation_url: "",
    lat: "",
    long: "",
    phone: "",
    photo: "",
    updated_by: "",
    updated_on: "",
  })




  // to table 
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - state.length) : 0;

  // to table
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  // to table
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // To navigate to team page
  const navigate = useNavigate();
  const callRoute = (id) => {
    navigate('/player/' + id);
  }


  function search_player_temp() {
    api.get('api/reports/events/list',
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then(response => {
        setState(response.data);
        console.log(response.data);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }

  function selectPlayerAssociate(id) {

    setIdSelected(id)
    api.get('/api/player/' + id, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {
        setPlayerInfo(response.data)
        showDetail(true)
      }
      )
      .catch(error => {
        console.error(error);

        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
        navigate('/players');
      }).finally(() => {
        setLoader(false);

      });
  }
  function get_all_teams_and_grades() {
    api.get('api/teamsgrades',
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then(response => {
        setTeamsSearch(response.data.teams);
        setGradesSearch(response.data.grades);
        console.log(response.data);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }





  // On start
  useEffect(() => {
    search_player_temp();
    get_all_teams_and_grades();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const submitNewContact = (temporary) => {
  }


  function openModalEvents(event) {
    showDetail(false);
    if (event != null)
      api.get('api/reports/event/' + event,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        })
        .then(response => {
          console.log(response.data);
          try {
            setTempModal(response.data)
          } finally {
            setOpenModal(true)
          }
        })
        .catch(error => {
          console.error(error);
          if (error.response.data.detail === "unauthorized login") {
            setToken(null)
          }
        });
  }
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const StyledTab = styled((props) => <Tab {...props} />)({
    "&.Mui-selected": {
      color: colors.blueAccent[500],
      fontSize: 16
    }
  });
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  function search_players() {

    const data = {
      "name": nameSearch,
      "team": teamSearch,
      "grade": gradeSearch
    }
    console.log(data)
    api.post(`api/reports/players/search`, data,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then(response => {
        setAthletesAssociates(response.data)
        console.log(response.data)
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }


  function findCodeByLabel(label) {
    const country = countries.find((country) => country.label === label);

    if (country) {
      return country.code;
    } else {
      return null;
    }
  }
  return (
    <>
      <Loader loader={loader} />
      <Message messageModal={messageModal} messageModalName={messageModalName} severity={severity} setOpenMessage={setOpenMessage} />
      <Box m="20px" sx={{
        position: "block",
        marginLeft: props.collapse === true ? 15 : 40
      }}>
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Atletas temporários" />
          <Box>

          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>

            <Grid container
              backgroundColor={colors.primary[400]}
              alignItems="center"
              justifyContent="center"

            >
              <Grid item xs={12} >
                <div style={{ width: '94%', marginLeft: 28, marginRight: 28, marginTop: 20, marginBottom: 20 }}>
                  <TableContainer sx={{ backgroundColor: colors.primary[400] }}>
                    <Table aria-label="custom pagination table" >
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Nome</TableCell>
                          <TableCell align="left">Clube</TableCell>
                          <TableCell align="left">Número</TableCell>
                          <TableCell align="center">Escalão</TableCell>
                          <TableCell align="center">Posição</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(rowsPerPage > 0
                          ? state.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          : state
                        ).map((row) => (
                          <TableRow key={row.id} onClick={() => {

                            openModalEvents(row.id);
                          }}
                            hover
                            style={{ cursor: 'pointer' }}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell >{row.team}</TableCell>
                            <TableCell >{row.shirt}</TableCell>
                            <TableCell >{row.grade}</TableCell>
                            <TableCell align="center">
                              <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" style={{ margin: 'auto' }}>
                                {row.position1 !== 0 && <Chip label={row.posicao} />}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={5} />
                          </TableRow>
                        )}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            colSpan={5}
                            count={state.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                              inputProps: {
                                'aria-label': 'Linhas por página',
                              },
                              native: true,
                            }}
                            labelRowsPerPage='Linhas por página'
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={(page) =>
                              `${page.from}-${page.to === -1 ? page.count : page.to} de ${page.count
                              }`
                            }
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>


            </Grid>
          </Grid>

        </Grid>

      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={() => { setOpenModal(false); }}
        closeAfterTransition
      >
        <Fade in={openModal}>
          <Box component="form"
            noValidate
            onSubmit={submitNewContact}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 1200,
              height: 800,
              bgcolor: colors.primary[400],
              boxShadow: 24,
              p: 4,
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{
                paddingBottom: '10px',
                borderStyle: 'solid',
                borderColor: colors.blueAccent[300],
                borderWidth: '0 0 2px 0'
              }}>
                <Typography component={'span'} variant="h3">Atleta temporário</Typography>
                <IconButton
                  aria-label="close"
                  onClick={() => { setOpenModal(false); }}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Divider flexItem variant="middle" />
              <Grid item xs={12}>
                <Grid container spacing={2} mt={2}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={2}>
                        <Typography variant="h3">Nome:</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="h4">{tempModal.name}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="h3">Camisola:</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="h4">{tempModal.shirt}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="h3">Clube:</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="h4">{tempModal.team_name}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="h3">Escalão: </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="h4">{tempModal.grade_name}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="h3">Scouter: </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="h4">{tempModal.scouter_id}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="h3">Jogo: </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="h4">{tempModal.date_opened}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={2}>
                  <Grid item xs={12}>

                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <StyledTab label="Observação" {...a11yProps(0)} />
                        <StyledTab label="Associar atleta" {...a11yProps(1)} />
                        <StyledTab label="Registar atleta" {...a11yProps(2)} />
                      </Tabs>
                    </Box>

                    <TabPanel value={value} index={0}>
                      <Grid container spacing={2} mt={2} sx={{ height: 400, overflow: 'auto' }}>

                        <Grid item xs={3} >
                          <Typography variant="h3" mb={2}>Gerais:</Typography>
                          <TableContainer sx={{ backgroundColor: colors.primary[600] }} >
                            <Table aria-label="custom pagination table">
                              <TableBody>
                                <TableRow>
                                  <TableCell >Lateralidade</TableCell>
                                  <TableCell >{tempModal.lateralidade}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell >Morfologia</TableCell>
                                  <TableCell >{tempModal.morfologia}</TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell >Rendimento Atual</TableCell>
                                  <TableCell >{tempModal.rendimento_atual}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell >Estatura</TableCell>
                                  <TableCell >{tempModal.estatura}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell >Maturacao</TableCell>
                                  <TableCell >{tempModal.maturacao}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell >Rendimento Futuro</TableCell>
                                  <TableCell >{tempModal.rendimento_futuro}</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <Typography variant="h3" mb={2} mt={2}>Outras:</Typography>
                          <TableContainer sx={{ backgroundColor: colors.primary[600] }} >
                            <Table aria-label="custom pagination table">
                              <TableBody>
                                <TableRow>
                                  <TableCell >Outras</TableCell>
                                  <TableCell >{tempModal.lateralidade}</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                        <Grid item xs={9}>
                          <Grid container spacing={2} mb={2}>
                            <Grid item xs={6} >
                              <Typography variant="h3" mb={2}>Técnicas:</Typography>
                              <TableContainer sx={{ backgroundColor: colors.primary[600] }} >
                                <Table aria-label="custom pagination table">
                                  <TableBody>
                                    <TableRow>
                                      <TableCell >Lateralidade</TableCell>
                                      <TableCell >{tempModal.lateralidade}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell >Morfologia</TableCell>
                                      <TableCell >{tempModal.morfologia}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell >Rendimento Atual</TableCell>
                                      <TableCell >{tempModal.rendimento_atual}</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                            <Grid item xs={6} >
                              <Typography variant="h3" mb={2}>Táticas:</Typography>
                              <TableContainer sx={{ backgroundColor: colors.primary[600] }} >
                                <Table aria-label="custom pagination table">
                                  <TableBody>
                                    <TableRow>
                                      <TableCell >Lateralidade</TableCell>
                                      <TableCell >{tempModal.lateralidade}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell >Morfologia</TableCell>
                                      <TableCell >{tempModal.morfologia}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell >Rendimento Atual</TableCell>
                                      <TableCell >{tempModal.rendimento_atual}</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                            <Grid item xs={6} >
                              <Typography variant="h3" mb={2}>Físicas:</Typography>
                              <TableContainer sx={{ backgroundColor: colors.primary[600] }} >
                                <Table aria-label="custom pagination table">
                                  <TableBody>
                                    <TableRow>
                                      <TableCell >Lateralidade</TableCell>
                                      <TableCell >{tempModal.lateralidade}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell >Morfologia</TableCell>
                                      <TableCell >{tempModal.morfologia}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell >Rendimento Atual</TableCell>
                                      <TableCell >{tempModal.rendimento_atual}</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                            <Grid item xs={6} >
                              <Typography variant="h3" mb={2}>Mentais:</Typography>
                              <TableContainer sx={{ backgroundColor: colors.primary[600] }} >
                                <Table aria-label="custom pagination table">
                                  <TableBody>
                                    <TableRow>
                                      <TableCell >Lateralidade</TableCell>
                                      <TableCell >{tempModal.lateralidade}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell >Morfologia</TableCell>
                                      <TableCell >{tempModal.morfologia}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell >Rendimento Atual</TableCell>
                                      <TableCell >{tempModal.rendimento_atual}</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} >
                          <TableContainer sx={{ backgroundColor: colors.primary[600] }} >
                            <Table aria-label="custom pagination table">
                              <TableBody>
                                <TableRow>
                                  <TableCell >Observação</TableCell>
                                  <TableCell >{tempModal.obs}</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                      <Grid container spacing={2} mt={2}>
                        <Grid item xs={3}>
                          <TextBox
                            fullWidth
                            name="name"
                            id="name"
                            label="Nome do atleta"
                            variant="outlined"
                            sx={{ backgroundColor: colors.primary[400] }}
                            onChange={event => {
                              setNameSearch(event.target.value);
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Autocomplete
                            disablePortal
                            name="TeamSearch" id="TeamSearch"
                            options={teamsSearch}
                            sx={{}}
                            renderInput={(params) => <TextBox {...params} label="Clube" />}
                            variant="outlined"
                            onChange={(event, value) => { setTeamSearch(value.value) }}
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <Autocomplete
                            disablePortal
                            name="GradeSearch" id="GradeSearch"
                            options={gradesSearch}
                            sx={{}}
                            renderInput={(params) => <TextBox {...params} label="Escalão" />}
                            variant="outlined"
                            onChange={(event, value) => { setGradeSearch(value.value) }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Button
                            sx={{
                              backgroundColor: colors.blueAccent[700],
                              color: colors.grey[100],
                              fontSize: "14px",
                              fontWeight: "bold",
                              padding: "10px 20px",
                            }}
                            onClick={() => { search_players() }}
                          >
                            <SearchIcon sx={{ mr: "10px" }} />
                            Pesquisar
                          </Button>
                        </Grid>
                        <Grid item xs={6} >
                          <TableContainer sx={{ backgroundColor: colors.primary[600] }}>
                            <Box sx={{ overflowY: 'auto', maxHeight: 400 }}>
                              <Table aria-label="custom pagination table" >
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="left">Nome</TableCell>
                                    <TableCell align="left">Clube</TableCell>
                                    <TableCell align="left">Idade</TableCell>
                                    <TableCell align="center">Posição</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {(athletesAssociates
                                  ).map((row) => (
                                    <TableRow key={row.id} onClick={() => {

                                      selectPlayerAssociate(row.id);
                                    }}
                                      hover
                                      style={{ cursor: 'pointer' }}>
                                      <TableCell>{row.complete_name}</TableCell>
                                      <TableCell >{row.team}</TableCell>
                                      <TableCell >{row.birthday}</TableCell>
                                      <TableCell align="center">
                                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" style={{ margin: 'auto' }}>
                                          {row.position1 !== 0 && (
                                            <Chip label={row.position1} />
                                          )}
                                          {row.position2 !== 0 && (
                                            <Chip label={row.position2} />
                                          )}
                                          {row.position3 !== 0 && (
                                            <Chip label={row.position3} />
                                          )}
                                        </Stack>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                  {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                      <TableCell colSpan={5} />
                                    </TableRow>
                                  )}
                                </TableBody>

                              </Table>
                            </Box>

                          </TableContainer>
                        </Grid>


                        {showdetail &&
                          <Grid item xs={6}>
                            <Grid container>
                              <Grid item mt={2} xs={6}>

                                <Grid item mt={2} xs={12}>
                                  <img alt={playerInfo.name} src={process.env.REACT_APP_API_BASE_URL + "api/photo/player/" + idSelected}
                                    style={{
                                      display: 'block',
                                      maxWidth: 190,
                                      maxHeight: 250,
                                      width: 'auto',
                                      height: 'auto',
                                      objectFit: 'cover',
                                    }}
                                    loading="lazy" />
                                </Grid>
                                <Grid item mt={2} xs={12}>
                                  <Typography variant="h4">{playerInfo.complete_name}</Typography>
                                  <Typography variant="h6">{playerInfo.shirt_name + " - " + playerInfo.shirt_number}</Typography>
                                </Grid>

                                {findCodeByLabel(playerInfo.naturality) &&
                                  <Grid item mt={2} xs={12}>
                                    <Box display="flex" alignItems="center">
                                      <img
                                        loading="lazy"
                                        width="20"
                                        src={`https://flagcdn.com/w20/${findCodeByLabel(playerInfo.naturality).toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${findCodeByLabel(playerInfo.naturality).toLowerCase()}.png 2x`}
                                        alt=""
                                      />
                                      <Box ml={1}>{"     " + playerInfo.naturality}</Box>
                                    </Box>
                                  </Grid>
                                }

                                <Grid item xs={12} mt={1}>
                                  <Box display="flex" alignItems="center">

                                    <CakeTwoToneIcon />
                                    <Typography variant="h5" ml={1}>
                                      {playerInfo.birthday + " (" + playerInfo.age + " anos)"}

                                    </Typography>
                                  </Box>

                                </Grid>

                                <Grid item xs={12} mt={1}>
                                  <Box display="flex" alignItems="center">
                                    <ScaleIcon />
                                    <Box ml={1}>{(playerInfo.weight) + "Kg - " + playerInfo.weight_last_update}</Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} mt={1}>
                                  <Box display="flex" alignItems="center">
                                    <HeightIcon />
                                    <Box ml={1}>{(playerInfo.height) + "cm - " + playerInfo.height_last_update}</Box>
                                  </Box>
                                </Grid>
                              </Grid>

                              <Grid item xs={6} mt={1}>
                                <FootballField playerNumbers={[playerInfo.position1, playerInfo.position2, playerInfo.position3]} footeballType={11} size={250} fieldColor={colors.grey[900]} linesColor={colors.grey[100]} />
                              </Grid>
                            </Grid>
                          </Grid>
                        }
                      </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                    </TabPanel>

                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              pt={4}
              spacing={2}
            >
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => setOpenModal(false)}>Cancelar</Button>
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="success" startIcon={<AddOutlinedIcon />} >Criar</Button>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal >

    </>
  );
};


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="Primeira página"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Anterior"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Próxima"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Última página"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const countries = [
  {
    code: 'AD', label: 'Andorra'
  },
  {
    code: 'AE',
    label: 'United Arab Emirates',
  },
  {
    code: 'AF', label: 'Afghanistan'
  },
  {
    code: 'AG',
    label: 'Antigua and Barbuda'
  },
  {
    code: 'AI', label: 'Anguilla'
  },
  {
    code: 'AL', label: 'Albania'
  },
  { code: 'AM', label: 'Armenia' },
  { code: 'AO', label: 'Angola' },
  { code: 'AQ', label: 'Antarctica' },
  { code: 'AR', label: 'Argentina' },
  { code: 'AS', label: 'American Samoa' },
  { code: 'AT', label: 'Austria' },
  {
    code: 'AU',
    label: 'Australia',
  },
  {
    code: 'AW', label: 'Aruba'
  },
  {
    code: 'AX', label: 'Alland Islands'
  },
  {
    code: 'AZ', label: 'Azerbaijan'
  },
  {
    code: 'BA',
    label: 'Bosnia and Herzegovina',
  },
  {
    code: 'BB', label: 'Barbados'
  },
  {
    code: 'BD', label: 'Bangladesh'
  },
  {
    code: 'BE', label: 'Belgium'
  },
  {
    code: 'BF', label: 'Burkina Faso'
  },
  {
    code: 'BG', label: 'Bulgaria'
  },
  {
    code: 'BH', label: 'Bahrain'
  },
  {
    code: 'BI', label: 'Burundi'
  },
  {
    code: 'BJ', label: 'Benin'
  },
  {
    code: 'BL', label: 'Saint Barthelemy'
  },
  {
    code: 'BM', label: 'Bermuda'
  },
  {
    code: 'BN', label: 'Brunei Darussalam'
  },
  {
    code: 'BO', label: 'Bolivia'
  },
  {
    code: 'BR', label: 'Brazil'
  },
  {
    code: 'BS', label: 'Bahamas'
  },
  {
    code: 'BT', label: 'Bhutan'
  },
  {
    code: 'BV', label: 'Bouvet Island'
  },
  {
    code: 'BW', label: 'Botswana'
  },
  {
    code: 'BY', label: 'Belarus'
  },
  {
    code: 'BZ', label: 'Belize'
  },
  {
    code: 'CA',
    label: 'Canada',
  },
  {
    code: 'CC',
    label: 'Cocos (Keeling) Islands',
  },
  {
    code: 'CD',
    label: 'Congo, Democratic Republic of the',
  },
  {
    code: 'CF',
    label: 'Central African Republic',
  },
  {
    code: 'CG',
    label: 'Congo, Republic of the',
  },
  {
    code: 'CH', label: 'Switzerland'
  },
  {
    code: 'CI', label: "Cote d'Ivoire"
  },
  {
    code: 'CK', label: 'Cook Islands'
  },
  {
    code: 'CL', label: 'Chile'
  },
  {
    code: 'CM', label: 'Cameroon'
  },
  {
    code: 'CN', label: 'China'
  },
  {
    code: 'CO', label: 'Colombia'
  },
  {
    code: 'CR', label: 'Costa Rica'
  },
  {
    code: 'CU', label: 'Cuba'
  },
  {
    code: 'CV', label: 'Cape Verde'
  },
  {
    code: 'CW', label: 'Curacao'
  },
  {
    code: 'CX', label: 'Christmas Island'
  },
  {
    code: 'CY', label: 'Cyprus'
  },
  {
    code: 'CZ', label: 'Czech Republic'
  },
  {
    code: 'DE',
    label: 'Germany',
  },
  {
    code: 'DJ', label: 'Djibouti'
  },
  {
    code: 'DK', label: 'Denmark'
  },
  {
    code: 'DM', label: 'Dominica'
  },
  {
    code: 'DO',
    label: 'Dominican Republic',
  },
  {
    code: 'DZ', label: 'Algeria'
  },
  {
    code: 'EC', label: 'Ecuador'
  },
  {
    code: 'EE', label: 'Estonia'
  },
  {
    code: 'EG', label: 'Egypt'
    , phone: '20'
  },
  {
    code: 'EH', label: 'Western Sahara'
  },
  {
    code: 'ER', label: 'Eritrea'
  },
  {
    code: 'ES', label: 'Spain'
  },
  {
    code: 'ET', label: 'Ethiopia'
  },
  {
    code: 'FI', label: 'Finland'
  },
  {
    code: 'FJ', label: 'Fiji'
  },
  {
    code: 'FK',
    label: 'Falkland Islands (Malvinas)',
  },
  {
    code: 'FM',
    label: 'Micronesia, Federated States of',
  },
  {
    code: 'FO', label: 'Faroe Islands'
  },
  {
    code: 'FR',
    label: 'France',
  },
  {
    code: 'GA', label: 'Gabon'
  },
  {
    code: 'GB', label: 'United Kingdom'
  },
  {
    code: 'GD', label: 'Grenada'
  },
  {
    code: 'GE', label: 'Georgia'
  },
  {
    code: 'GF', label: 'French Guiana'
  },
  {
    code: 'GG', label: 'Guernsey'
  },
  {
    code: 'GH', label: 'Ghana'
  },
  {
    code: 'GI', label: 'Gibraltar'
  },
  {
    code: 'GL', label: 'Greenland'
  },
  {
    code: 'GM', label: 'Gambia'
  },
  {
    code: 'GN', label: 'Guinea'
  },
  {
    code: 'GP', label: 'Guadeloupe'
  },
  {
    code: 'GQ', label: 'Equatorial Guinea'
  },
  {
    code: 'GR', label: 'Greece'
  },
  {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
  },
  {
    code: 'GT', label: 'Guatemala'
  },
  {
    code: 'GU', label: 'Guam'
  },
  {
    code: 'GW', label: 'Guinea-Bissau'
  },
  {
    code: 'GY', label: 'Guyana'
  },
  {
    code: 'HK', label: 'Hong Kong'
  },
  {
    code: 'HM',
    label: 'Heard Island and McDonald Islands',
  },
  {
    code: 'HN', label: 'Honduras'
  },
  {
    code: 'HR', label: 'Croatia'
  },
  {
    code: 'HT', label: 'Haiti'
  },
  {
    code: 'HU', label: 'Hungary'
  },
  {
    code: 'ID', label: 'Indonesia'
  },
  {
    code: 'IE', label: 'Ireland'
  },
  {
    code: 'IL', label: 'Israel'
  },
  {
    code: 'IM', label: 'Isle of Man'
  },
  {
    code: 'IN', label: 'India'
  },
  {
    code: 'IO',
    label: 'British Indian Ocean Territory',
  },
  {
    code: 'IQ', label: 'Iraq'
  },
  {
    code: 'IR',
    label: 'Iran, Islamic Republic of',
  },
  {
    code: 'IS', label: 'Iceland'
  },
  {
    code: 'IT', label: 'Italy'
  },
  {
    code: 'JE', label: 'Jersey'
  },
  {
    code: 'JM', label: 'Jamaica'
  },
  {
    code: 'JO', label: 'Jordan'
  },
  {
    code: 'JP',
    label: 'Japan',
  },
  {
    code: 'KE', label: 'Kenya'
  },
  {
    code: 'KG', label: 'Kyrgyzstan'
  },
  {
    code: 'KH', label: 'Cambodia'
  },
  {
    code: 'KI', label: 'Kiribati'
  },
  {
    code: 'KM', label: 'Comoros'
  },
  {
    code: 'KN',
    label: 'Saint Kitts and Nevis',
  },
  {
    code: 'KP',
    label: "Korea, Democratic People's Republic of",
  },
  {
    code: 'KR', label: 'Korea, Republic of'
  },
  {
    code: 'KW', label: 'Kuwait'
  },
  {
    code: 'KY', label: 'Cayman Islands'
  },
  {
    code: 'KZ', label: 'Kazakhstan'
  },
  {
    code: 'LA',
    label: "Lao People's Democratic Republic",
  },
  {
    code: 'LB', label: 'Lebanon'
  },
  {
    code: 'LC', label: 'Saint Lucia'
  },
  {
    code: 'LI', label: 'Liechtenstein'
  },
  {
    code: 'LK', label: 'Sri Lanka'
  },
  {
    code: 'LR', label: 'Liberia'
  },
  {
    code: 'LS', label: 'Lesotho'
  },
  {
    code: 'LT', label: 'Lithuania'
  },
  {
    code: 'LU', label: 'Luxembourg'
  },
  {
    code: 'LV', label: 'Latvia'
  },
  {
    code: 'LY', label: 'Libya'
  },
  {
    code: 'MA', label: 'Morocco'
  },
  {
    code: 'MC', label: 'Monaco'
  },
  {
    code: 'MD',
    label: 'Moldova, Republic of',
  },
  {
    code: 'ME', label: 'Montenegro'
  },
  {
    code: 'MF',
    label: 'Saint Martin (French part)',
  },
  {
    code: 'MG', label: 'Madagascar'
  },
  {
    code: 'MH', label: 'Marshall Islands'
  },
  {
    code: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
  },
  {
    code: 'ML', label: 'Mali'
  },
  {
    code: 'MM', label: 'Myanmar'
  },
  {
    code: 'MN', label: 'Mongolia'
  },
  {
    code: 'MO', label: 'Macao'
  },
  {
    code: 'MP',
    label: 'Northern Mariana Islands',
  },
  {
    code: 'MQ', label: 'Martinique'
  },
  {
    code: 'MR', label: 'Mauritania'
  },
  {
    code: 'MS', label: 'Montserrat'
  },
  {
    code: 'MT', label: 'Malta'
  },
  {
    code: 'MU', label: 'Mauritius'
  },
  {
    code: 'MV', label: 'Maldives'
  },
  {
    code: 'MW', label: 'Malawi'
  },
  {
    code: 'MX', label: 'Mexico'
  },
  {
    code: 'MY', label: 'Malaysia'
  },
  {
    code: 'MZ', label: 'Mozambique'
  },
  {
    code: 'NA', label: 'Namibia'
  },
  {
    code: 'NC', label: 'New Caledonia'
  },
  {
    code: 'NE', label: 'Niger'
  },
  {
    code: 'NF', label: 'Norfolk Island'
  },
  {
    code: 'NG', label: 'Nigeria'
  },
  {
    code: 'NI', label: 'Nicaragua'
  },
  {
    code: 'NL', label: 'Netherlands'
  },
  {
    code: 'NO', label: 'Norway'
  },
  {
    code: 'NP', label: 'Nepal'
  },
  {
    code: 'NR', label: 'Nauru'
  },
  {
    code: 'NU', label: 'Niue'
  },
  {
    code: 'NZ', label: 'New Zealand'
  },
  {
    code: 'OM', label: 'Oman'
  },
  {
    code: 'PA', label: 'Panama'
  },
  {
    code: 'PE', label: 'Peru'
  },
  {
    code: 'PF', label: 'French Polynesia'
  },
  {
    code: 'PG', label: 'Papua New Guinea'
  },
  {
    code: 'PH', label: 'Philippines'
  },
  {
    code: 'PK', label: 'Pakistan'
  },
  {
    code: 'PL', label: 'Poland'
  },
  {
    code: 'PM',
    label: 'Saint Pierre and Miquelon',
  },
  {
    code: 'PN', label: 'Pitcairn'
  },
  {
    code: 'PR', label: 'Puerto Rico'
  },
  {
    code: 'PS',
    label: 'Palestine, State of',
  },
  {
    code: 'PT', label: 'Portugal'
  },
  {
    code: 'PW', label: 'Palau'
  },
  {
    code: 'PY', label: 'Paraguay'
  },
  {
    code: 'QA', label: 'Qatar'
  },
  {
    code: 'RE', label: 'Reunion'
  },
  {
    code: 'RO', label: 'Romania'
  },
  {
    code: 'RS', label: 'Serbia'
  },
  {
    code: 'RU', label: 'Russian Federation'
  },
  {
    code: 'RW', label: 'Rwanda'
  },
  {
    code: 'SA', label: 'Saudi Arabia'
  },
  {
    code: 'SB', label: 'Solomon Islands'
  },
  {
    code: 'SC', label: 'Seychelles'
  },
  {
    code: 'SD', label: 'Sudan'
  },
  {
    code: 'SE', label: 'Sweden'
  },
  {
    code: 'SG', label: 'Singapore'
  },
  {
    code: 'SH', label: 'Saint Helena'
  },
  {
    code: 'SI', label: 'Slovenia'
  },
  {
    code: 'SJ',
    label: 'Svalbard and Jan Mayen',
  },
  {
    code: 'SK', label: 'Slovakia'
  },
  {
    code: 'SL', label: 'Sierra Leone'
  },
  {
    code: 'SM', label: 'San Marino'
  },
  {
    code: 'SN', label: 'Senegal'
  },
  {
    code: 'SO', label: 'Somalia'
  },
  {
    code: 'SR', label: 'Suriname'
  },
  {
    code: 'SS', label: 'South Sudan'
  },
  {
    code: 'ST',
    label: 'Sao Tome and Principe',
  },
  {
    code: 'SV', label: 'El Salvador'
  },
  {
    code: 'SX',
    label: 'Sint Maarten (Dutch part)',
  },
  {
    code: 'SY',
    label: 'Syrian Arab Republic',
  },
  {
    code: 'SZ', label: 'Swaziland'
  },
  {
    code: 'TC',
    label: 'Turks and Caicos Islands',
  },
  {
    code: 'TD', label: 'Chad'
  },
  {
    code: 'TF',
    label: 'French Southern Territories',
  },
  {
    code: 'TG', label: 'Togo'
  },
  {
    code: 'TH', label: 'Thailand'
  },
  {
    code: 'TJ', label: 'Tajikistan'
  },
  {
    code: 'TK', label: 'Tokelau'
  },
  {
    code: 'TL', label: 'Timor-Leste'
  },
  {
    code: 'TM', label: 'Turkmenistan'
  },
  {
    code: 'TN', label: 'Tunisia'
  },
  {
    code: 'TO', label: 'Tonga'
  },
  {
    code: 'TR', label: 'Turkey'
  },
  {
    code: 'TT',
    label: 'Trinidad and Tobago',
  },
  {
    code: 'TV', label: 'Tuvalu'
  },
  {
    code: 'TW',
    label: 'Taiwan, Republic of China',
  },
  {
    code: 'TZ',
    label: 'United Republic of Tanzania',
  },
  {
    code: 'UA', label: 'Ukraine'
  },
  {
    code: 'UG', label: 'Uganda'
  },
  {
    code: 'US',
    label: 'United States',
  },
  {
    code: 'UY', label: 'Uruguay'
  },
  {
    code: 'UZ', label: 'Uzbekistan'
  },
  {
    code: 'VA',
    label: 'Holy See (Vatican City State)',
  },
  {
    code: 'VC',
    label: 'Saint Vincent and the Grenadines',
  },
  {
    code: 'VE', label: 'Venezuela'
  },
  {
    code: 'VG',
    label: 'British Virgin Islands',
  },
  {
    code: 'VI',
    label: 'US Virgin Islands',
  },
  {
    code: 'VN', label: 'Vietnam'
  },
  {
    code: 'VU', label: 'Vanuatu'
  },
  {
    code: 'WF', label: 'Wallis and Futuna'
  },
  {
    code: 'WS', label: 'Samoa'
  },
  {
    code: 'XK', label: 'Kosovo'
  },
  {
    code: 'YE', label: 'Yemen'
  },
  {
    code: 'YT', label: 'Mayotte'
  },
  {
    code: 'ZA', label: 'South Africa'
  },
  {
    code: 'ZM', label: 'Zambia'
  },
  {
    code: 'ZW', label: 'Zimbabwe'
  },
];
export default PlayersTempList;