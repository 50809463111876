import {
  Box, useTheme, Grid, Typography, Avatar, Button,    DialogTitle, IconButton, Fade, Modal, Divider
} from "@mui/material";
import Header from "../../components/Header";
import React, { useContext, useState, useEffect, Fragment, useRef } from "react";
import { UserContext } from "../../context/UserContext";
import { tokens } from "../../theme"; 
import api from "../../components/confs";
import { styled } from "@mui/material/styles";
import Loader from "../../components/Loader";

import { DataGrid } from '@mui/x-data-grid';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextBox from '../../components/TextBox'
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Message from "../../components/Message";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function TabPanelModal(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, width: 600 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}



const Groups = (props) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loader, setLoader] = useState(false);

  const [groups, setGroups] = useState(null);
  const [users, setUsers] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [tabGroupValue, setTabGroupValue] = useState(0);   //Identifica a tab do grupo
  const [groupSelected, setGroupSelected] = useState(1);    //Identifica o grupo selecionado
  const [openNewGroup, setOpenNewGroup] = useState(false); // Abre o modal de novo grupo
  const new_group_name = useRef(null);

  const [forceReload, setForceReload] = useState(false); // É utilizado para dar render na página quando se cria novo grupo

  const [messageModal, setOpenMessage] = useState(false);
  const [messageModalName, setMessageName] = useState("");

  const [edit_group_name, setEditGroupName] = useState("");
  const [modalEditPermissions, setModalEditPermissions] = useState(false);

  //Used when permissions are updated  (GREEN BUTTON)
  const [permissionName, setPermissionName] = useState("");
  const [permissionHave, setPermissionHave] = useState(false);
  const [permissionId, setPermissionId] = useState(false);
  const [openSettingsModal, setopenSettingsModal] = useState(false);    // Identifica se modal das definições está aberto

  const [valueTabModalSettings, setvalueTabModalSettings] = React.useState(0);

  //Settings Modal
  const submitEditGroup = () => {
    api.put('/api/group/' + groupSelected, { "group_name": edit_group_name }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then((response) => {
        setMessageName("Nome de grupo alterado")
        setOpenMessage(true);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      })
      .finally(() => {
        //  setOpenNewGroup(false)
        // setForceReload(!forceReload)
      });
  }
  const deleteGroup = () => {
    setLoader(true);
    api.delete('/api/group/' + groupSelected, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then((response) => {
        setMessageName("Grupo eliminado")
        setOpenMessage(true);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      })
      .finally(() => {
        setopenSettingsModal(false);
        setOpenNewGroup(false)
        setTabGroupValue(0)
        setGroupSelected(1)
        setForceReload(!forceReload)
        setLoader(false);

      });
  }


  //new group modal
  const submitNewGroup = () => {
    const data = { group_name: new_group_name.current.value }
    api.post('/api/group', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then((response) => {
        setMessageName("Grupo criado")
        setOpenMessage(true);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      })
      .finally(() => {
        setOpenNewGroup(false)
        setForceReload(!forceReload)
      });
  }




  const StyledTab = styled((props) => <Tab {...props} />)({
    "&.Mui-selected": {
      color: colors.blueAccent[500],
      fontSize: 16
    }
  });


  useEffect(() => {
    setLoader(true);
    // EXEMPLO DE AXIOS
    api.get('/api/groups', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then((response) => {
        const new_data = [];

        response.data.forEach((group) => {

          new_data.push(
            <StyledTab label={group.group_name}
              onClick={() => { setGroupSelected(group.id); setEditGroupName(group.group_name); }}
              tabIndex={group.id} id={"simple-tab-" + group.id}
              aria-controls={"simple-tabpanel-" + group.id}
              key={group.id} />);
        });
        setGroups(new_data);
        get_group_data();

      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      })
      .finally(() => {
        setLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceReload]);


  useEffect(() => {
    setLoader(true);
    get_group_data()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupSelected]);

  const get_group_data = () => {
    setLoader(true);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    };
    api.get('/api/group/' + groupSelected, config)
      .then((response) => {
        setUsers(response.data.users)
        setPermissions(response.data.permissions)
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      })
      .finally(() => {
        setLoader(false);
      });
  }

  const columns_permissions = [
    { field: 'name', headerName: 'Permissão', width: 400 },
    {
      field: 'have', headerName: 'Tem?', width: 40, renderCell: (params) => {
        return (
          params.row.have ? <DoneIcon sx={{ color: "green" }} /> : <CloseIcon sx={{ color: "red" }} />
        );
      }
    },
    {
      field: 'button', headerName: '', width: 130, renderCell: (params) => {
        if (groupSelected !== 1) {
            return (
              <Box
                width="130%"
                m="0 auto"
                p="5px"
                display="flex"
                justifyContent="center"
                backgroundColor={colors.greenAccent[600]}
                borderRadius="4px"
                onClick={() => { openModalEditPermission(params.row) }}
              >
                <SettingsOutlinedIcon />
                <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                  Alterar
                </Typography>
              </Box>
            );
           
        } else {
          return (<></>);
        }

      }
    }

  ];

  const columns = [
    {
      field: 'id', headerName: 'Avatar', align: 'center', headerAlign: "center", width: 100, renderCell: (params) => {
        return (
          <Fragment key={params.row.id}>
            <Avatar src={process.env.REACT_APP_API_BASE_URL + "photo/" + params.row.id} />
            {params.value.photo}
          </Fragment>
        );
      }
    },
    { field: 'name', headerName: 'Nome', width: 300, renderCell: (params) => { return params.row.first_name + " " + params.row.last_name } },
    {
      field: 'button', headerName: '', width: 130, renderCell: (params) => {
        return (
          <Link to={"/user/" + params.row.id} style={{ textDecoration: "none", color: "inherit" }} >
            <Box
              width="130%"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              backgroundColor={colors.greenAccent[600]}
              borderRadius="4px"
            >
              <VisibilityOutlinedIcon />
              <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                Ver Perfil
              </Typography>
            </Box>
          </Link>
        );
      }
    }];




  const openModalEditPermission = (permission) => {
    setPermissionName(permission.name)
    setPermissionHave(!permission.have)
    setPermissionId(permission.id)

    //Open Modal
    setModalEditPermissions(true)
  }

  //Atualizar permissões
  const submitEditPermissions = () => {


    setModalEditPermissions(false)
    setLoader(true)

    const data = {
      permission: permissionId,
      group: groupSelected,
      have: permissionHave,
    }

    const config = {
      headers: {
        'Content-Type': 'application/json',
        //'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + token
      }
    };

    api.put('/api/permissions/', data, config)
      .then((response) => {
        if (response.status === 200) {
          setMessageName("Permissão alterada")
          setOpenMessage(true)
        }

      })
      .catch(error => console.error(error))
      .finally(() => {
        setForceReload(!forceReload)
        setLoader(false);
      });
  }

  const severity = "success";
  return (
    <Fragment key="groups">

      <Loader loader={loader} />
      <Message messageModal={messageModal} messageModalName={messageModalName} severity={severity} setOpenMessage={setOpenMessage} />

      <Box m="20px" sx={{
        position: "block",
        marginLeft: props.collapse === true ? 15 : 40
      }}>
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Grupos" subtitle="" />
          <Box>
                <Button
                  onClick={() => { setOpenNewGroup(true);  }}
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                  }}
                >
                  <AddIcon sx={{ mr: "10px" }} />
                  Novo grupo
                </Button>
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ bgcolor: colors.primary[400] }}>
              <Tabs
                value={tabGroupValue}
                onChange={(event, newValue) => { setTabGroupValue(newValue); }}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                TabIndicatorProps={{ style: { background: colors.blueAccent[500] } }}

              >
                {groups}
              </Tabs>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }} height={groupSelected === 1 ? 12 : 0}>
            {groupSelected !== 1 ?
                <IconButton aria-label="settings" size="large"
                  onClick={() => { setopenSettingsModal(true); }}
                >
                  <SettingsOutlinedIcon fontSize="inherit" />
                </IconButton>
                : <></>
            }
          </Grid>
          <Grid item xs={12} lg={5}  >
            <Typography variant="h4" paddingBottom={2}>Utilizadores</Typography>
            <Box sx={{ bgcolor: colors.primary[400] }}>
              <div m={2} style={{ height: 400, width: '100%' }}>
                <DataGrid
                  rows={users}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                />
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} lg={7}   >
            <Grid container >
              <Grid item xs={12}  >
                <Typography variant="h4" paddingBottom={2}>Permissões</Typography>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ bgcolor: colors.primary[400] }}>
                  <div m={2} style={{ height: 400, width: '100%' }}>
                    <DataGrid
                      rows={permissions}
                      columns={columns_permissions}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box >

      {/** New Group Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openNewGroup}
        onClose={() => setOpenNewGroup(false)}
        closeAfterTransition
      >
        <Fade in={openNewGroup}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 600,
              height: 600,
              bgcolor: colors.primary[400],
              boxShadow: 24,
              p: 4,
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{
                paddingBottom: '10px',
                borderStyle: 'solid',
                borderColor: colors.blueAccent[300],
                borderWidth: '0 0 2px 0'
              }}>
                <Typography component={'span'} variant="h3">Novo Grupo</Typography>
                <IconButton
                  aria-label="close"
                  onClick={() => setOpenNewGroup(false)}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Divider flexItem variant="middle" />
              <Grid container spacing={2} mt={1}>

              <Grid item xs={12}>
              <Typography gutterBottom>
            Ao criar novo grupo, todas as permissões serão negadas.
          </Typography>
          <Typography gutterBottom>
            Se pretender adicionar as permissões terão que ser editadas!
          </Typography>
          <TextBox
            name="new_group_name"
            fullWidth
            id="new_group_name"
            label="Nome"
            inputRef={new_group_name}
          />



              </Grid>

                <Grid item xs={12}>

                <Button autoFocus sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "12px",
            fontWeight: "bold",
          }}
            onClick={() => setOpenNewGroup(false)}>
            Cancelar
          </Button>
          <Button autoFocus sx={{
            backgroundColor: colors.greenAccent[700],
            color: colors.grey[100],
            fontSize: "12px",
            fontWeight: "bold",
          }}
            onClick={submitNewGroup}>
            Criar
          </Button>
          
                </Grid>
              </Grid>

            </Grid>

          </Box>
        </Fade>
      </Modal>




      {/** Change Permissions Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalEditPermissions}
        onClose={() => setModalEditPermissions(false)}
        closeAfterTransition
      >
        <Fade in={modalEditPermissions}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            height: 370,
            bgcolor: colors.grey[800],
            boxShadow: 24,
            p: 4,
          }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography id="modal-modal-title" variant="h3" component="h2">
                  Alterar Permissão
                </Typography>
                <IconButton
                  aria-label="close"
                  onClick={() => setModalEditPermissions(false)}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography gutterBottom >
                      {permissionName}
                    </Typography>
                  </Grid>
                  <Grid item ml={6} mt={3} xs={10}>
                    {!permissionHave ?
                      <Typography>Tem a certeza que pretende remover a permissão? </Typography>
                      :
                      <Typography>Tem a certeza que pretende adicionar a permissão? </Typography>

                    }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Button autoFocus sx={{
                          backgroundColor: colors.blueAccent[700],
                          color: colors.grey[100],
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                          onClick={() => setModalEditPermissions(false)}>
                          Não
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button autoFocus sx={{
                          backgroundColor: colors.greenAccent[700],
                          color: colors.grey[100],
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                          onClick={submitEditPermissions}>
                          Sim
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>

      {/** Settings Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSettingsModal}
        onClose={() => { setopenSettingsModal(false); setForceReload(!forceReload) }}
        closeAfterTransition
      >
        <Fade in={openSettingsModal}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 1023,
            height: 500,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{
                paddingBottom: '10px',
                borderStyle: 'solid',
                borderColor: colors.blueAccent[300],
                borderWidth: '0 0 2px 0'
              }}>
                <Typography component={'span'} variant="h3">Definições de grupo</Typography>
                <IconButton
                  aria-label="close"
                  onClick={() => { setopenSettingsModal(false); setForceReload(!forceReload) }}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Divider flexItem variant="middle" />
              <Grid item xs={12}>
                <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }} >
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={valueTabModalSettings}
                    onChange={(event, newValue) => { setvalueTabModalSettings(newValue); }}
                    aria-label="Vertical tabs example"
                    TabIndicatorProps={{ style: { background: colors.blueAccent[500] } }}
                    sx={{ borderRight: 1, borderColor: 'divider', width: "24%" }}
                  >
                    <StyledTab label="Informação do Grupo" {...a11yProps(0)} />
                    <StyledTab label="Eliminar Grupo" {...a11yProps(1)} />

                  </Tabs>
                  <TabPanelModal value={valueTabModalSettings} index={0}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextBox
                          name="name"
                          fullWidth
                          id="name"
                          label="Nome"
                          onChange={(e) => setEditGroupName(e.target.value)}
                          value={edit_group_name}
                        />

                      </Grid>
                      <Grid item xs={12} justifyContent="flex-end"
                        alignItems="flex-end">

                        <Button autoFocus sx={{
                          backgroundColor: colors.greenAccent[700],
                          color: colors.grey[100],
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                          onClick={submitEditGroup}>
                          Salvar
                        </Button>
                      </Grid>

                    </Grid>
                  </TabPanelModal>
                  <TabPanelModal value={valueTabModalSettings} index={1}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography>No caso de eliminar este grupo, todos os utilizadores ficarão sem grupo.</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Button autoFocus sx={{
                          backgroundColor: colors.redAccent[700],
                          color: colors.grey[100],
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                          startIcon={<DeleteOutlineOutlinedIcon />}
                          onClick={deleteGroup}>
                          Eliminar Grupo
                        </Button>
                      </Grid>
                    </Grid>
                  </TabPanelModal>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>





    </Fragment>

  );
};



function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


export default Groups;