import React, { useContext, useState, Fragment, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import { Box, Chip, Typography, useTheme, Grid, Tab, Tabs } from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import api from "../../components/confs";
import { styled } from "@mui/material/styles";

import Loader from "../../components/Loader";

const Notifications = (props) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [tabValue, setTabValue] = useState(0);
  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(false);
  const url = [
    '/api/notifications/viewed'
    ,
    '/api/notifications/not_viewed'
  ]

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setLoader(true);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    };
    api.get(url[tabValue], config)
      .then((response) => {
        setRows(response.data)
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const StyledTab = styled((props) => <Tab {...props} />)({
    "&.Mui-selected": {
      color: colors.blueAccent[500],
      fontSize: 16
    }
  });

  const columns = [
    {
      field: 'name', headerName: 'Notificação', width: 400, renderCell: (params) => {
        if (params.row.category === "new_user") {
          return (
            <Chip icon={< PersonAddOutlinedIcon />}
              label={"   " + params.row.name}
              sx={{ fontSize: 16, bgcolor: "transparent" }}>
            </Chip >
          );
        }
        return (
          <>
            < NotificationsNoneOutlinedIcon />
            <Typography>
              {params.row.name}
            </Typography>
          </>
        )
      }
    },
    { field: "date", headerName: "Data", width: 150 },
    {
      field: 'button', headerName: '', width: 130, renderCell: (params) => {
        return (
          <Link to={params.row.link} style={{ textDecoration: "none", color: "inherit" }} >
            <Box
              width="130%"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              backgroundColor={colors.greenAccent[600]}
              borderRadius="4px"
            >
              <VisibilityOutlinedIcon />
              <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                Ver
              </Typography>
            </Box>
          </Link>
        );
      }
    }];

  useEffect(() => {
    setLoader(true);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    };
    api.get('/api/notifications/not_viewed', config)
      .then((response) => {
        setRows(response.data)
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      })
      .finally(() => {
        setLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <Fragment key="groups">

      <Loader loader={loader} />
      <Box m="20px" sx={{
        position: "block",
        marginLeft: props.collapse === true ? 15 : 40
      }}>
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Notificações" subtitle="" />

        </Box>

        {/* GRID & CHARTS */}
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="490px"
          gap="20px"
        >
          {/* ROW 1 */}
          <Box
            gridColumn="span 12"
            backgroundColor={colors.primary[400]}
            display="flex"
          >
            <Grid container>
              <Grid item xs={12}>
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ bgcolor: colors.primary[400] }}>
                    <Tabs
                      value={tabValue}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                      TabIndicatorProps={{ style: { background: colors.blueAccent[500] } }}
                    >
                      <StyledTab label="Não visto"
                        tabIndex={0} id="simple-tab-0" aria-controls="simple-tabpanel-0"
                        key="0" />
                      <StyledTab label="Visto"
                        tabIndex={1} id="simple-tab-1"
                        aria-controls="simple-tabpanel-1"
                        key="1" />
                    </Tabs>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} p={2}>
                <Box >
                  <Box sx={{ bgcolor: colors.primary[400] }}>
                    <div m={2} style={{ height: 400, width: '100%' }}>
                      <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        sx={{ fontSize: 16 }}
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Fragment>

  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};





export default Notifications;