import React, { useContext, useState } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Box, Button, useTheme, Tab, Tabs
} from "@mui/material";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import { styled } from "@mui/material/styles";

import { tokens } from "../../theme";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Today from "./today";
import Future from "./future";
import Past from "./past";
import NewGameModal from "./new_game_modal";
import ShowGameModal from "./show_game_modal";

const Games = (props) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loader, setLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false)
  const [gameSelected, setGameSelected] = useState(null)
  const [openShowGameModal, setOpenShowGameModal] = useState(false)
  const [forceTodayGames, setForceTodayGames] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const StyledTab = styled((props) => <Tab {...props} />)({
    "&.Mui-selected": {
      color: colors.blueAccent[500],
      fontSize: 16
    }
  });
  return (
    <>
      <Loader loader={loader} />
      <ShowGameModal openModal={openShowGameModal}
      setOpenModal = {setOpenShowGameModal}
      setLoader = {setLoader}
      GameId = {gameSelected} />

      
      <Box m="20px" sx={{
        position: "block",
        marginLeft: props.collapse === true ? 15 : 40
      }}>
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Jogos" />
          <Box>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
              onClick={() => { setOpenModal(true); }}
            >
              <AddOutlinedIcon sx={{ mr: "10px" }} />
              Adicionar Jogos
            </Button>
          </Box>

        </Box>

        <Tabs value={selectedTab} onChange={handleTabChange}
          TabIndicatorProps={{ style: { background: colors.blueAccent[500] } }}>
          <StyledTab label="Hoje" />
          <StyledTab label="Futuros" />
          <StyledTab label="Passados" />

        </Tabs>
        {selectedTab === 0 && (
          <Today 
          setGameSelected = {setGameSelected}
          setOpenShowGameModal = {setOpenShowGameModal}
          setForceTodayGames={setForceTodayGames}
          forceTodayGames={forceTodayGames}
          />
        )}
        {selectedTab === 1 && (

          <Future 
          setGameSelected = {setGameSelected}
  setOpenShowGameModal = {setOpenShowGameModal}/>

        )}
        {selectedTab === 2 && (

          <Past setGameSelected = {setGameSelected}
          setOpenShowGameModal = {setOpenShowGameModal}/>

        )}
      </Box>

      <NewGameModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        setLoader={setLoader}
        name="Novo jogo"
        setForceTodayGames={setForceTodayGames}
        forceTodayGames={forceTodayGames}
      />
    </>
  );
};


export default Games;