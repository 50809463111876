import React  from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import Badge from '@mui/material/Badge';
import { PickersDay } from '@mui/x-date-pickers/PickersDay'; 
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import SportsOutlinedIcon from '@mui/icons-material/SportsOutlined';
 
import { pt } from "date-fns/locale";
 

const Calendar = ({value, setValue, setChangeView, importantDates}) => {
 
  function getUnansweredElements(scheduleList) {
    return scheduleList.filter(schedule => !schedule.answered);
  }
  function getApprovedElements(scheduleList) {
    return scheduleList.filter(schedule => (schedule.aproved && schedule.answered) );
  }
  
  function getDates(scheduleList) {
    const dates = scheduleList.map(schedule => schedule.date.split('T')[0]);
    return dates;
  }
 
   
  return (<div style={{marginLeft:'-15px', marginTop:'-15px'}}>
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pt}>
       
      <StaticDatePicker
        // mask='____/__/__'
        variant='static'
        orientation='landscape'
        value={value}
        showToolbar={false}
        onChange={(newValue) => setValue(newValue)}
        className="customDatePicker"
        onMonthChange={(a)=>{setChangeView(a);}}
        renderInput={(params) => {
          <TextField {...params} />;
        }}
        renderDay={(day, _value, DayComponentProps) => {
          const date = new Date(day);
          date.setHours(date.getHours() + 1);
          const isAproved= getDates(getApprovedElements(importantDates)).includes(date.toISOString().split('T')[0]);
          const isWarning =  getDates(getUnansweredElements(importantDates)).includes(date.toISOString().split('T')[0]);
         
          return (
            <Badge
              key={day.toString()}
              overlap='circular'
              badgeContent={isWarning ? <AnnouncementOutlinedIcon color='red' /> :
               (isAproved ? <SportsOutlinedIcon  color='red' /> : undefined)}
            >
              <PickersDay {...DayComponentProps} />
            </Badge>
          );
        }}
      />
    </LocalizationProvider>
  </div>
    
  );
};
 


export default Calendar;