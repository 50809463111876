import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Box, Button, useTheme, Grid, Typography,  
   IconButton, Modal, Fade, Divider
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Chip from '@mui/material/Chip';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { tokens } from "../../theme";
import api from "../../components/confs";
import TextBox from "../../components/TextBox";


const Classes = ({ setLoader }) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  const [classes, setClasses] = useState()

  const [classSelected, setClassSelected] = useState("")
  const [classSelectedId, setClassSelectedId] = useState(null);
  const [attributename, setattributename] = useState("")

  

  const [modal, setOpenModal] = useState(false)


  function closeModal() {
    setOpenModal(false);
    setClassSelected("");
  }
  function submit() {
    setLoader(true);
    api.post('/api/attribute', 
    {
      "class_": classSelectedId,
      "name": attributename
    },{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then((response) => {
        //setClasses(response.data)
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      }).finally(() => {
        getClasses()
        closeModal();
        setLoader(false);
      });
  }

  function openModal(name,id) {
    setClassSelected(name);
    setClassSelectedId(id);
    setOpenModal(true);
  }

  useEffect(() => {
    getClasses();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [])

  function getClasses() {
    setLoader(true)
    api.get('/api/class', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then((response) => {
        setClasses(response.data)
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      }).finally(() => {
        setLoader(false)
      });
  }

  /**-------------------Begin accordion  ---------------------- */


  const [expanded, setExpanded] = React.useState(false);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  /**-------------------End accordion ---------------------- */

  return (
    <>
      {(Array.isArray(classes)) && classes
        .map((class_, idx) => {
          return (
            <Accordion expanded={expanded === 'panel' + idx} onChange={handleChangeAccordion('panel' + idx)} key={'panel' + idx}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={"panel" + idx + "bh-content"}
                id={"panel" + idx + "bh-header"}
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  {class_.name}
                </Typography>
                {/*<Typography sx={{ color: 'text.secondary' }}>{classes_.value + " %"}</Typography>*/}
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={10} >
                    <Grid container spacing={2}>
                      {(Array.isArray(class_.attributes)) &&
                        class_.attributes
                          .map((attributes, idx2) => {
                            return (
                              <Grid item key={"gris-" + idx + idx2}>
                                <Chip key={"chip-" + idx + idx2} label={attributes} variant="outlined" />
                              </Grid>
                            )
                          })}
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    {class_.id !== 0 &&
                      <Box>
                        <Button
                          onClick={() => { openModal(class_.name, class_.id) }}
                          sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                          }}
                        >
                          <AddIcon sx={{ mr: "10px" }} />
                          Atributo
                        </Button>
                      </Box>
                    }
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )
        })}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modal}
        onClose={closeModal}
        closeAfterTransition
      >
        <Fade in={modal}>
          <Box
            noValidate
            //onSubmit={submitNewTeam}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              height: 300,
              bgcolor: colors.primary[400],
              boxShadow: 24,
              p: 4,
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{
                paddingBottom: '10px',
                borderStyle: 'solid',
                borderColor: colors.blueAccent[300],
                borderWidth: '0 0 2px 0'
              }}>
                <Typography component={'span'} variant="h3">Criação nova atributo</Typography>
                <IconButton
                  aria-label="close"
                  onClick={closeModal}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Divider flexItem variant="middle" />
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item >
                    <Typography >
                      O atributo vai ser associado à classe: {classSelected}
                    </Typography>
                  </Grid>
                  <Grid item >
                    <TextBox 
                    name="name" 
                    id="name" 
                    label="Nome do atributo" 
                    variant="outlined" 
                    onChange={(event)=>{setattributename(event.target.value)}}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                pt={4}
                spacing={2}>
                <Grid item>
                  <Button variant="contained" color="primary" onClick={()=>{closeModal()}}>Cancelar</Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="success" onClick={()=>{submit()}} startIcon={<AddOutlinedIcon />} >Criar</Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};







export default Classes;