import React, {   useState  } from "react";

//futebol 11
const playerPositions_fut11 = {
  1: { x: 34, y: 100 }, // G
  2: { x: 58, y: 75 }, // DD
  3: { x: 43, y: 75 }, // DCD
  4: { x: 25, y: 75 }, // DCE
  5: { x: 11, y: 75 }, // DE
  6: { x: 34, y: 60 }, // MCD
  7: { x: 58, y: 30 }, // AD
  8: { x: 43, y: 40 }, // MOD
  9: { x: 34, y: 20 }, // PL
  10: { x: 25, y: 40 }, // MOE
  11: { x: 11, y: 30 }, // AE
};
//futebol 9
const playerPositions_fut9 = {
  1: { x: 34, y: 100 }, // G
  3: { x: 43, y: 75 }, // DCD
  4: { x: 25, y: 75 }, // DCE
  6: { x: 34, y: 60 }, // MCD
  7: { x: 58, y: 30 }, // AD
  8: { x: 43, y: 40 }, // MOD
  9: { x: 34, y: 20 }, // PL
  10: { x: 25, y: 40 }, // MOE
  11: { x: 11, y: 30 }, // AE
};
//futebol 7
const playerPositions_fut7 = {
  1: { x: 34, y: 100 }, // G
  6: { x: 34, y: 60 }, // MCD
  7: { x: 58, y: 30 }, // AD
  8: { x: 43, y: 40 }, // MOD
  9: { x: 34, y: 20 }, // PL
  10: { x: 25, y: 40 }, // MOE
  11: { x: 11, y: 30 }, // AE
};

//futebol 5
const playerPositions_fut5 = {
  1: { x: 34, y: 100 }, // G
  6: { x: 34, y: 60 }, // MCD
  7: { x: 58, y: 30 }, // AD
  9: { x: 34, y: 20 }, // PL
  10: { x: 25, y: 40 }, // MOE
};


const FootballFieldToChoose = ({ playerNumbers, footeballType, size, fieldColor, linesColor ,fieldColorAux, changePosition}) => {

  const footballPositions = {
    11: playerPositions_fut11,
    9: playerPositions_fut9,
    7: playerPositions_fut7,
    5: playerPositions_fut5,
  };

  const playerPositions = footballPositions[footeballType];
  const numbers = [];

  for (let i = 1; i <= footeballType; i++) {
    numbers.push(i);
  }

  const width = size * 74 / 111;
  const height = size;
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const svgStyle = {
    cursor: isHovered ? 'pointer' : 'auto'
  };
  return (
    <svg width={width} height={height} viewBox="0 0 74 111">
      <rect id="" width="74" height="111" fill={fieldColor} />
      <g fill="none" stroke={linesColor} strokeWidth="0.5" transform="translate(3 3)">
        <path id="Border" d="M 0 0 h 68 v 105 h -68 Z" />
        <path id="Centre line" d="M 0 52.5 h 68" />
        <circle id="Centre circle" r="9.15" cx="34" cy="52.5" />
        <circle id="Centre mark" r="0.75" cx="34" cy="52.5" fill={linesColor} stroke="none" />
        <g id="Penalty area">
          <path id="Penalty area line" d="M 13.84 0 v 16.5 h 40.32 v -16.5" />
          <path id="Goal area line" d="M 24.84 0 v 5.5 h 18.32 v -5.5" />
          <circle id="Penalty mark" r="0.75" cx="34" cy="10.94" fill={linesColor} stroke="none" />
          <path id="Penalty arc" d="M 26.733027 16.5 a 9.15 9.15 0 0 0 14.533946 0" />
        </g>
        <use xlinkHref="#Penalty area" transform="rotate(180,34,52.5)" />
        <path id="Corner arcs" d="M 0 2 a 2 2 0 0 0 2 -2M 66 0 a 2 2 0 0 0 2 2M 68 103 a 2 2 0 0 0 -2 2M 2 105 a 2 2 0 0 0 -2 -2" />
        <g id="Players">
          {numbers.map((n) => {
            const player = playerPositions[n];
            if (playerNumbers.includes(n)){
              return (
                <g key={n} transform={`translate(${player.x} ${player.y})`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={()=>{changePosition(n)}}
                  style={svgStyle}>
                  <circle r="4" stroke={fieldColorAux[500]} strokeWidth="1" fill={fieldColorAux["800"]} fillOpacity="50%" />
                  <text x="0" y="0" textAnchor="middle" dy=".3em" fill="black" fontSize="4">
                    {n}
                  </text>
                </g>
              );
            }
              return (
                <g key={n} transform={`translate(${player.x} ${player.y})`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={()=>{changePosition(n)}}
                  style={svgStyle}>
                  <circle r="4" stroke={linesColor} strokeWidth="1" fill={fieldColor} fillOpacity="50%" />
                  <text x="0" y="0" textAnchor="middle" dy=".3em" fontSize="4">
                    {n}
                  </text>
                </g>
              );
          })
        }
           
        </g>

      </g>
    </svg>
  );
}

export default FootballFieldToChoose;
