import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Box, Button, useTheme, TableFooter, TablePagination, Grid, TableContainer, InputBase, IconButton, Typography, Divider, Fade, Modal, Table, TableHead, TableBody, TableRow, TableCell
} from "@mui/material";
import TextBox from '../../components/TextBox';
import StadiumOutlinedIcon from '@mui/icons-material/StadiumOutlined';
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { tokens } from "../../theme";
import api from "../../components/confs";

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from 'prop-types';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import CloseIcon from '@mui/icons-material/Close';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="Primeira página"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Anterior"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Próxima"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Última página"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};



const FootballFields = (id) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [stateFields, setStateFields] = useState([])
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [search_name, setSearchName] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const [name, setName] = useState();
  const [address, setAddress] = useState();
  const [lat, setLat] = useState();
  const [long, setLong] = useState();

  const [loader, setLoader] = useState(false);
  const severity = "success";
  const [messageModalName, setMessageName] = useState("");
  const [messageModal, setOpenMessage] = useState(false);

  function submitNewField() {
    setLoader(true)

    const data = {
      "name": name,
      "address": address,
      "lat": lat,
      "long": long,
      "team": id.id
    }
    api.post('/api/football_field', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {
        setMessageName("Campo criado!")
        setOpenMessage(true);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      })
      .finally(() => {
        get_fields_searching();
        setLoader(false);
        setOpenModal(false);

      });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  //--------------------------- ON START ---------------------------//


  useEffect(() => {
    get_fields_searching();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);





  //------------------- Search Fields ---------------------------//
  useEffect(() => {
    get_fields_searching();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [search_name]);


  const get_fields_searching = () => {
    setPage(0)
    const data = {
      id: id.id,
      search: search_name || ""
    };
    api.get("/api/football_field/search",
      {
        params: data,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then(response => {
        setStateFields(response.data)

      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }

  //-----------------------------------------------------------//

  return (
    <>

      <Loader loader={loader} />
      <Message messageModal={messageModal} messageModalName={messageModalName} severity={severity} setOpenMessage={setOpenMessage} />
      <Grid container>
        <Grid item xs={10} mt={2}>

          <Box
            display="flex"
            backgroundColor={colors.primary[400]}
            borderRadius="3px"
          >
            <InputBase sx={{ ml: 2, mt: 1, mb: 1, flex: 1 }} placeholder="Procurar Campo"
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
              value={search_name || ''}
              inputProps={{ style: { fontSize: 14 } }} />
            <IconButton type="button" sx={{ p: 1 }}>
              <SearchIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={2} mt={2}>

          <Box style={{ textAlign: 'right' }}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
              onClick={() => { setOpenModal(true) }}
            >
              <StadiumOutlinedIcon sx={{ mr: "10px" }} />
              Criar Campo
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} mt={2}>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Morada</TableCell>
                  <TableCell>Coordenadas</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? stateFields.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : stateFields
                ).map((row, i) => (
                  <TableRow key={"contact-" + i}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.address}</TableCell>
                    <TableCell>{row.lat + ", " + row.long}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    //rowsPerPageOptions={[5,   10, 25, { label: 'Tudo', value: -1 }]}
                    rowsPerPageOptions={[8]}
                    colSpan={4}
                    count={stateFields.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'Linhas por página',
                      },
                      native: true,
                    }}
                    labelRowsPerPage='Linhas por página'
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelDisplayedRows={(page) =>
                      `${page.from}-${page.to === -1 ? page.count : page.to} de ${page.count
                      }`
                    }
                  >

                  </TablePagination>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>


      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={() => { setOpenModal(false); }}
        closeAfterTransition
      >
        <Fade in={openModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 700,
              height: 480,
              bgcolor: colors.primary[400],
              boxShadow: 24,
              p: 4,
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{
                paddingBottom: '10px',
                borderStyle: 'solid',
                borderColor: colors.blueAccent[300],
                borderWidth: '0 0 2px 0'
              }}>
                <Typography component={'span'} variant="h3">Criação de novo Campo</Typography>
                <IconButton
                  aria-label="close"
                  onClick={() => { setOpenModal(false); }}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Divider flexItem variant="middle" />
              <Grid item xs={12}>
                <Grid container spacing={2} mt={2}>
                  <Grid item xs={12}>
                    <TextBox fullWidth name="name" id="name" label="Nome" variant="outlined" onChange={(e) => { setName(e.target.value) }} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextBox fullWidth name="address" id="address" label="Morada" variant="outlined" onChange={(e) => { setAddress(e.target.value) }} />
                  </Grid>

                  <Grid item xs={12}>
                    <TextBox fullWidth name="lat" id="lat" label="Latitude" variant="outlined" onChange={(e) => { setLat(e.target.value) }} />
                  </Grid>

                  <Grid item xs={12}>
                    <TextBox fullWidth name="long" id="long" label="Longitude" variant="outlined" onChange={(e) => { setLong(e.target.value) }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              pt={4}
              spacing={2}
            >
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => setOpenModal(false)}>Cancelar</Button>
              </Grid>
              <Grid item>
                <Button onClick={() => { submitNewField(); }} variant="contained" color="success" startIcon={<AddOutlinedIcon />} >Criar</Button>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>


  );
};

export default FootballFields;