import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import React, { useContext, useState } from "react";
import Dashboard from "./scenes/dashboard/index";
import Login from "./scenes/login";
import Signup from "./scenes/signup";
import Users from './scenes/users';
import Groups from './scenes/groups';
import Notifications from './scenes/Notifications';
import Associations from './scenes/Associations';
import Association from './scenes/Associations/Association';
import Teams from './scenes/Teams';
import Team from './scenes/Teams/Team';
import Players from './scenes/Players';
import Player from './scenes/Players/Player';

import UserProfile from './scenes/users/user';
import PlayersTempList from './scenes/Players/TemporaryList';

import Profile from './scenes/profile';
import Editprofile from './scenes/profile/edit_profile';
import Settings from './scenes/Settings';
import Games from './scenes/Games';
import Game from './scenes/Games/Game';
import Report from './scenes/report';
import ReportContet from './scenes/report/report';
import ScouterSchedule from './scenes/Schedules/ScouterSchedule';
import Schedules from './scenes/Schedules/index';
import ShadowTeamDescritive from './scenes/ShadowTeam/ShadowTeam';
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import { UserContext } from './context/UserContext';
import "./index.css";
import Expenses from './scenes/expenses';
import NewExpense from './scenes/expenses/NewExpense';
import Expense from './scenes/expenses/Expense';
import ExpenseManagement from './scenes/expenses/ExpenseManagement';
import ShadowTeam from './scenes/ShadowTeam';
import NewShadowTeam from './scenes/ShadowTeam/NewShadowTeam';


function LoginRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/signin" replace />} />
      <Route path="/signin" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="*" element={<Navigate to="/signin" replace />} />
    </Routes>
  );
}

function AppRoutes(props) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  let location = useLocation();

  const checkPermission = (id) => {
    return (
      props.user ? (props.user.is_admin ? true : (props.user.permissions.find(permission => permission.permissions === id)?.have)) : false
    )
  };

  return (
    <ColorModeContext.Provider value={props.colorMode}>
      <ThemeProvider theme={props.theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar
            isSidebar={props.isSidebar}
            name={props.user.first_name}
            group={props.user.group_name}
            admin={true}
            photo={process.env.REACT_APP_API_BASE_URL + "photo/" + props.user.id}
            sx={{ position: "fixed" }}
            setIsCollaps={setIsCollapsed} />

          <main className="content" >
            <Topbar setIsSidebar={props.setIsSidebar} collapse={isCollapsed} location={location} />
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" replace collapse={isCollapsed} />} />
              <Route path="/dashboard" element={<Dashboard collapse={isCollapsed} />} />

              {checkPermission(1) ? <Route path="/users" element={<Users collapse={isCollapsed} />} /> : ""}
              {checkPermission(1) ? <Route path="/profile" element={<Profile collapse={isCollapsed} />} /> : ""}
              {checkPermission(1) ? <Route path="/edit_profile" element={<Editprofile collapse={isCollapsed} />} /> : ""}
              {checkPermission(1) ? <Route path="/user/:id" element={<UserProfile collapse={isCollapsed} />} /> : ""}
              {checkPermission(1) ? <Route path="/groups" element={<Groups collapse={isCollapsed} />} /> : ""}
              <Route path="/notifications" element={<Notifications collapse={isCollapsed} />} />
              {(checkPermission(2) || checkPermission(3) || checkPermission(4)) ? <Route path="/associations" element={<Associations collapse={isCollapsed} />} /> : ""}
              {(checkPermission(2) || checkPermission(3) || checkPermission(4)) ? <Route path="/association/:id" element={<Association collapse={isCollapsed} />} /> : ""}
              {(checkPermission(7) || checkPermission(8) || checkPermission(9)) ? <Route path="/teams" element={<Teams collapse={isCollapsed} />} /> : ""}
              {checkPermission(9) ? <Route path="/team/:id" element={<Team collapse={isCollapsed} />} /> : ""}
              {(checkPermission(15) || checkPermission(16) || checkPermission(17)) && <Route path="/players" element={<Players collapse={isCollapsed} />} />}
              {(checkPermission(15) || checkPermission(16) || checkPermission(17)) && <Route path="/player/:id" element={<Player collapse={isCollapsed} />} />}
              <Route path="/players/temp" element={<PlayersTempList collapse={isCollapsed} />} />

              {checkPermission(1) && <Route path="/settings" element={<Settings collapse={isCollapsed} />} />}
              {(checkPermission(26) || checkPermission(27) ) && <Route path="/schedule" element={<Schedules collapse={isCollapsed} />} />}
              {(checkPermission(24) || checkPermission(25) ) && <Route path="/games" element={<Games collapse={isCollapsed} />} />}
              {(checkPermission(24) || checkPermission(25) ) && <Route path="/game/:id" element={<Game collapse={isCollapsed} />} />}

              {(checkPermission(31) || checkPermission(32) || checkPermission(33)) && <Route path="/report" element={<Report collapse={isCollapsed} />} />}
              {(checkPermission(31) || checkPermission(32) || checkPermission(33)) && <Route path="/report/:id" element={<ReportContet collapse={isCollapsed} />} />}


              {checkPermission(28) && <Route path="/scouter/schedule" element={<ScouterSchedule collapse={isCollapsed} />} />}
              {(checkPermission(37) || checkPermission(38) || checkPermission(39)) && <Route path="/expenses" element={<Expenses collapse={isCollapsed} />} />}
              {(checkPermission(37) || checkPermission(38) || checkPermission(39)) && <Route path="/expenses/new" element={<NewExpense collapse={isCollapsed} />} />}
              {(checkPermission(37) || checkPermission(38) || checkPermission(39)) && <Route path="/expense/:id" element={<Expense collapse={isCollapsed} />} />}
              {(checkPermission(39)) && <Route path="/expense/management" element={<ExpenseManagement collapse={isCollapsed} />} />}

              {(checkPermission(34) || checkPermission(35) || checkPermission(36)) && <Route path="/shadow-team" element={<ShadowTeam collapse={isCollapsed} />} />}
              {(checkPermission(34) || checkPermission(35) || checkPermission(36)) && <Route path="/shadow-team/new" element={<NewShadowTeam collapse={isCollapsed} />} />}
              {(checkPermission(34) || checkPermission(35) || checkPermission(36)) && <Route path="/shadow-team/:id" element={<ShadowTeamDescritive collapse={isCollapsed} />} />}


              <Route path="*" element={<Navigate to="/dashboard" replace collapse={isCollapsed} />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

function App() {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  if (!token || token === "null" || token == null) {
    return <LoginRoutes />
  } else {
    if (user) { //Isto evita a ida para a dashboard no refresh
      return <AppRoutes
        colorMode={colorMode}
        theme={theme}
        user={user}
        isSidebar={isSidebar}
        setIsSidebar={setIsSidebar}
      />
    }
  }
}

export default App;