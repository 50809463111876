import { useState, useContext, forwardRef } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextBox from '../../components/TextBox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import api from "../../components/confs";
import { UserContext } from "../../context/UserContext";
import MuiAlert from '@mui/material/Alert'; 

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const theme = createTheme(); 

export default function SignIn() {
  const [error, setError] = useState("");
  const [warning, setWarning] = useState("");
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);

  const [mail, setMail] = useState("");
  const [pass, setPass] = useState("");

  function handleSubmit() {

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    };
    api.post('/api/token', 
    {"username": mail,
    "password": pass}
    , requestOptions

    ).then(response => {
      setToken(response.data.access_token);

      if (!response.ok) {
        if (response.data.detail === "inactive_account") {
          setWarning(true);
          setError(false);
        }
        if (response.data.detail === "invalid_access") {
          setWarning(false);
          setError(true);
        }
      } else {
        setWarning(false);
        setError(false);
        setToken(response.data.access_token);
      }
    })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      })
      .finally(() => {
      });
  }


  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h3">
            APP SCOUTING
          </Typography>
          <Typography component="h1" variant="h5">
            Entrar
          </Typography>

          <Box noValidate sx={{ mt: 1 }}>
            <TextBox
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={mail}
              onChange={(event) => { setMail(event.target.value) }}
            />
            <TextBox
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={pass}
              onChange={(event) => { setPass(event.target.value) }}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Lembra-me"
            />
            {error ? <Alert severity="error">O e-mail ou a password estão incorretos!</Alert> : ""}
            {warning ? <Alert severity="warning">Ainda não foi aceite pelo Administrador!</Alert> : ""}

            <Button
              type="btn"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => { handleSubmit(); }}
            >
              Entrar
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Esqueceu-se da password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="\signup" variant="body2">
                  {"Não tem uma conta? Registe-se"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );

}