import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Box, useTheme, Grid, IconButton, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow,
  TableFooter, TablePagination
} from "@mui/material";
import { tokens } from "../../theme";

import api from "../../components/confs";
import PropTypes from 'prop-types';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

const Today = ({ setGameSelected,
  setOpenShowGameModal, setForceTodayGames, forceTodayGames }) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  const [games, setGames] = useState([])

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);


  useEffect(() => {
    getGames();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    getGames();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [forceTodayGames])


  function getGames() {
    api.get('/api/games/today',
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then((response) => {
        setGames(response.data)
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      })
      .finally(() => {
        //  setOpenNewGroup(false)
        // setForceReload(!forceReload)
      });
  }
  function callRoute(id) {
    setGameSelected(id);
    setOpenShowGameModal(true);
  }




  // to table 
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - games.length) : 0;

  // to table
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  // to table
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function dateFormat(datetimeStr) {
    const dt = new Date(datetimeStr);
    const dateStr = dt.toLocaleDateString();
    const options = { hour: 'numeric', minute: 'numeric' };
    const timeStr = dt.toLocaleTimeString([], options);
    return dateStr + ' ' + timeStr;
  }


  return (
    <>
      <Grid container
        backgroundColor={colors.primary[400]}
        alignItems="center"
        justifyContent="center"
        mt={4}
      >
        <Grid item xs={12} ml={4} mr={4} mt={4} mb={4}>
          <TableContainer
            sx={{ backgroundColor: colors.primary[400] }}
          >
            <Table aria-label="custom pagination table"
            >
              <TableHead>
                <TableRow>
                  <TableCell  >Equipa Visitada</TableCell>
                  <TableCell  >Equipa Visitante</TableCell>
                  <TableCell  >Tipo</TableCell>
                  <TableCell  >Escalão</TableCell>
                  <TableCell  >Data e hora</TableCell>
                  <TableCell  >Local</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(games > 0
                  ? games.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : games
                ).map((row) => (
                  <TableRow key={row.id} onClick={() => { callRoute(row.id) }}
                    hover
                    style={{ cursor: 'pointer' }}>
                    <TableCell>{row.team1}</TableCell>
                    <TableCell>{row.team1}</TableCell>
                    <TableCell >{row.type}</TableCell>
                    <TableCell>{row.grade}</TableCell>
                    <TableCell>{dateFormat(row.date)}</TableCell>
                    <TableCell>{row.local}</TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'Tudo', value: -1 }]}
                    colSpan={6}
                    count={games.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'Linhas por página',
                      },
                      native: true,
                    }}
                    labelRowsPerPage='Linhas por página'
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelDisplayedRows={(page) =>
                      `${page.from}-${page.to === -1 ? page.count : page.to} de ${page.count
                      }`
                    }
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};



function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="Primeira página"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Anterior"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Próxima"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Última página"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


export default Today;