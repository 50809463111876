import React, { useContext, useState } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Box,  useTheme, Grid , Tabs, Tab
} from "@mui/material";
import Header from "../../components/Header";
import { styled } from "@mui/material/styles"; 
import GamesSearch from './GamesSearch';
import { tokens } from "../../theme";
import GamesByDate from "./GamesByDate";
const Schedules = (props) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
   
  const day_name = (plus) => {
    const diasSemana = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];
    const hoje = new Date();
    hoje.setDate(hoje.getDate() + plus);
    const day = hoje.getDate();
    const month = hoje.getMonth() + 1;
    return diasSemana[hoje.getDay()] + " - " + day + "/" + month;
  }


  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const StyledTab = styled((props) => <Tab {...props} />)({
    "&.Mui-selected": {
      color: colors.blueAccent[500],
      fontSize: 16
    }
  });

  return (
    <> 
      
      <Box m="20px" sx={{
        position: "block",
        marginLeft: props.collapse === true ? 15 : 40
      }}>
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Planeamentos" subtitle="Pesquise um planeamento" />

        </Box>



        <Tabs value={selectedTab} onChange={handleTabChange}
          TabIndicatorProps={{ style: { background: colors.blueAccent[500] } }}>
          <StyledTab label={day_name(0)} />
          <StyledTab label={day_name(1)} />
          <StyledTab label={day_name(2)} />
          <StyledTab label={day_name(3)} />
          <StyledTab label={day_name(4)} />
          <StyledTab label={day_name(5)} />
          <StyledTab label={day_name(6)} />
          <StyledTab label="Pesquisar" />
        </Tabs>
        {(selectedTab >= 0 && selectedTab <= 6) && (
          <Grid mt={2}>
            <GamesByDate date={selectedTab} />
          </Grid>
        )}

        {selectedTab === 7 && (
          <GamesSearch />
        )}
        {/*<div>
          <Calendar />
        </div>*/}
      </Box>


    </>
  );
};


export default Schedules;