import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Box, Button, useTheme, Grid, IconButton, Table, TableBody, Tab, Tabs,
  TableCell, TableContainer, TableHead, TableRow, Typography, Autocomplete, Divider, Modal, Fade
} from "@mui/material";
import Header from "../../components/Header"; 
import { styled } from "@mui/material/styles";
import TextBox from '../../components/TextBox';
import Message from "../../components/Message";

import { tokens } from "../../theme";

import api from "../../components/confs";
import { useNavigate } from "react-router-dom";   
import PropTypes from 'prop-types'; 
 
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import CloseIcon from '@mui/icons-material/Close'; 
import FootballFieldToShadowTeam from '../../components/FootballFieldToShadowTeam'
   
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
const NewShadowTeam = (props) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  const [shadows, setShadows] = useState([]) 
  const [messageModal, setOpenMessage] = useState(false);
  const [messageModalName, setMessageName] = useState("");
 
  const [severity, setSeverity] = useState("success");
  const [grades, setGrades] = useState([]);
  const [playersToChoose, setPlayersToChoose] = useState([]);
  const [grade, setGrade] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const initial_list = [
    { position: 1, athlete1: null, athlete2: null },
    { position: 2, athlete1: null, athlete2: null },
    { position: 3, athlete1: null, athlete2: null },
    { position: 4, athlete1: null, athlete2: null },
    { position: 5, athlete1: null, athlete2: null },
    { position: 6, athlete1: null, athlete2: null },
    { position: 7, athlete1: null, athlete2: null },
    { position: 8, athlete1: null, athlete2: null },
    { position: 9, athlete1: null, athlete2: null },
    { position: 10, athlete1: null, athlete2: null },
    { position: 11, athlete1: null, athlete2: null },
  ];
  const [data, setData] = useState(initial_list);

  const [description, setDescriotion] = useState("");

  useEffect(() => {
    get_shadows();
    get_grades();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    setData(initial_list);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grade]);

  const navigate = useNavigate();

  const get_shadows = () => {
    api.get('/api/shadows', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then((response) => {
        setShadows(response.data)
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }

  const get_grades = () => {
    api.get('/api/grades', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {
        setGrades(response.data);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const StyledTab = styled((props) => <Tab {...props} />)({
    "&.Mui-selected": {
      color: colors.blueAccent[500],
      fontSize: 16
    }
  });


  const getAthletesByPosition = (position) => {
    const foundItem = data.find((item) => item.position === position);

    if (foundItem) {
      const { athlete1, athlete2 } = foundItem;
      return { athlete1, athlete2 };
    }

    return { athlete1: null, athlete2: null };
  };

  function choosePlayer(row) {
    closeModal1()
    const position = selectedTab + 1;
    if (type === 1) {
      setData((prevData) => {
        return prevData.map((item) => {
          if (item.position === position) {
            return { ...item, athlete1: row };
          }
          return item;
        });
      });
    } else {
      setData((prevData) => {
        return prevData.map((item) => {
          if (item.position === position) {
            return { ...item, athlete2: row };
          }
          return item;
        });
      });
    }
  }

  const [openModal1, setModal1] = useState(false);
  const [type, settype] = useState(null);
  function closeModal1() {
    setModal1(false)
  }

  function openModal(type) {
    settype(type);
    if (grade) {

      const position = selectedTab + 1;

      const data_variables = {
        'age': grade.age,
        'position': position
      };
      api.post('/api/player/shadow', data_variables,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        })
        .then((response) => {
          setPlayersToChoose(response.data)
          setModal1(true)
        })
        .catch(error => {
          console.error(error);
          if (error.response.data.detail === "unauthorized login") {
            setToken(null)
          }
        });

    } else {
      setMessageName("Tem que escolher o escalão!");
      setSeverity("warning")
      setOpenMessage(true);

    }
  }

  function save() {
    let positionsResult = [];
    let athlete1=null;
    let athlete2=null;
    data.forEach(function(atleta) {
      athlete1=null;
      athlete2=null;
      if (atleta.athlete1 !== null) {
        athlete1 = atleta.athlete1.id
      }
      if (atleta.athlete2 !== null) {
        athlete2 = atleta.athlete2.id
      }
      positionsResult.push({
        "position": atleta.position,
        "athlete1": athlete1,
        "athlete2": athlete2,
      })
    });
    const dataToCreate =
    {
      "info": {
        "descricao": description,
        "grade": grade.id
      },
      "positions": positionsResult
    }

    api.post('/api/shadow-team/new', dataToCreate,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then((response) => {
    })
    .catch(error => {
      console.error(error);
      if (error.response.data.detail === "unauthorized login") {
        setToken(null)
      }
    });
  }
  return (
    <> 
      <Message messageModal={messageModal} messageModalName={messageModalName} severity={severity} setOpenMessage={setOpenMessage} />


      <Box m="20px" sx={{
        position: "block",
        marginLeft: props.collapse === true ? 15 : 40
      }}>
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Equipas Sombra" />
          <Box>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
              onClick={() => { save(); navigate('/shadow-team/new') }}>
              <AddOutlinedIcon sx={{ mr: "10px" }} />
              Guardar
            </Button>
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container
              backgroundColor={colors.primary[400]}
            >
              <Grid item xs={5}  >
                <FootballFieldToShadowTeam
                  footeballType={11}
                  size={750}
                  fieldColor={colors.grey[900]}
                  fieldColorAux={colors.grey[100]}
                  linesColor={colors.grey[600]}
                  identityColor={colors.blueAccent[600]}
                  data={data}
                />

              </Grid>
              <Grid item xs={7}  >
                <Grid container mt={4} spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h3">
                      Informações
                    </Typography>
                  </Grid>
                  <Grid item xs={12} mt={2} pr={4}>
                    <TextBox label="Descrição" fullWidth
                      value={description}
                      onChange={event => { setDescriotion(event.target.value); }}
                    />
                  </Grid>
                  <Grid item xs={12} mt={2} pr={4}>

                    <Autocomplete
                      options={grades}
                      getOptionLabel={(option) => option.name || ""}
                      isOptionEqualToValue={(option, value) => value && option.id === value.id}
                      renderInput={(params) => (
                        <TextBox {...params} label="Escalão" variant="outlined" />
                      )}
                      onChange={(event, value) => { setGrade(value); }}
                      value={grade}
                    />

                  </Grid>
                  <Grid item xs={12} mt={2} pr={4}>

                    <Tabs value={selectedTab} onChange={handleTabChange}
                      TabIndicatorProps={{ style: { background: colors.blueAccent[500] } }}
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      <StyledTab label="1" />
                      <StyledTab label="2" />
                      <StyledTab label="3" />
                      <StyledTab label="4" />
                      <StyledTab label="5" />
                      <StyledTab label="6" />
                      <StyledTab label="7" />
                      <StyledTab label="8" />
                      <StyledTab label="9" />
                      <StyledTab label="10" />
                      <StyledTab label="11" />
                    </Tabs>
                  </Grid>
                  <Grid item xs={12} mt={2} pr={4}>
                    <TableContainer mt={2}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Opção</TableCell>
                            <TableCell>Nome</TableCell>
                            <TableCell>Pé preferido</TableCell>
                            <TableCell>Clube Atual</TableCell>
                            <TableCell>Valor</TableCell>
                            <TableCell></TableCell>


                          </TableRow>
                        </TableHead>
                        <TableBody>

                          <TableRow>
                            <TableCell>1º</TableCell>
                            <TableCell>{getAthletesByPosition(selectedTab + 1).athlete1 ? getAthletesByPosition(selectedTab + 1).athlete1.name : "Sem atleta"}</TableCell>
                            <TableCell>{getAthletesByPosition(selectedTab + 1).athlete1 ? getAthletesByPosition(selectedTab + 1).athlete1.foot : "Sem atleta"}</TableCell>
                            <TableCell>{getAthletesByPosition(selectedTab + 1).athlete1 ? getAthletesByPosition(selectedTab + 1).athlete1.team : "Sem atleta"}</TableCell>
                            <TableCell>{getAthletesByPosition(selectedTab + 1).athlete1 ? getAthletesByPosition(selectedTab + 1).athlete1.value : "Sem atleta"}</TableCell>
                            <TableCell>
                              <IconButton aria-label="Trocar" color={colors.blueAccent["400"]} onClick={() => { openModal(1) }}>
                                <SwapHorizontalCircleIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>2º</TableCell>
                            <TableCell>{getAthletesByPosition(selectedTab + 1).athlete2 ? getAthletesByPosition(selectedTab + 1).athlete2.name : "Sem atleta"}</TableCell>
                            <TableCell>{getAthletesByPosition(selectedTab + 1).athlete2 ? getAthletesByPosition(selectedTab + 1).athlete2.foot : "Sem atleta"}</TableCell>
                            <TableCell>{getAthletesByPosition(selectedTab + 1).athlete2 ? getAthletesByPosition(selectedTab + 1).athlete2.team : "Sem atleta"}</TableCell>
                            <TableCell>{getAthletesByPosition(selectedTab + 1).athlete2 ? getAthletesByPosition(selectedTab + 1).athlete2.value : "Sem atleta"}</TableCell>
                            <TableCell>
                              <IconButton aria-label="Trocar" color={colors.blueAccent["400"]} onClick={() => { openModal(2) }} >
                                <SwapHorizontalCircleIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Box>




      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal1}
        onClose={closeModal1}
        closeAfterTransition
      >
        <Fade in={openModal1}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 600,
              height: 600,
              bgcolor: colors.primary[400],
              boxShadow: 24,
              p: 4,
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{
                paddingBottom: '10px',
                borderStyle: 'solid',
                borderColor: colors.blueAccent[300],
                borderWidth: '0 0 2px 0'
              }}>
                <Typography component={'span'} variant="h3">Escolher Jogador</Typography>
                <IconButton
                  aria-label="close"
                  onClick={closeModal1}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Divider flexItem variant="middle" />
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>


                  <TableContainer sx={{ maxHeight: 400 }}>
                    <Table stickyHeader aria-label="custom table sticky">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Nome</TableCell>
                          <TableCell align="left">Clube</TableCell>
                          <TableCell align="left">Pé</TableCell>
                          <TableCell align="center">Valor</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>

                        {playersToChoose.map((row) => (
                          <TableRow
                            key={row.id}
                            onClick={() => { choosePlayer(row) }}
                            hover
                            style={{ cursor: 'pointer' }}
                          >
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.foot}</TableCell>
                            <TableCell>{row.team}</TableCell>
                            <TableCell>{row.value}</TableCell>
                          </TableRow>
                        ))}

                      </TableBody>
                    </Table>
                  </TableContainer>

                </Grid>
              </Grid>

            </Grid>

          </Box>
        </Fade>
      </Modal>

    </>
  );
};


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="Primeira página"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Anterior"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Próxima"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Última página"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};



export default NewShadowTeam;