import React  from "react";

//futebol 11
const playerPositions_fut11 = {
  1: { x: 34, y: 95 }, // G
  2: { x: 63, y: 72 }, // DD
  3: { x: 43, y: 75 }, // DCD
  4: { x: 25, y: 75 }, // DCE
  5: { x: 6, y: 72 }, // DE
  6: { x: 34, y: 60 }, // MCD
  7: { x: 58, y: 25 }, // AD
  8: { x: 45, y: 40 }, // MOD
  9: { x: 34, y: 10 }, // PL
  10: { x: 22, y: 40 }, // MOE
  11: { x: 11, y: 25 }, // AE
};
//futebol 9
const playerPositions_fut9 = {
  1: { x: 34, y: 100 }, // G
  3: { x: 43, y: 75 }, // DCD
  4: { x: 25, y: 75 }, // DCE
  6: { x: 34, y: 60 }, // MCD
  7: { x: 58, y: 30 }, // AD
  8: { x: 43, y: 40 }, // MOD
  9: { x: 34, y: 20 }, // PL
  10: { x: 25, y: 40 }, // MOE
  11: { x: 11, y: 30 }, // AE
};
//futebol 7
const playerPositions_fut7 = {
  1: { x: 34, y: 100 }, // G
  6: { x: 34, y: 60 }, // MCD
  7: { x: 58, y: 30 }, // AD
  8: { x: 43, y: 40 }, // MOD
  9: { x: 34, y: 20 }, // PL
  10: { x: 25, y: 40 }, // MOE
  11: { x: 11, y: 30 }, // AE
};

//futebol 5
const playerPositions_fut5 = {
  1: { x: 34, y: 100 }, // G
  6: { x: 34, y: 60 }, // MCD
  7: { x: 58, y: 30 }, // AD
  9: { x: 34, y: 20 }, // PL
  10: { x: 25, y: 40 }, // MOE
};


const FootballFieldToShadowTeam = ({ footeballType, size, fieldColor, linesColor, fieldColorAux, identityColor, data }) => {

  const footballPositions = {
    11: playerPositions_fut11,
    9: playerPositions_fut9,
    7: playerPositions_fut7,
    5: playerPositions_fut5,
  };

  const playerPositions = footballPositions[footeballType];

  const width = size * 74 / 111;
  const height = size;
 
  return (
    <svg width={width} height={height} viewBox="0 0 74 111">
      <rect id="" width="74" height="111" fill={fieldColor} />
      <g fill="none" stroke={linesColor} strokeWidth="0.5" transform="translate(3 3)">
        <path id="Border" d="M 0 0 h 68 v 105 h -68 Z" />
        <path id="Centre line" d="M 0 52.5 h 68" />
        <circle id="Centre circle" r="9.15" cx="34" cy="52.5" />
        <circle id="Centre mark" r="0.75" cx="34" cy="52.5" fill={linesColor} stroke="none" />
        <g id="Penalty area">
          <path id="Penalty area line" d="M 13.84 0 v 16.5 h 40.32 v -16.5" />
          <path id="Goal area line" d="M 24.84 0 v 5.5 h 18.32 v -5.5" />
          <circle id="Penalty mark" r="0.75" cx="34" cy="10.94" fill={linesColor} stroke="none" />
          <path id="Penalty arc" d="M 26.733027 16.5 a 9.15 9.15 0 0 0 14.533946 0" />
        </g>
        <use xlinkHref="#Penalty area" transform="rotate(180,34,52.5)" />
        <path id="Corner arcs" d="M 0 2 a 2 2 0 0 0 2 -2M 66 0 a 2 2 0 0 0 2 2M 68 103 a 2 2 0 0 0 -2 2M 2 105 a 2 2 0 0 0 -2 -2" />
        <g id="Players">
          {data.map((playerNumber) => {
            const player = playerPositions[playerNumber.position];
            if (player) {
              return (
                <g key={playerNumber.position} transform={`translate(${player.x} ${player.y})`}>

                  <circle r="3" stroke={fieldColorAux} strokeWidth=".5" fill={fieldColor} fillOpacity="50%" />
                  <text x="0" y="0" textAnchor="middle" dy=".3em" fill={fieldColorAux} strokeWidth="0" fontFamily="Arial" fontSize="3" fontWeight="300">
                    {playerNumber.position}
                  </text>

                  <text x="0" y="7" textAnchor="middle" fill={fieldColorAux} strokeWidth="0" fontFamily="Arial" fontSize="1.5"  >
                  {playerNumber.athlete1 ? playerNumber.athlete1.name : ""}
                  </text>

                  <text x="0" y="10" textAnchor="middle" fill={fieldColorAux} strokeWidth="0" fontFamily="Arial" fontSize="1.5"  >
                  {playerNumber.athlete2 ? playerNumber.athlete2.name : ""}
                  </text>

                </g>
              );
            }else { return null}
          })}
        </g>

      </g>
    </svg>
  );
}

export default FootballFieldToShadowTeam;
