import React, { useContext, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import { Box, useTheme, Grid } from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import FavoriteIcon from '@mui/icons-material/Favorite';

import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';

const Dashboard = (props) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  const cardStyle = {
    display: 'flex',
    alignItems: 'center', // Centralizar verticalmente
    borderRadius: '15px', // Cantos arredondados
    padding: '30px', // Espaçamento fora do círculo
    backgroundColor: colors.primary[400],
  };

  const circleStyle = {
    width: '70px',
    height: '70px',
    borderRadius: '50%',
    backgroundColor: colors.primary[600],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '20px', // Espaçamento entre o círculo e o título
  };

  const titleAndCountStyle = {
    display: 'flex',
    flexDirection: 'column',
  };
 

  // EXEMPLO DE AXIOS
  const get_users = () => {
    /*
        api.get('http://localhost:3000/api/users/all', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        })
        .then(response => setState( response.data ))
        .catch(error => console.error(error));*/
  }

  //Para executar apenas uma vez
  useEffect(() => {
    get_users();
  }, []);




  return (
    <Box m="20px" sx={{
      position: "block",
      marginLeft: props.collapse === true ? 15 : 40
    }}>
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" />


      </Box>
      {/* GRID & CHARTS */}
      <Grid container spacing={2}>

        <Grid item xs={6} sm={4} md={3}  >
          <Card sx={cardStyle}>
            <div style={circleStyle}>
              <FavoriteIcon fontSize="large" />
            </div>
            <div style={titleAndCountStyle}>
              <Typography variant="h5" component="div">
                Atleta favoritos 
              </Typography>
              <Typography variant="h4" component="div">
                10
              </Typography>
            </div>
          </Card>
        </Grid>

        <Grid item xs={6} sm={4} md={3}  >
          <Card sx={cardStyle}>
            <div style={circleStyle}>
              <SportsSoccerIcon fontSize="large" />
            </div>
            <div style={titleAndCountStyle}>
              <Typography variant="h5" component="div">
                Atletas Observados
              </Typography>
              <Typography variant="h4" component="div">
                10
              </Typography>
            </div>
          </Card>
        </Grid>

      </Grid>
    </Box>

  );
};


export default Dashboard;