import React from "react";
import {
    Box, Grid, Typography, Tab, Tabs
} from "@mui/material";

import Chip from '@mui/material/Chip';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import { Pie } from '@nivo/pie';
import { styled } from "@mui/material/styles";
import api from "../../components/confs";



export default function ActiveProfile({ token, setToken, setLoader, colors }) {

    const [activeProfile, setActiveProfile] = React.useState({});
    React.useEffect(() => {
        getActiveProfile();
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [])
    const [value, setValue] = React.useState(0);
    const [value1, setValue1] = React.useState(0);
    const [grades, setGrades] = React.useState([]);


    //Para mudar tab
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangePlayer = (event, newValue) => {
        setValue1(newValue);
    };

    //Estilo para a tab
    const StyledTab = styled((props) => <Tab {...props} />)({
        "&.Mui-selected": {
            color: colors.blueAccent[500],
            fontSize: 16
        }
    });
 


    function getActiveProfile() {
        setLoader(true)
        api.get('/api/evaluation_profiles/active', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                setActiveProfile(response.data)
                const nomesDeGrau = new Set();
                response.data.forEach(perfil => {
                    if (perfil.grade) {
                        nomesDeGrau.add(perfil.grade);
                    }
                });
                setGrades(Array.from(nomesDeGrau).map(nome => ({ "grade_name": nome })))
            })
            .catch(error => {
                console.error(error);
                if (error.response.data.detail === "unauthorized login") {
                    setToken(null)
                }
            }).finally(() => {
                setLoader(false)
            });
    }

    /**-------------------Begin accordion  ---------------------- */


    const [expanded, setExpanded] = React.useState(false);

    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    /**-------------------End accordion ---------------------- */


    function getProfileByGradeAndGoalkeeper(grade, goalkeeper) {
        // Encontrar o objeto na lista que corresponda à grade e goalkeeper fornecidos
        var foundProfile = activeProfile.find(function (player) {
            return player.grade === grade && player.goalkeeper === goalkeeper;
        });

        return foundProfile;
    }

    return (
        <>

            <Box
                sx={{ flexGrow: 1, bgcolor: colors.primary[400], display: 'flex',height: 520 }}
            >
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    TabIndicatorProps={{ style: { background: colors.blueAccent[500] } }}
                    sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                    {(Array.isArray(grades)) &&
                        grades
                            .map((row, idx) => {
                                return (
                                    <StyledTab label={row.grade_name} {...a11yProps(idx)} key={idx} />
                                );
                            })}
                </Tabs>
                {(Array.isArray(grades)) &&
                    grades
                        .map((row, idx) => {
                            return (
                                <TabPanel value={value} index={idx} key={idx}>

                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={value1} onChange={handleChangePlayer} aria-label="basic tabs example">
                                            <StyledTab label="Jogador" {...a11yProps(0)} />
                                            <StyledTab label="Guarda-Redes" {...a11yProps(1)} />
                                        </Tabs>
                                    </Box>
                                    <TabPanel value={value1} index={0}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="h5">{getProfileByGradeAndGoalkeeper(row.grade_name, true).grade + " - " + getProfileByGradeAndGoalkeeper(row.grade_name, true).profile}</Typography>
                                            </Grid>

                                            <Grid item xs={6}>

                                                <div>
                                                    {(Array.isArray(getProfileByGradeAndGoalkeeper(row.grade_name, true).data)) &&
                                                        getProfileByGradeAndGoalkeeper(row.grade_name, true).data
                                                            .map((classes_, idx1) => {
                                                                return (
                                                                    <Accordion expanded={expanded === 'panel' + idx + idx1} onChange={handleChangeAccordion('panel' + idx + idx1)} key={'panel' + idx + idx1}>
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls={"panel" + idx + idx1 + "bh-content"}
                                                                            id={"panel" + idx + idx1 + "bh-header"}
                                                                        >
                                                                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                                                                {classes_.name}
                                                                            </Typography>
                                                                            <Typography sx={{ color: 'text.secondary' }}>{classes_.value + " %"}</Typography>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails>

                                                                            <Grid container spacing={2}>
                                                                                {(Array.isArray(classes_.attributes)) &&
                                                                                    classes_.attributes
                                                                                        .map((attributes, idx2) => {
                                                                                            return (
                                                                                                <Grid item key={"gris-" + idx + idx1 + idx2}>
                                                                                                    <Chip key={"chip-" + idx + idx1 + idx2} label={attributes} variant="outlined" />
                                                                                                </Grid>
                                                                                            )
                                                                                        })}
                                                                            </Grid>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                );
                                                            })}

                                                </div>

                                            </Grid>

                                            <Grid item xs={6}>
                                                <Pie
                                                    data={getProfileByGradeAndGoalkeeper(row.grade_name, true).data.map(({ name: id, value }) => ({ id, value }))}
                                                    width={400}
                                                    height={400}
                                                    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                                    innerRadius={0.9}
                                                    padAngle={0.7}
                                                    cornerRadius={3}
                                                    colors={{ scheme: 'nivo' }}
                                                    borderWidth={1}
                                                    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                                    arcLinkLabelsTextColor={colors.primary[100]}
                                                    animate={true}
                                                    motionStiffness={90}
                                                    motionDamping={15}
                                                    tooltip={(tooltipProps) => {
                                                        const { datum } = tooltipProps;
                                                        return (
                                                            <div style={{ background: '#f1f1f1', color: '#333', border: '1px solid #ccc', borderRadius: '5px', padding: '5px' }}>
                                                                <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>{datum.label} {`${datum.value}%`}</div>
                                                            </div>
                                                        );
                                                    }}
                                                />
                                            </Grid>

                                        </Grid>

                                    </TabPanel>
                                    <TabPanel value={value1} index={1}>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="h5">{getProfileByGradeAndGoalkeeper(row.grade_name, false).grade + " - " + getProfileByGradeAndGoalkeeper(row.grade_name, false).profile}</Typography>
                                            </Grid>

                                            <Grid item xs={6}>

                                                <div>
                                                    {(Array.isArray(getProfileByGradeAndGoalkeeper(row.grade_name, false).data)) &&
                                                        getProfileByGradeAndGoalkeeper(row.grade_name, false).data
                                                            .map((classes_, idx1) => {
                                                                return (
                                                                    <Accordion expanded={expanded === 'panel' + idx + idx1} onChange={handleChangeAccordion('panel' + idx + idx1)} key={'panel' + idx + idx1}>
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls={"panel" + idx + idx1 + "bh-content"}
                                                                            id={"panel" + idx + idx1 + "bh-header"}
                                                                        >
                                                                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                                                                {classes_.name}
                                                                            </Typography>
                                                                            <Typography sx={{ color: 'text.secondary' }}>{classes_.value + " %"}</Typography>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails>

                                                                            <Grid container spacing={2}>
                                                                                {(Array.isArray(classes_.attributes)) &&
                                                                                    classes_.attributes
                                                                                        .map((attributes, idx2) => {
                                                                                            return (
                                                                                                <Grid item key={"gris-" + idx + idx1 + idx2}>
                                                                                                    <Chip key={"chip-" + idx + idx1 + idx2} label={attributes} variant="outlined" />
                                                                                                </Grid>
                                                                                            )
                                                                                        })}
                                                                            </Grid>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                );
                                                            })}

                                                </div>

                                            </Grid>

                                            <Grid item xs={6}>
                                                <Pie
                                                    data={getProfileByGradeAndGoalkeeper(row.grade_name, false).data.map(({ name: id, value }) => ({ id, value }))}
                                                    width={400}
                                                    height={400}
                                                    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                                    innerRadius={0.9}
                                                    padAngle={0.7}
                                                    cornerRadius={3}
                                                    colors={{ scheme: 'nivo' }}
                                                    borderWidth={1}
                                                    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                                    arcLinkLabelsTextColor={colors.primary[100]}
                                                    animate={true}
                                                    motionStiffness={90}
                                                    motionDamping={15}
                                                    tooltip={(tooltipProps) => {
                                                        const { datum } = tooltipProps;
                                                        return (
                                                            <div style={{ background: '#f1f1f1', color: '#333', border: '1px solid #ccc', borderRadius: '5px', padding: '5px' }}>
                                                                <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>{datum.label} {`${datum.value}%`}</div>
                                                            </div>
                                                        );
                                                    }}
                                                />
                                            </Grid>

                                        </Grid>

                                    </TabPanel>

                                </TabPanel>

                            );
                        })}
            </Box>
        </>
    )
}




function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{
                width: '100%',
            }}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}