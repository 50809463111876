import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Box, Button, useTheme, Grid,
  Avatar, InputBase, IconButton, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow,
  TableFooter, TablePagination, Typography, Divider, Modal, Fade
} from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";

import TextBox from '../../components/TextBox'
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import api from "../../components/confs";

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import CloseIcon from '@mui/icons-material/Close';


const Associations = (props) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  const [state, setState] = useState([])
  const [search_name, setSearchName] = useState('');
  const [openModal, setOpenModal] = useState(false)
  const [loader, setLoader] = useState(false);


  const [messageModal, setOpenMessage] = useState(false);
  const [messageModalName, setMessageName] = useState("");
  const [severityMessage, setSeverityMessage] = useState("");


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);


  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - state.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState()

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const navigate = useNavigate();
  const callRoute = (id) => {
    navigate('/association/' + id);
  }

  const submitNewAssociation = async (event) => {
    event.preventDefault();
    setLoader(true)

    const formData = new FormData(event.currentTarget);

    if (formData.get('photo').name.length === 0 || formData.get('name').length === 0 || formData.get('acronym').length === 0 || formData.get('address').length === 0 || formData.get('country').length === 0) {
      setSeverityMessage("warning")
      setMessageName("Todos os campos são obigatórios")
      setOpenMessage(true);
      setLoader(false)
      return;
    }

    const formData2 = new FormData();
    formData2.append('photo', formData.get('photo'));

    const params = new URLSearchParams();
    params.append('name', formData.get('name'));
    params.append('acronym', formData.get('acronym'));
    params.append('address', formData.get('address'));
    params.append('country', formData.get('country'));


    api.post('api/association', formData2, {
      params: params,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {
        setOpenModal(false);
        get_associations();
        setSeverityMessage("success")
        setMessageName("Associação criada")
        setOpenMessage(true);

      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      }).finally(() => {

        setLoader(false)
      });
  }



  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0])
  }



  const get_associations = () => {
    api.get('api/associations', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {
        setState(response.data);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }

  const get_associations__searching = (name_searching) => {
    api.get('api/association/search',
      {
        params: { name: name_searching },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then(response => {
        setState(response.data);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }
  const checkPermission = (id) => {
    return (
      user ? (user.is_admin ? true : (user.permissions.find(permission => permission.permissions === id)?.have)) : false
    )
  };
  useEffect(() => {
    get_associations();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  return (
    <>

      <Loader loader={loader} />

      <Message messageModal={messageModal} messageModalName={messageModalName} severity={severityMessage} setOpenMessage={setOpenMessage} />

      <Box m="20px" sx={{
        position: "block",
        marginLeft: props.collapse === true ? 15 : 40
      }}>
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Associações" subtitle="Pesquise uma associação" />
          {checkPermission(2) &&
            <Box>
              <Button
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                }}
                onClick={()=>{setOpenModal(true)}}
              >
                <AddOutlinedIcon sx={{ mr: "10px" }} />
                Criar Associação
              </Button>
            </Box>
          }
        </Box>

        {checkPermission(4) &&
          <>
            <Box
              display="flex"
              backgroundColor={colors.primary[400]}
              borderRadius="3px"
            >
              <InputBase sx={{ ml: 2, mt: 1, mb: 1, flex: 1 }} placeholder="Procurar Associação"
                onChange={(e) => { setSearchName(e.target.value); get_associations__searching(e.target.value); }}
                value={search_name}
                inputProps={{ style: { fontSize: 20 } }} />
              <IconButton type="button" sx={{ p: 1 }}>
                <SearchIcon />
              </IconButton>
            </Box>
            <Grid container
              backgroundColor={colors.primary[400]}
              alignItems="center"
              justifyContent="center"
              mt={4}
            >
              <Grid item xs={12} >

                <div style={{ width: '94%', marginLeft: 28, marginRight: 28, marginTop: 20, marginBottom: 20 }}>

                  <TableContainer
                    sx={{ backgroundColor: colors.primary[400] }}

                  >
                    <Table aria-label="custom pagination table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Fotografia</TableCell>
                          <TableCell>Nome</TableCell>
                          <TableCell>Acrônimo</TableCell>
                          <TableCell align="center">Nº Equipas</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(rowsPerPage > 0
                          ? state.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          : state
                        ).map((row) => (
                          <TableRow key={row.id} onClick={() => { callRoute(row.id) }}
                            hover
                            style={{ cursor: 'pointer' }}>
                            <TableCell sx={{ textAlign: 'center' }}>
                              <Avatar alt={row.name} src={process.env.REACT_APP_API_BASE_URL + "association/photo/" + row.id} style={{ margin: '0 auto', display: 'block' }} />
                            </TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell >{row.acronym}</TableCell>
                            <TableCell align="center">{row.qtt}</TableCell>
                          </TableRow>
                        ))}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'Tudo', value: -1 }]}
                            colSpan={4}
                            count={state.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                              inputProps: {
                                'aria-label': 'Linhas por página',
                              },
                              native: true,
                            }}
                            labelRowsPerPage='Linhas por página'
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={(page) =>
                              `${page.from}-${page.to === -1 ? page.count : page.to} de ${page.count
                              }`
                            }
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>


                </div>

              </Grid>
            </Grid>
          </>
        }

      </Box>
      {/** Settings Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={() => { setOpenModal(false); }}
        closeAfterTransition
      >
        <Fade in={openModal}>
          <Box component="form"
            noValidate
            onSubmit={submitNewAssociation}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 1023,
              height: 410,
              bgcolor: colors.primary[400],
              boxShadow: 24,
              p: 4,
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{
                paddingBottom: '10px',
                borderStyle: 'solid',
                borderColor: colors.blueAccent[300],
                borderWidth: '0 0 2px 0'
              }}>
                <Typography component={'span'} variant="h3">Criação de nova associação</Typography>
                <IconButton
                  aria-label="close"
                  onClick={() => { setOpenModal(false); }}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Divider flexItem variant="middle" />
              <Grid item xs={12}>
                <Grid container spacing={2} mt={2}>
                  <Grid item xs={3} style={{ display: "flex", justifyContent: 'center' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} style={{ display: "flex", justifyContent: 'center' }}>
                        <Avatar alt="Remy Sharp" src={preview} sx={{ width: 100, height: 100, fontSize: 15 }} />
                      </Grid>
                      <Grid item xs={12} style={{ display: "flex", justifyContent: 'center' }}>
                        <input
                          required
                          id="photo"
                          label="Fotografia"
                          name="photo"
                          type="file"
                          onChange={onSelectFile}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={9} >
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <TextBox fullWidth name="name" id="name" label="Nome da Associação" variant="outlined" required />
                      </Grid>
                      <Grid item xs={4}>
                        <TextBox fullWidth name="acronym" id="acronym" label="Acrônimo" variant="outlined" required />
                      </Grid>
                      <Grid item xs={12}>
                        <TextBox fullWidth name="address" id="address" label="Morada" variant="outlined" required />
                      </Grid>
                      <Grid item xs={12}>
                        <TextBox fullWidth name="country" id="country" label="País" variant="outlined" required />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              pt={4}
              spacing={2}
            >
              <Grid item>

                <Button variant="contained" color="primary" onClick={() => { setOpenModal(false); }}>Cancelar</Button>
              </Grid>
              <Grid item>

                <Button type="submit" variant="contained" color="success" startIcon={<AddOutlinedIcon />} >Criar</Button>

              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>

    </>
  );
};


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="Primeira página"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Anterior"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Próxima"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Última página"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


export default Associations;