import React, { createContext, useEffect, useState } from "react";
import Cookies from 'js-cookie';

export const UserContext = createContext();

export const UserProvider = (props) => {
  // const [token, setToken] = useState(localStorage.getItem("awesomeLeadsToken"));
  const [token, setToken] = useState(Cookies.get('auth_user'));

  const [user, setUser] = useState("");

  useEffect(() => {
    const fetchUser = async () => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };

      const response = await fetch(process.env.REACT_APP_API_BASE_URL+"api/users/me", requestOptions);
      //const response2 = await fetch("/photo/", requestOptions);



      if (!response.ok) {
        setToken(null);
        //localStorage.setItem("awesomeLeadsToken", null);
        Cookies.set('auth_user', null);
      }else{
        setUser(await response.json())
        //localStorage.setItem("awesomeLeadsToken", token);
        Cookies.set('auth_user', token);
      }

    };
    fetchUser();
  }, [token]);

  return (
    <UserContext.Provider value={{token, setToken, user}}>
      {props.children}
    </UserContext.Provider>
  );
};