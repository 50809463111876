import {
  Box, Button, useTheme, Typography, Grid, IconButton, Avatar, InputLabel, MenuItem, FormControl, Select, Autocomplete,
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableFooter, TablePagination
} from "@mui/material";
import Header from "../../components/Header";
import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import { tokens } from "../../theme";
import { DataGrid } from "@mui/x-data-grid";
import api from "../../components/confs";
import packageJson from '../../../package.json'
import { Link } from 'react-router-dom';
import Loader from "../../components/Loader";
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import SearchIcon from '@mui/icons-material/Search';
import TextBox from "../../components/TextBox";
import { useNavigate } from "react-router-dom";

const Users = (props) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [state, setState] = useState([])
  const [groups, setGroups] = useState([])

  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);
  const [search_name, setSearchName] = useState('');
  const [search_email, setSearchEmail] = useState('');
  const [search_phone, setSearchPhone] = useState('');
  const [search_local, setSearchLocality] = useState('');
  const [search_activity, setSearchActivity] = useState('both');
  const [search_group, setSearchGroup] = useState('');
  // to table 
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - state.length) : 0;

  // to table
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  // to table
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [open, setOpen] = useState(false);

  //Para executar apenas uma vez
  useEffect(() => {
    setOpen(true);
    // EXEMPLO DE AXIOS
    api.get('/api/users', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then((response) => {
        response.data.map(user => (
           user.photo = user.photo//process.env.REACT_APP_API_BASE_URL + user.photo
        ));
        console.log(response.data)
        setState(response.data);

        api.get('/api/groups', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        })
          .then((response2) => {
            const all_groups = []
            response2.data.map(g => (
              all_groups.push({ label: g.group_name, id: g.id })
            ))
            setGroups(all_groups)
          })
          .catch(error => {
            console.error(error);
            if (error.response.data.detail === "unauthorized login") {
              setToken(null)
            }
          });

      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      })
      .finally(() => {
        setOpen(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const new_search = () => {
    setOpen(true);

    const config = {
      params: {
        names: search_name,
        email: search_email,
        phone: search_phone,
        locality: search_local,
        activity: search_activity,
        group: search_group.id || "0"
      },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    };
    api.get('/api/search_users', config)
      .then((response) => {
        response.data.map(user => (user.photo = packageJson.proxy + user.photo));
        setState(response.data);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      })
      .finally(() => {
        setOpen(false);
      });


  }
  const clean_search = () => {
    setSearchName('');
    setSearchEmail('');
    setSearchPhone('');
    setSearchLocality('');
    setSearchActivity('both');
    setSearchGroup('');
  }
  // To navigate to team page
  const navigate = useNavigate();
  const callRoute = (id) => {
    navigate('/user/' + id);
  }

  const columns = [
    {
      field: 'photo', headerName: 'Fotografia', align: 'center', headerAlign: "center", width: 130, renderCell: (params) => {
        return (
          <>
            <Avatar src={params.row.photo} />
            {params.value.photo}
          </>
        );
      }
    },
    { field: 'fullName', cellClassName: "name-column--cell", headerName: 'Nome', align: 'center', headerAlign: "center", sortable: false, width: 160, valueGetter: (params) => `${params.row.first_name || ''} ${params.row.last_name || ''}`, },
    { field: 'email', headerName: 'E-mail', width: 200 },
    { field: 'phone', headerName: 'Telemóvel', type: 'number', width: 120 },
    { field: 'locality', headerName: 'Localidade/Cidade', width: 200 },
    { field: 'birthday', headerName: 'Nascimento', width: 100 },
    { field: 'coordenatorname', headerName: 'Coordenador', width: 100 },
    {
      field: 'is_active', headerName: 'Atividade', align: 'center', headerAlign: "center", width: 200, renderCell: (params) => {
        if (params.row.is_active === true) {
          return (
            <IconButton aria-label="delete" disableRipple disabled color="success">
              <RadioButtonCheckedOutlinedIcon color="success" />
              <Typography variant="h6" color={colors.grey[100]}>
                Conta ativa
              </Typography>
            </IconButton>
          );
        } else {
          return (
            <IconButton aria-label="delete" disableRipple disabled color="success">
              <RadioButtonCheckedOutlinedIcon color="error" />
              <Typography variant="h6" color={colors.grey[100]}>
                Conta intativa
              </Typography>
            </IconButton>
          );
        }
      }
    },
    {
      field: "", headerName: "", align: 'center', headerAlign: "center", width: 230, renderCell: (params) => {
        return (
          <Link to={"/user/" + params.row.id} style={{ textDecoration: "none", color: "inherit" }} >
            <Box
              width="130%"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              backgroundColor={colors.greenAccent[600]}
              borderRadius="4px"
            >
              <VisibilityOutlinedIcon />
              <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                Ver Perfil
              </Typography>
            </Box>
          </Link>
        );
      }
    },
  ];


  return (
    <>

      <Loader loader={open} />
      <Box m="20px" sx={{
        position: "block",
        marginLeft: props.collapse === true ? 15 : 40
      }}>
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Utilizadores" subtitle="" />
        </Box>
        <Grid container
          backgroundColor={colors.primary[400]}
          alignItems="center"
          justifyContent="center"
          pb={3}
        >
          <Grid item xs={12} marginLeft={3} marginRight={3} marginTop={2}>
            <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} mt={-2}>
                  <Typography variant="h5">Filtros</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={5} lg={3}>
                  <TextBox
                    name="name"
                    fullWidth
                    id="name"
                    label="Nome"
                    onChange={(e) => setSearchName(e.target.value)}
                    value={search_name}

                  />
                </Grid>
                <Grid item xs={12} sm={6} md={5} lg={3}>
                  <TextBox
                    name="email"
                    fullWidth
                    id="email"
                    label="E-mail"
                    onChange={(e) => setSearchEmail(e.target.value)}
                    value={search_email}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={5} lg={3}>
                  <TextBox
                    name="phone"
                    fullWidth
                    id="phone"
                    label="Telemóvel"
                    onChange={(e) => setSearchPhone(e.target.value)}
                    value={search_phone}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={5} lg={3}>
                  <TextBox
                    name="locality"
                    fullWidth
                    id="locality"
                    label="Localidade / Cidade"
                    onChange={(e) => setSearchLocality(e.target.value)}
                    value={search_local}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={5} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Atividade</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={search_activity}
                      label="Atividade"
                      onChange={(e) => setSearchActivity(e.target.value)}
                      sx={{
                        '& label.Mui-focused': {
                          color: 'white',
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: 'white',
                        },
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: 'white',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'white',
                          },
                        },
                      }}
                    >
                      <MenuItem value="active">Ativo</MenuItem>
                      <MenuItem value="inactive">Inativo</MenuItem>
                      <MenuItem value="both">Ambos</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={5} lg={3}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={groups}
                    value={search_group}
                    renderInput={(params) => <TextBox {...params} label="Grupo" />}
                    onChange={(event, newValue) => { setSearchGroup(newValue); }}
                    isOptionEqualToValue={(option, value) => option.value === value.value}

                  />
                </Grid>

                <Grid item xs={12} sm={9} md={10} lg={11} style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button onClick={clean_search}
                    sx={{
                      color: colors.grey[100],
                      bgcolor: colors.blueAccent[700],
                      fontSize: "10px",
                      fontWeight: "bold",
                      padding: "10px 20px"
                    }} >
                    Repor
                  </Button>

                </Grid>
                <Grid item xs={12} sm={3} md={2} lg={1} style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button onClick={new_search}
                    sx={{
                      color: colors.grey[100],
                      bgcolor: colors.blueAccent[700],
                      fontSize: "10px",
                      fontWeight: "bold",
                      padding: "10px 20px"
                    }} startIcon={< SearchIcon />}>
                    Pesquisar
                  </Button>

                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid container
          backgroundColor={colors.primary[400]}
          alignItems="center"
          justifyContent="center"
          mt={4}
        >
          <Grid item xs={12} marginLeft={3} marginRight={3}>
            <Grid container spacing={3} marginTop={2} >
              <div style={{ width: '94%', marginLeft: 28, marginRight: 28, marginTop: 20, marginBottom: 20 }}>
                <TableContainer sx={{ backgroundColor: colors.primary[400] }}>
                  <Table aria-label="custom pagination table" >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Fotografia</TableCell>
                        <TableCell align="left">Nome</TableCell>
                        <TableCell align="left">E-mail</TableCell>
                        <TableCell align="left">Telemóvel</TableCell>
                        <TableCell align="left">Localidade</TableCell>
                        <TableCell align="left">Nascimento</TableCell>
                        <TableCell align="left">Coordenador</TableCell>
                        <TableCell align="left">Atividade</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? state.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : state
                      ).map((row) => (
                        <TableRow key={row.id} onClick={() => { callRoute(row.id) }}
                          hover
                          style={{ cursor: 'pointer' }}>

                          <TableCell><Avatar src={row.photo} /></TableCell>
                          <TableCell>{row.first_name + " " + row.last_name}</TableCell>
                          <TableCell >{row.email}</TableCell>
                          <TableCell >{row.phone}</TableCell>
                          <TableCell >{row.locality}</TableCell>
                          <TableCell >{row.birthday}</TableCell>
                          <TableCell >{row.coordenatorname}</TableCell>
                          <TableCell >{

                            (row.is_active === true) ?

                              <IconButton aria-label="delete" disableRipple disabled color="success">
                                <RadioButtonCheckedOutlinedIcon color="success" />
                                <Typography variant="h6" color={colors.grey[100]}>
                                  Conta ativa
                                </Typography>
                              </IconButton>

                              :
                              <IconButton aria-label="delete" disableRipple disabled color="success">
                                <RadioButtonCheckedOutlinedIcon color="error" />
                                <Typography variant="h6" color={colors.grey[100]}>
                                  Conta intativa
                                </Typography>
                              </IconButton>

                          }
                          </TableCell>

                        </TableRow>
                      ))}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={8} />
                        </TableRow>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          colSpan={8}
                          count={state.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: {
                              'aria-label': 'Linhas por página',
                            },
                            native: true,
                          }}
                          labelRowsPerPage='Linhas por página'
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                          labelDisplayedRows={(page) =>
                            `${page.from}-${page.to === -1 ? page.count : page.to} de ${page.count
                            }`
                          }
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </div>
    
            </Grid>
          </Grid>
        </Grid>
      </Box >
    </>

  );
};


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="Primeira página"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Anterior"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Próxima"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Última página"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
export default Users;