import React, { useContext, useState } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Box,   useTheme, Tab, Tabs, Typography
} from "@mui/material";
import Header from "../../components/Header";
import { styled } from "@mui/material/styles";

import { tokens } from "../../theme";
import ReportInObservations from './ReportInObservations';
import ReportToFuture from "./ReportToFuture";
import ReportClosed from "./ReportClosed";

const Report = (props) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const StyledTab = styled((props) => <Tab {...props} />)({
    "&.Mui-selected": {
      color: colors.blueAccent[500],
      fontSize: 16
    }
  });
  return (
    <>
      <Box m="20px" sx={{
        position: "block",
        marginLeft: props.collapse === true ? 15 : 40
      }}>
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Relatórios de Observação" />
        </Box>

        <Tabs value={selectedTab} onChange={handleTabChange}
          TabIndicatorProps={{ style: { background: colors.blueAccent[500] } }}>
          <StyledTab label="Em Observação" />
          <StyledTab label="Para futuro" />
          <StyledTab label="Concluidas" />
          <StyledTab label="Épocas Anteriores" />
        </Tabs>
        {selectedTab === 0 && (
           <ReportInObservations/>
        )}
        {selectedTab === 1 && (
          <ReportToFuture/>
          
        )}
        {selectedTab === 2 && (
           <ReportClosed/>
        )}
        {selectedTab === 3 && (
           <Typography>épocas anteriores</Typography>
        )}
      </Box>

    </>
  );
};


export default Report;