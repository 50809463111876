import React from "react";
import {
  Box, Grid, Typography, Checkbox, FormControlLabel, FormGroup
} from "@mui/material";


import TextBox from '../../components/TextBox';

export default function Attributes({ className, classId, colors, setProfileState, attributeList, setAttributesChecked, attributesChecked, profileState }) {
    const filtered = attributesChecked.filter(item => item.class === className);
  
    function updateOrAddClass(newClass) {
      setProfileState((prevState) => {
        // Verifica se o objeto com o mesmo class_id já existe
        const existingClassIndex = prevState.class_values.findIndex(
          (classObj) => classObj.class_id === newClass.class_id
        );
  
        // Se o objeto existir, atualize o valor
        if (existingClassIndex !== -1) {
          return {
            ...prevState,
            class_values: prevState.class_values.map((classObj, index) =>
              index === existingClassIndex ? { ...classObj, value: newClass.value } : classObj
            ),
          };
        } else {
          // Se não existir, adicione um novo objeto
          return {
            ...prevState,
            class_values: [...prevState.class_values, newClass],
          };
        }
      });
    }
  
    const findValueByClassId = (classId) => {
      const classObj = profileState.class_values.find((classObj) => classObj.class_id === classId);
  
      if (classObj) {
        return classObj.value;
      } else {
        // Caso o objeto com a class_id especificada não seja encontrado, retorna um valor padrão (por exemplo, null)
        return "";
      }
    };
  
  
    return (
      <>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <Typography variant="h3">{className}</Typography>
  
          </Grid>
          <Grid item xs={8}>
            <TextBox
              fullWidth
              inputProps={{
                type: 'number',
                inputMode: 'numeric',
              }}
              name={className}
              id={className}
              label={className + " (%)"}
              variant="outlined"
              sx={{ backgroundColor: colors.primary[400] }}
              value={findValueByClassId(classId)}
              onChange={event => {
                updateOrAddClass({ "class_id": classId, "value": event.target.value })
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckBoxList
              attributeList={attributeList}
              setAttributesChecked={setAttributesChecked}
              filtered={filtered}
              setProfileState={setProfileState}
              profileState={profileState} />
          </Grid>
        </Grid>
      </>
    )
  }
  function CheckBoxList({
    id,
    attributeList,
    colors,
    filtered,
    setAttributesChecked,
    setProfileState,
    profileState,
  }) {



    function handleAttributeChange(id) {
      if (!profileState.attributes.includes(id)) {
        // Adicione o id do atributo ao campo de atributos do perfil
        setProfileState((prevState) => ({
          ...prevState,
          attributes: [...prevState.attributes, id],
        }));
      } else {
        // Remova o id do atributo do campo de atributos do perfil
        setProfileState((prevState) => ({
          ...prevState,
          attributes: prevState.attributes.filter((attributeId) => attributeId !== id),
        }));
      }
    }
  


    return (<>
      <Typography variant="h6">Atributos:</Typography>
      <Box sx={{ overflow: "auto", maxHeight: 300 }} ml={2}>
        <FormGroup ml={2}>
          {filtered.map((attribute) => (
            <FormControlLabel
              ml={2}
              key={attribute.attribute_id}
              control={
                <Checkbox
                  ml={2}
                  checked={profileState.attributes.includes(attribute.attribute_id)}
                  onChange={() => handleAttributeChange(attribute.attribute_id)}
                  color={profileState.attributes.includes(attribute.attribute_id) ? 'success' : 'default'}
                />
              }
              label={attribute.attribute}
            />
          ))}
        </FormGroup>
      </Box>
    </>
    );
  }
  