import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Box, Button, useTheme, TableFooter, TablePagination, Grid, TableContainer, InputBase, IconButton, Typography, Divider, Fade, Modal, Table, TableHead, TableBody, TableRow, TableCell
} from "@mui/material";
import TextBox from '../../components/TextBox';
import StadiumOutlinedIcon from '@mui/icons-material/StadiumOutlined';
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { tokens } from "../../theme";
import api from "../../components/confs";

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from 'prop-types';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="Primeira página"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Anterior"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Próxima"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Última página"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};



const Games = (id) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [stateGames, setStateGames] = useState([])
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [search_name, setSearchName] = useState("");
  const [openModal, setOpenModal] = useState(false);
 
  const [loader, setLoader] = useState(false);
  const severity = "success";
  const [messageModalName, setMessageName] = useState("");
  const [messageModal, setOpenMessage] = useState(false);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // To navigate to team page
  const navigate = useNavigate();
  const callRoute = (id) => {
    navigate('/game/' + id);
  }

  //--------------------------- ON START ---------------------------//


  useEffect(() => {
    get_games();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  const get_games = () => {
    setPage(0)
    const data = {
      "id": (id.id *1)
    };
    console.log(data)
    api.get(`/api/team/games/${id.id}`, 
      {   
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then(response => {
        console.log(response.data)
        setStateGames(response.data)
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }

  //-----------------------------------------------------------//

  return (
    <>

      <Loader loader={loader} />
      <Message messageModal={messageModal} messageModalName={messageModalName} severity={severity} setOpenMessage={setOpenMessage} />
      <Grid container>
        <Grid item xs={12} mt={2}>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Equipa</TableCell>
                  <TableCell>Casa/Fora</TableCell>
                  <TableCell>Campo</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Nº Relatórios</TableCell>
                  <TableCell>Scouters</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? stateGames.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : stateGames
                ).map((row, i) => (
                  <TableRow key={"game-"+row.game} onClick={()=>callRoute(row.game)} hover   style={{ cursor: 'pointer' }}>
                    <TableCell>{row.equipa}</TableCell>
                    <TableCell>{row.casa ? "Casa" : "Fora"}</TableCell>
                    <TableCell>{row.local}</TableCell>
                    <TableCell>{row.data}</TableCell>
                    <TableCell>{row.nObservados}</TableCell>
                    <TableCell>{row.scouters}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    //rowsPerPageOptions={[5,   10, 25, { label: 'Tudo', value: -1 }]}
                    rowsPerPageOptions={[8]}
                    colSpan={6}
                    count={stateGames.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'Linhas por página',
                      },
                      native: true,
                    }}
                    labelRowsPerPage='Linhas por página'
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelDisplayedRows={(page) =>
                      `${page.from}-${page.to === -1 ? page.count : page.to} de ${page.count
                      }`
                    }
                  >

                  </TablePagination>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>


  );
};

export default Games;