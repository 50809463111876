import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Box, Button, useTheme, TableFooter, TablePagination, Grid, TableContainer, Avatar, InputBase, IconButton, Typography,
  Tabs, Divider, Fade, Modal, Tab, Table, TableHead, TableBody, TableRow, TableCell
} from "@mui/material";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { useNavigate } from "react-router-dom";
import api from "../../components/confs";

import { tokens } from "../../theme";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SearchIcon from "@mui/icons-material/Search";
import { useParams } from "react-router-dom";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { styled } from "@mui/material/styles";
import PropTypes from 'prop-types';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import AddIcCallOutlinedIcon from '@mui/icons-material/AddIcCallOutlined';
import CloseIcon from '@mui/icons-material/Close';
import AddModeratorOutlinedIcon from '@mui/icons-material/AddModeratorOutlined';
import TextBox from "../../components/TextBox";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="Primeira página"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Anterior"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Próxima"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Última página"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};



const Association = (props) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const [loader, setLoader] = useState(false);

  const [state, setState] = useState({
    "id": id,
    "name": null,
    "acronym": null,
    "country": null,
    "address": null,
    "photo": null

  })

  const [stateTeam, setStateTeam] = useState([])
  const [stateContacts, setStateContacts] = useState([])


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  const [page1, setPage1] = useState(0);
  const rowsPerPage1 = 8;

  const [openModal, setOpenModal] = useState(false);
  const [openTeamModal, setOpenTeamModal] = useState(false);
  const [messageModalName, setMessageName] = useState("");
  const [messageModal, setOpenMessage] = useState(false);
  const [search_name, setSearchName] = useState("");
  const [search_name_team, setSearchNameTeam] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [severity, setSeverity] = useState("success")

  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState()


  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [selectedFile])

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0])
  }



  const submitNewContact = (event) => {
    event.preventDefault();
    setLoader(true)
    const formData = new FormData(event.currentTarget);
    formData.append('table_id', parseInt(id, 10))
    const data = {
      "name": formData.get('name'),
      "phone": formData.get('phone'),
      "email": formData.get('email'),
      "address": formData.get('address'),
      "table_id": parseInt(id, 10)
    }
    api.post('/api/association/contact', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {
        setMessageName("Contacto criado!")
        setOpenMessage(true);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      })
      .finally(() => {
        get_associations_contact_searching();
        setLoader(false);
        setOpenModal(false);

      });
  }

  const checkPermission = (id) => {
    return (
      user ? (user.is_admin ? true : (user.permissions.find(permission => permission.permissions === id)?.have)) : false
    )
  };

  const submitNewTeam = (event) => {
    event.preventDefault();
    setLoader(true)
    const formData = new FormData(event.currentTarget);
    formData.append('table_id', parseInt(id, 10))
    const data = {
      "name": formData.get('name'),
      "address": formData.get('address'),
      "city": formData.get('city'),
      "lat": formData.get('lat'),
      "long": formData.get('long'),
      "email": formData.get('email'),
      "fax": formData.get('fax'),
      "phone": formData.get('phone'),
      "federation_url": formData.get('federation_url'),
      "website": formData.get('website'),
      "association_id": parseInt(id, 10),
    }

    const photo = new FormData();
    photo.append('photo', formData.get('photo'));


    if (data.name.trim().length === 0) {
      setSeverity("warning")
      setMessageName("O nome da equipa é um campo obrigatório")
      setOpenMessage(true);
      setLoader(false)
      return;
    }

    api.post('/api/team', photo, {
      params: data,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {
        setSeverity("success")
        setMessageName("Equipa criado!")
        setOpenMessage(true);
        setPreview("")
        setOpenTeamModal(false);
      })
      .catch(error => {
        setSeverity("error")
        setMessageName(error)
        setOpenMessage(true);
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      })
      .finally(() => {
        get_associations_contact_searching();
        setLoader(false);

      });
  }




  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //--------------------------- ON START ---------------------------//


  useEffect(() => {
    get_association_data();
    get_teams_data();
    get_associations_contact_searching();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  const get_association_data = () => {
    api.get('/api/association/' + id, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {
        setState(response.data)
      }
      )
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }

  //--------------------------*----------------------------------//


  //--------------------------- TABS ---------------------------//


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const StyledTab = styled((props) => <Tab {...props} />)({
    "&.Mui-selected": {
      color: colors.blueAccent[500],
      fontSize: 16
    }
  });
  //-------------------------------------------------------------//



  //------------------- Search Teams ---------------------------//

  useEffect(() => {
    get_teams_data();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [search_name_team]);

  const get_teams_data = () => {
    setPage1(0)
    api.get('/api/teams/search',
      {
        params: { name: search_name_team || " ", association: id },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then(response => {
        setStateTeam(response.data);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      }).finally(() => {

      });
  }
  //-----------------------------------------------------------//




  //------------------- Search Contacts ---------------------------//
  useEffect(() => {
    get_associations_contact_searching();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [search_name]);


  const get_associations_contact_searching = () => {
    setPage(0)
    const config = {
      params: {
        id: id,
        search: search_name || ""
      },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    };
    api.get("/api/association/contact/search", config)
      .then(response => {
        setStateContacts(response.data)

      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }

  //-----------------------------------------------------------//


  // To navigate to team page
  const navigate = useNavigate();
  const callRoute = (id) => {
    navigate('/team/' + id);
  }
  return (
    <>

      <Loader loader={loader} />
      <Message messageModal={messageModal} messageModalName={messageModalName} severity={severity} setOpenMessage={setOpenMessage} />


      <Box m="20px" sx={{
        position: "block",
        marginLeft: props.collapse === true ? 15 : 40
      }}>



        <Grid container

          mt={4}
        >
          <Grid item xs={2}>
            <Grid container direction="column"
            >
              <Grid item mt={2} xs={12}>
                <Avatar alt={state.name} src={process.env.REACT_APP_API_BASE_URL + "association/photo/" + id} sx={{ width: 121, height: 121 }} />
              </Grid>
              <Grid item mt={2} xs={12}>
                <Typography variant="h3">{state.acronym}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">{state.name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  <LocationOnOutlinedIcon sx={{ width: 16, height: 16 }} />   {state.address + ", " + state.country}
                </Typography>
              </Grid>

            </Grid>
          </Grid>
          <Grid item xs={10}>
            <Tabs value={selectedTab} onChange={handleTabChange}
              TabIndicatorProps={{ style: { background: colors.blueAccent[500] } }}>
              <StyledTab label="Equipas" />
              <StyledTab label="Contatos" />
            </Tabs>
            {selectedTab === 0 && (


              <Grid container>
                <Grid item xs={10} mt={2}>

                  <Box
                    display="flex"
                    backgroundColor={colors.primary[400]}
                    borderRadius="3px"
                  >
                    <InputBase sx={{ ml: 2, mt: 1, mb: 1, flex: 1 }} placeholder="Procurar Equipa"
                      onChange={event => {
                        setSearchNameTeam(event.target.value);
                      }}
                      value={search_name_team || ''}
                      inputProps={{ style: { fontSize: 14 } }} />
                    <IconButton type="button" sx={{ p: 1 }}>
                      <SearchIcon />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item xs={2} mt={2}>

                  <Box style={{ textAlign: 'right' }}>
                    <Button
                      sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                      }}
                      onClick={() => { setOpenTeamModal(true) }}
                    >
                      <AddModeratorOutlinedIcon sx={{ mr: "10px" }} />
                      Criar Equipa
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} mt={2}>

                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Fotografia</TableCell>
                          <TableCell>Nome</TableCell>
                          <TableCell>Morada</TableCell>
                          <TableCell>E-mail</TableCell>
                          <TableCell>Contacto</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(rowsPerPage1 > 0
                          ? stateTeam.slice(page1 * rowsPerPage1, page1 * rowsPerPage1 + rowsPerPage1)
                          : stateTeam
                        ).map((row, i) => (
                          <TableRow key={"team-" + i} onClick={() => { callRoute(row.id) }}>
                            <TableCell sx={{ textAlign: 'center' }}>
                              <img alt={row.name} src={process.env.REACT_APP_API_BASE_URL + "team/photo/" + row.id} style={{ margin: '0 auto', display: 'block' }} height={40} />
                            </TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.address}</TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>{row.phone}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            //rowsPerPageOptions={[5, 6,7,8,9, 10, 25, { label: 'Tudo', value: -1 }]}
                            rowsPerPageOptions={[8]}
                            colSpan={5}
                            count={stateTeam.length}
                            //rowsPerPage={rowsPerPage1}
                            rowsPerPage={8}

                            page={page1}
                            SelectProps={{
                              inputProps: {
                                'aria-label': 'Linhas por página',
                              },
                              native: true,
                            }}
                            labelRowsPerPage='Linhas por página'
                            onPageChange={handleChangePage1}
                            //onRowsPerPageChange={handleChangeRowsPerPage1}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={(page1) =>
                              `${page1.from}-${page1.to === -1 ? page1.count : page1.to} de ${page1.count
                              }`
                            }
                          >

                          </TablePagination>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>

            )}
            {selectedTab === 1 && (



              <Grid container>
                <Grid item xs={10} mt={2}>
                  {checkPermission(5) &&

                    <Box
                      display="flex"
                      backgroundColor={colors.primary[400]}
                      borderRadius="3px"
                    >
                      <InputBase sx={{ ml: 2, mt: 1, mb: 1, flex: 1 }} placeholder="Procurar Contacto"
                        onChange={(e) => {
                          setSearchName(e.target.value);
                        }}
                        value={search_name || ''}
                        inputProps={{ style: { fontSize: 14 } }} />
                      <IconButton type="button" sx={{ p: 1 }}>
                        <SearchIcon />
                      </IconButton>
                    </Box>
                  }
                </Grid>
                <Grid item xs={2} mt={2}>

                  <Box style={{ textAlign: 'right' }}>
                    {checkPermission(6) &&
                      <Button
                        sx={{
                          backgroundColor: colors.blueAccent[700],
                          color: colors.grey[100],
                          fontSize: "14px",
                          fontWeight: "bold",
                          padding: "10px 20px",
                        }}
                        onClick={() => { setOpenModal(true) }}
                      >
                        <  AddIcCallOutlinedIcon sx={{ mr: "10px" }} />
                        Criar Contacto
                      </Button>
                    }
                  </Box>
                </Grid>
                {checkPermission(5) &&

                  <Grid item xs={12} mt={2}>

                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell>Morada</TableCell>
                            <TableCell>Telemóvel</TableCell>
                            <TableCell>E-mail</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(rowsPerPage > 0
                            ? stateContacts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : stateContacts
                          ).map((row, i) => (
                            <TableRow key={"contact-" + i}>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>{row.address}</TableCell>
                              <TableCell>{row.phone}</TableCell>
                              <TableCell>{row.email}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              //rowsPerPageOptions={[5,   10, 25, { label: 'Tudo', value: -1 }]}
                              rowsPerPageOptions={[8]}
                              colSpan={4}
                              count={stateContacts.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              SelectProps={{
                                inputProps: {
                                  'aria-label': 'Linhas por página',
                                },
                                native: true,
                              }}
                              labelRowsPerPage='Linhas por página'
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              ActionsComponent={TablePaginationActions}
                              labelDisplayedRows={(page) =>
                                `${page.from}-${page.to === -1 ? page.count : page.to} de ${page.count
                                }`
                              }
                            >

                            </TablePagination>
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </Grid>
                }
              </Grid>

            )}

          </Grid>
        </Grid>



      </Box >

      {/** Contact Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={() => { setOpenModal(false); }}
        closeAfterTransition
      >
        <Fade in={openModal}>
          <Box component="form"
            noValidate
            onSubmit={submitNewContact}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 700,
              height: 480,
              bgcolor: colors.primary[400],
              boxShadow: 24,
              p: 4,
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{
                paddingBottom: '10px',
                borderStyle: 'solid',
                borderColor: colors.blueAccent[300],
                borderWidth: '0 0 2px 0'
              }}>
                <Typography component={'span'} variant="h3">Criação de novo contacto para associação</Typography>
                <IconButton
                  aria-label="close"
                  onClick={() => { setOpenModal(false); }}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Divider flexItem variant="middle" />
              <Grid item xs={12}>
                <Grid container spacing={2} mt={2}>
                  <Grid item xs={12}>
                    <TextBox fullWidth name="name" id="name" label="Nome" variant="outlined" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextBox fullWidth name="address" id="address" label="Morada" variant="outlined" />
                  </Grid>

                  <Grid item xs={12}>
                    <TextBox fullWidth name="phone" id="phone" label="Telemóvel" variant="outlined" />
                  </Grid>

                  <Grid item xs={12}>
                    <TextBox fullWidth name="email" id="email" label="E-mail" variant="outlined" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              pt={4}
              spacing={2}
            >
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => setOpenModal(false)}>Cancelar</Button>
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="success" startIcon={<AddOutlinedIcon />} >Criar</Button>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>

      {/** Team Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openTeamModal}
        onClose={() => { setOpenTeamModal(false); setPreview(""); }}
        closeAfterTransition
      >
        <Fade in={openTeamModal}>
          <Box component="form"
            noValidate
            onSubmit={submitNewTeam}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 800,
              height: 700,
              bgcolor: colors.primary[400],
              boxShadow: 24,
              p: 4,
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{
                paddingBottom: '10px',
                borderStyle: 'solid',
                borderColor: colors.blueAccent[300],
                borderWidth: '0 0 2px 0'
              }}>
                <Typography component={'span'} variant="h3">Criação de Equipa para a associação</Typography>
                <IconButton
                  aria-label="close"
                  onClick={() => { setOpenTeamModal(false); setPreview(""); }}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Divider flexItem variant="middle" />
              <Grid item xs={12}>
                <Typography component={'span'} variant="h5">{state.name}</Typography>

                <Grid container spacing={2} mt={2}>


                  <Grid item xs={3}  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} style={{ display: "flex", justifyContent: 'center' }}>
                        <Avatar alt="preview" src={preview} sx={{ width: 100, height: 100, fontSize: 15 }} />
                      </Grid>
                      <Grid item xs={12} style={{ display: "flex", justifyContent: 'center' }}>
                        <input
                          required
                          id="photo"
                          label="Fotografia"
                          name="photo"
                          type="file"
                          onChange={onSelectFile}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={9} >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextBox fullWidth name="name" id="name" label="Nome da Equipa" variant="outlined" required />
                      </Grid>
                      <Grid item xs={8}>
                        <TextBox fullWidth name="address" id="address" label="Morada" variant="outlined" />
                      </Grid>
                      <Grid item xs={4}>
                        <TextBox fullWidth name="city" id="city" label="Cidade" variant="outlined" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextBox fullWidth name="lat" id="lat" label="Latitude" variant="outlined" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextBox fullWidth name="long" id="long" label="Longitude" variant="outlined" />
                      </Grid>
                      <Grid item xs={12}>
                        <TextBox fullWidth name="email" id="email" label="E-mail" variant="outlined" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextBox fullWidth name="fax" id="fax" label="Fax" variant="outlined" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextBox fullWidth name="phone" id="phone" label="Telefone" variant="outlined" />
                      </Grid>
                      <Grid item xs={12}>
                        <TextBox fullWidth name="federation_url" id="federation_url" label="Site na Federação" variant="outlined" />
                      </Grid>
                      <Grid item xs={12}>
                        <TextBox fullWidth name="website" id="website" label="Site" variant="outlined" />
                      </Grid>
                    </Grid>
                  </Grid>



                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              pt={4}
              spacing={2}
            >
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => { setOpenTeamModal(false); setPreview(""); }}>Cancelar</Button>
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="success" startIcon={<AddOutlinedIcon />} >Criar</Button>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>


  );
};

export default Association;