import React, { useContext, useState } from "react";
import { UserContext } from "../../context/UserContext";
import {
    Box, Button, useTheme, Tab, Tabs, Typography, Grid, Modal, Fade, Divider, Autocomplete,
    IconButton, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, TableFooter, TablePagination
} from "@mui/material";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import { styled } from "@mui/material/styles";
import api from "../../components/confs";

import { useNavigate, useParams } from "react-router-dom";

import { tokens } from "../../theme";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Message from "../../components/Message";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { pt } from "date-fns/locale";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import TextBox from "../../components/TextBox";
import { useEffect } from "react";
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="Primeira página"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="Anterior"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Próxima"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Última página"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


const Expense = (props) => {
    // eslint-disable-next-line
    const { token, setToken, user } = useContext(UserContext);
    const theme = useTheme();
    const { id } = useParams();

    const colors = tokens(theme.palette.mode);
    const [loader, setLoader] = useState(false);
    // eslint-disable-next-line
    const [messageModalName, setMessageName] = useState("");
    const [messageModal, setOpenMessage] = useState(false);
    // eslint-disable-next-line
    const [severity, setSeverity] = useState("success")
    const [openModal, setOpenModal] = useState(false)
    const [openModalEdit, setOpenModalEdit] = useState(false)
    const [selectedDateStart, setSelectedDateStart] = useState(new Date());
    const [reports, setReports] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [hasBeenSubmited, setHasBeenSubmited] = useState(true);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8);

    const [value_money, setValueMoney] = useState('');
    const [expenseType, setType] = useState('');
    const [description, setDescription] = useState('');

    const [value_moneyEdit, setValueMoneyEdit] = useState('');
    const [expenseTypeEdit, setTypeEdit] = useState('');
    const [descriptionEdit, setDescriptionEdit] = useState('');
    const [idEdit, setIdEdit] = useState('');
    const navigate = useNavigate();


    const [page1, setPage1] = useState(0);
    const [rowsPerPage1, setRowsPerPage1] = useState(8);

    const handleChangePage1 = (event, newPage) => {
        setPage1(newPage);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeRowsPerPage1 = (event) => {
        setRowsPerPage1(parseInt(event.target.value, 10));
        setPage1(0);
    };
    useEffect(() => {
        getExpensesByid();
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getReportsByDate();
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [selectedDateStart])

    function getReportsByDate() {
        setLoader(true);
        api.get('/api/reports/date',
            {
                params: { date: obterData(selectedDateStart) },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(response => {
                setReports(response.data);
            })
            .catch(error => {
                console.error(error);
                if (error.response.data.detail === "unauthorized login") {
                    setToken(null)
                }
            }).finally(() => {
                setLoader(false);

            });

    }

    function obterData(data) {
        var dataObj = new Date(data);
        var dia = dataObj.getDate();
        var mes = dataObj.getMonth() + 1; // Lembrando que os meses são indexados em 0
        var ano = dataObj.getFullYear();
        return ano + '-' + mes + '-' + dia;
    }

    function obterData1(data) {
        var dataObj = new Date(data);
        var dia = dataObj.getDate();
        var mes = dataObj.getMonth() + 1; // Lembrando que os meses são indexados em 0
        var ano = dataObj.getFullYear();
        return dia + '/' + mes + '/' + ano;
    }
    function obterHora(data) {
        var dataObj = new Date(data);
        var hora = dataObj.getHours().toString().padStart(2, '0');
        var minuto = dataObj.getMinutes().toString().padStart(2, '0');
        return hora + ':' + minuto;
    }
    function closeModal() {
        setOpenModal(false);
    }
    function closeModalEdit() {
        setOpenModalEdit(false);
    }

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };
    const StyledTab = styled((props) => <Tab {...props} />)({
        "&.Mui-selected": {
            color: colors.blueAccent[500],
            fontSize: 16
        }
    });

    const handleValueChange = (event) => {
        const inputValue = event.target.value;
        setValueMoney(inputValue);
    };
    const handleValueChangeEdit = (event) => {
        const inputValue = event.target.value;
        setValueMoneyEdit(inputValue);
    };

    function addExpense() {
        setExpenses((prevState) => [...prevState, { id: Math.random().toString(36).substr(2, 9), 'description': description, 'type': expenseType, 'amount': parseFloat(value_money) }]);
        closeModal();
        setDescription("");
        setType("");
        setValueMoney("");
    }
    function editExpense() {
        setExpenses(expenses.map(despesa =>
            despesa.id === idEdit ? { 'description': descriptionEdit, 'amount': parseFloat(value_moneyEdit), 'type': expenseTypeEdit } : despesa
        ));
        setOpenModalEdit(false)

    }


    function calculateTotalAmount() {
        const totalAmount = expenses.reduce((accumulator, item) => accumulator + parseFloat(item.amount), 0);
        return totalAmount;
    };

    function Save() {
        setLoader(true)
        const data = expenses.map(({ expenses_id, id, ...rest }) => rest)

        console.log(data)
        api.post('/api/expenses/save?expense_id=' + id, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                setMessageName("Guardado com sucesso!");
                setSeverity("success");
                setOpenMessage(true);
            })
            .catch(error => {
                console.error(error);
                if (error.response.data.detail === "unauthorized login") {
                    setToken(null)
                }
                setMessageName(error);
                setSeverity("error");
                setOpenMessage(true);

            }).finally(() => {
                setLoader(false);
            });

    }

    function ValidateExpenses() {

        if (reports.filter((report) => !report.finish).length > 0) {
            return false
        }
        return true;
    }
    function Submit() {

        if (ValidateExpenses()) {

            setLoader(true)
            const data = expenses.map(({ expenses_id, id, ...rest }) => rest)

            api.post('/api/expenses/submit?expense_id=' + id, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => {
                    setMessageName("Guardado com sucesso!");
                    setSeverity("success");
                    setOpenMessage(true);
                    navigate('/expense/' + id)
                })
                .catch(error => {
                    console.error(error);
                    if (error.response.data.detail === "unauthorized login") {
                        setToken(null)
                    }
                    setMessageName(error);
                    setSeverity("error");
                    setOpenMessage(true);

                }).finally(() => {
                    setLoader(false);
                });

        } else {
            setMessageName("Não é possível submeter, pois tem relatórios por concluir!");
            setSeverity("error");
            setOpenMessage(true);
        }
    }
    function removeLine(id) {
        setExpenses(expenses.filter(ex => ex.id !== id));
    }
    function editLine(id, descript, type, value) {
        setDescriptionEdit(descript)
        setTypeEdit(type)
        setValueMoneyEdit(value)
        setIdEdit(id)
        setOpenModalEdit(true)
    }

    function getExpensesByid() {
        setLoader(true);
        api.get('/api/expense/' + id,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(response => {
                console.log(response)
                setSelectedDateStart(response.data.expense.date);
                setExpenses(response.data.rows)
                setHasBeenSubmited(response.data.expense.submetida);
            })
            .catch(error => {
                console.error(error);
                if (error.response.data.detail === "unauthorized login") {
                    setToken(null)
                }
            }).finally(() => {
                setLoader(false);

            });
    }

    return (
        <>
            <Loader loader={loader} />
            <Message messageModal={messageModal} messageModalName={messageModalName} severity={severity} setOpenMessage={setOpenMessage} />

            <Box m="20px" sx={{
                position: "block",
                marginLeft: props.collapse === true ? 15 : 40
            }}>
                {/* HEADER */}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title="Despesa" />
                    {(hasBeenSubmited == false) &&
                        <Box spacing={2}>
                            <Button
                                sx={{
                                    backgroundColor: colors.blueAccent[700],
                                    color: colors.grey[100],
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    padding: "10px 20px",
                                }}
                                onClick={() => { Save(); }} >
                                <AddOutlinedIcon sx={{ mr: "10px" }} />
                                Guardar
                            </Button>


                            <Button
                                sx={{
                                    backgroundColor: colors.blueAccent[700],
                                    color: colors.grey[100],
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    padding: "10px 20px",
                                    marginLeft: "10px",
                                }}
                                onClick={() => { Submit() }}>
                                <AddOutlinedIcon sx={{ mr: "10px" }} />
                                Submeter
                            </Button>
                        </Box>
                    }

                </Box>
                <Grid container spacing={2}>

                    <Grid item xs={6}>
                        <Box >
                            <Typography variant="h3"> {"Data: " + selectedDateStart} </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-end" alignItems="center">
                            <Typography variant="h2">{"Total: " + calculateTotalAmount() + " €"}</Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container
                            backgroundColor={colors.primary[400]}
                            alignItems="center"
                            justifyContent="center"
                            mt={4} >
                            <Grid item xs={12} ml={4} mr={4} mt={4} mb={4}>
                                <Tabs value={selectedTab} onChange={handleTabChange}
                                    TabIndicatorProps={{ style: { background: colors.blueAccent[500] } }}>
                                    <StyledTab label="Relatórios referentes" />
                                    <StyledTab label="Despesa" />
                                </Tabs>

                                {selectedTab === 0 && (
                                    <TableContainer sx={{ backgroundColor: colors.primary[400] }}>
                                        <Table aria-label="custom pagination table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Atleta</TableCell>
                                                    <TableCell>Camisola</TableCell>
                                                    <TableCell>Equipa visitada</TableCell>
                                                    <TableCell>Equipa visitante</TableCell>
                                                    <TableCell>Data</TableCell>
                                                    <TableCell>Hora</TableCell>
                                                    <TableCell>Escalão</TableCell>
                                                    <TableCell>Concluído</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {(rowsPerPage > 0
                                                    ? reports.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    : reports
                                                ).map((row, i) => (
                                                    <TableRow key={"report-" + i}>
                                                        <TableCell>{row.name}</TableCell>
                                                        <TableCell>{row.shirt_name + "(" + row.shirt_number + ")"}</TableCell>
                                                        <TableCell>{row.team1}</TableCell>
                                                        <TableCell>{row.team2}</TableCell>
                                                        <TableCell>{obterData1(row.date)}</TableCell>
                                                        <TableCell>{obterHora(row.date)}</TableCell>
                                                        <TableCell>{row.grade}</TableCell>
                                                        <TableCell>{row.finish === true ? "Sim" : "Não"}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        //rowsPerPageOptions={[5,   10, 25, { label: 'Tudo', value: -1 }]}
                                                        rowsPerPageOptions={[8]}
                                                        colSpan={8}
                                                        count={reports.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        SelectProps={{
                                                            inputProps: {
                                                                'aria-label': 'Linhas por página',
                                                            },
                                                            native: true,
                                                        }}
                                                        labelRowsPerPage='Linhas por página'
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                        ActionsComponent={TablePaginationActions}
                                                        labelDisplayedRows={(page) =>
                                                            `${page.from}-${page.to === -1 ? page.count : page.to} de ${page.count
                                                            }`
                                                        }
                                                    >

                                                    </TablePagination>
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </TableContainer>
                                )}
                                {selectedTab === 1 && (
                                    <>
                                        {(hasBeenSubmited == false) &&
                                            <Box display="flex" justifyContent="flex-end" alignItems="center">
                                                <Button
                                                    sx={{
                                                        backgroundColor: colors.blueAccent[700],
                                                        color: colors.grey[100],
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        padding: "10px 20px",
                                                        marginLeft: "10px",
                                                    }}
                                                    onClick={() => { setOpenModal(true); }}
                                                >
                                                    <AddOutlinedIcon sx={{ mr: "10px" }} />
                                                    Adicionar valor
                                                </Button>
                                            </Box>
                                        }
                                        <TableContainer sx={{ backgroundColor: colors.primary[400] }}>
                                            <Table aria-label="custom pagination table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Descrição</TableCell>
                                                        <TableCell>Tipo</TableCell>
                                                        <TableCell>Valor (€)</TableCell>
                                                        <TableCell> </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {(rowsPerPage1 > 0
                                                        ? expenses.slice(page1 * rowsPerPage1, page1 * rowsPerPage1 + rowsPerPage1)
                                                        : expenses
                                                    ).map((row, i) => (
                                                        <TableRow key={"expense-" + i}>
                                                            <TableCell>{row.description}</TableCell>
                                                            <TableCell>{row.type}</TableCell>
                                                            <TableCell>{row.amount}</TableCell>
                                                            <TableCell>
                                                                {(hasBeenSubmited == false) &&

                                                                    <><IconButton variant="plain" color={colors.blueAccent[500]}
                                                                        onClick={(e) => removeLine(row.id)}>
                                                                        <DeleteIcon />
                                                                    </IconButton><IconButton variant="plain" color={colors.blueAccent[500]}
                                                                        onClick={(e) => editLine(row.id, row.description, row.type, row.amount)}>
                                                                            <EditIcon />
                                                                        </IconButton></>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            //rowsPerPageOptions={[5,   10, 25, { label: 'Tudo', value: -1 }]}
                                                            rowsPerPageOptions={[8]}
                                                            colSpan={4}
                                                            count={expenses.length}
                                                            rowsPerPage={rowsPerPage1}
                                                            page={page1}
                                                            SelectProps={{
                                                                inputProps: {
                                                                    'aria-label': 'Linhas por página',
                                                                },
                                                                native: true,
                                                            }}
                                                            labelRowsPerPage='Linhas por página'
                                                            onPageChange={handleChangePage1}
                                                            onRowsPerPageChange={handleChangeRowsPerPage1}
                                                            ActionsComponent={TablePaginationActions}
                                                            labelDisplayedRows={(page1) =>
                                                                `${page1.from}-${page1.to === -1 ? page1.count : page1.to} de ${page1.count
                                                                }`
                                                            }
                                                        >

                                                        </TablePagination>
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        </TableContainer>
                                    </>
                                )}


                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Box>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={closeModal}
                closeAfterTransition
            >
                <Fade in={openModal}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 600,
                            height: 400,
                            bgcolor: colors.primary[400],
                            boxShadow: 24,
                            p: 4,
                        }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{
                                paddingBottom: '10px',
                                borderStyle: 'solid',
                                borderColor: colors.blueAccent[300],
                                borderWidth: '0 0 2px 0'
                            }}>
                                <Typography component={'span'} variant="h3">Novo gasto</Typography>
                                <IconButton
                                    aria-label="close"
                                    onClick={closeModal}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                    }} >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                            <Divider flexItem variant="middle" />
                            <Grid container spacing={2} mt={1}>
                                <Grid item xs={12}>
                                    <TextBox
                                        fullWidth
                                        label="Descrição"
                                        value={description}
                                        onChange={(ev, value) => { setDescription(ev.target.value) }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        options={["", "Alimentação", "Combustível", "Portagens/Scuts", "Alojamento", "Transporte"]}
                                        renderInput={(params) => (
                                            <TextBox {...params} label="Tipo de gasto" variant="outlined" />
                                        )}
                                        onChange={(event, value) => { setType(value) }}
                                        value={expenseType} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextBox
                                        label="Valor monetário"
                                        value={value_money}
                                        onChange={handleValueChange}
                                        type="number"
                                        InputProps={{
                                            endAdornment: "€",
                                        }} />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-end"
                                pt={4}
                                spacing={2} >
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={() => setOpenModal(false)}>Cancelar</Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => { addExpense(); }} variant="contained" color="success" startIcon={<AddOutlinedIcon />} >Adicionar</Button>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Box>
                </Fade>
            </Modal>


            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModalEdit}
                onClose={closeModalEdit}
                closeAfterTransition >
                <Fade in={openModalEdit}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 600,
                            height: 400,
                            bgcolor: colors.primary[400],
                            boxShadow: 24,
                            p: 4,
                        }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{
                                paddingBottom: '10px',
                                borderStyle: 'solid',
                                borderColor: colors.blueAccent[300],
                                borderWidth: '0 0 2px 0'
                            }}>
                                <Typography component={'span'} variant="h3">Alterar gasto</Typography>
                                <IconButton
                                    aria-label="close"
                                    onClick={closeModalEdit}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                    }} >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                            <Divider flexItem variant="middle" />
                            <Grid container spacing={2} mt={1}>
                                <Grid item xs={12}>
                                    <TextBox
                                        fullWidth
                                        label="Descrição"
                                        value={descriptionEdit}
                                        onChange={(ev, value) => { setDescriptionEdit(ev.target.value) }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        options={["", "Alimentação", "Combustível", "Portagens/Scuts", "Alojamento", "Transporte"]}
                                        renderInput={(params) => (
                                            <TextBox {...params} label="Tipo de gasto" variant="outlined" />
                                        )}
                                        onChange={(event, value) => { setTypeEdit(value) }}
                                        value={expenseTypeEdit} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextBox
                                        label="Valor monetário"
                                        value={value_moneyEdit}
                                        onChange={handleValueChangeEdit}
                                        type="number"
                                        InputProps={{
                                            endAdornment: "€",
                                        }} />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-end"
                                pt={4}
                                spacing={2} >
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={() => setOpenModalEdit(false)}>Cancelar</Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => { editExpense(); }} variant="contained" color="success" startIcon={<EditIcon />} >Altear</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};


export default Expense;