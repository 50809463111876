import React, { useState, useEffect, useContext } from 'react';

import api from "../../components/confs";

import {
  Box, Button, useTheme, Grid, Tooltip, IconButton, Table, TableBody, Badge,
  TableCell, TableContainer, TableHead, TableRow, 
  TableFooter, TablePagination, Typography, Autocomplete, Divider, Modal, Fade
} from "@mui/material";
import { tokens } from "../../theme";
import { UserContext } from "../../context/UserContext";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { pt } from "date-fns/locale";
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TextBox from '../../components/TextBox';
const GamesSearch = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [scouters, setScouters] = useState([]);
  const [scoutersToFilter, setScoutersToFilter] = useState([]);
  const [scouter, setScouter] = useState(null);
  const [scouterFilter, setScouterFilter] = useState(null);
  
  const [selectedDateEnd, setSelectedDateEnd] = useState(new Date());
  const [selectedDateStart,setSelectedDateStart] = useState(new Date());
  const [gameSelected, setGameSelected] = useState([]);
  const [first_obs, setFirstObs] = useState("");

  const [loader, setLoader] = useState(false);
  // eslint-disable-next-line
  const [messageModalName, setMessageName] = useState("");
  const [messageModal, setOpenMessage] = useState(false);
  // eslint-disable-next-line
  const [severity, setSeverity] = useState("success")

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [games, setGames] = useState([])

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - games.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const [openModal, setOpenModal] = useState(false);
  function closeModal() {
    setOpenModal(false);
  }


  function dateFormat(datetimeStr) {
    const dt = new Date(datetimeStr);
    const dateStr = dt.toLocaleDateString();
    const options = { hour: 'numeric', minute: 'numeric' };
    const timeStr = dt.toLocaleTimeString([], options);
    return dateStr + ' ' + timeStr;
  }
  function onlyDate(datetimeStr) {
    let dia = datetimeStr.getDate();
let mes = datetimeStr.getMonth() + 1; 
let ano = datetimeStr.getFullYear();

dia = (dia < 10 ? "0" : "") + dia;
mes = (mes < 10 ? "0" : "") + mes;

let dataFormatada = `${dia}-${mes}-${ano}`;
    return dataFormatada;
  }
  useEffect(() => {
    getScouters();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getGames();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [selectedDateStart,selectedDateEnd,scouterFilter]);

  function getScouters() {
    api.get('/api/users',
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then((response) => {
        const labelValueArray = response.data.map(obj => ({
          label: `${obj.first_name} ${obj.last_name}`,
          value: obj.id
        }));
        setScouters(labelValueArray)
        labelValueArray.unshift({
          label: "Todos",
          value: null
        });
        setScoutersToFilter(labelValueArray)
        setScouterFilter(null)
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      })
      .finally(() => {
        //  setOpenNewGroup(false)
        // setForceReload(!forceReload)
      });
  }


  function getGames() {
    let scouter_value= 0 ;
    if (scouterFilter ){
      scouter_value = scouterFilter.value;
      if (scouter_value==null){
        scouter_value=0;
      }
    }
    api.get('/api/games/search?start=' + onlyDate(selectedDateStart) + '&end=' + onlyDate(selectedDateEnd) + '&scouter=' + scouter_value,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then((response) => {
        setGames(response.data)
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      })
      .finally(() => {
        //  setOpenNewGroup(false)
        // setForceReload(!forceReload)
      });
  }

  function openModalScouter(game) {
    setGameSelected(game);
    setOpenModal(true)
  }


  function addSchedule() {
    setLoader(true);
    const data =
    {
      game: gameSelected,
      user: scouter.value,
      first_obs: first_obs
    };
    api.post('/api/schedule', data,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then((response) => {
        setMessageName("Scouter adicionado com sucesso");
        setSeverity("success")
        setOpenMessage(true);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
        setMessageName("error");
        setSeverity("warning")

      })
      .finally(() => {
        getGames();
        setOpenModal(false);
        setLoader(false);
        setOpenMessage(true);
        setScouter(null);
        setGameSelected(null)
        setFirstObs("")
      });
  }
  return (

    <>
      <Loader loader={loader} />
      <Message messageModal={messageModal} messageModalName={messageModalName} severity={severity} setOpenMessage={setOpenMessage} />
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pt}>
                <DatePicker
                  fullWidth
                  label="Data início"
                  value={selectedDateStart}
                  onChange={(newDate) => { setSelectedDateStart(newDate); }}
                  renderInput={(props) => <TextBox {...props} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pt}>
                <DatePicker
                  fullWidth
                  label="Data Fim"
                  value={selectedDateEnd}
                  onChange={(newDate) => { setSelectedDateEnd(newDate); }}
                  renderInput={(props) => <TextBox {...props} />}
                />
              </LocalizationProvider>

            </Grid>
            <Grid item xs={2}>
            <Autocomplete
                    options={scoutersToFilter}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => value && option === value}
                    renderInput={(params) => (
                      <TextBox {...params} label="Scouter" variant="outlined" />
                    )}
                    onChange={(event, value) => { setScouterFilter(value) }}
                    value={scouterFilter}
                  />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={2}>

          <TableContainer sx={{ backgroundColor: colors.primary[400] }}>
            <Table aria-label="custom pagination table" >
              <TableHead>
                <TableRow>
                  <TableCell  >Equipa Visitada</TableCell>
                  <TableCell  >Equipa Visitante</TableCell>
                  <TableCell  >Tipo</TableCell>
                  <TableCell  >Escalão</TableCell>
                  <TableCell  >Data e hora</TableCell>
                  <TableCell  >Local</TableCell>
                  <TableCell ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(games > 0
                  ? games.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : games
                ).map((row) => {
                  return (
                    <TableRow key={row.id}
                      hover
                      style={{ cursor: 'pointer' }}>
                      <TableCell>{row.team1}</TableCell>
                      <TableCell>{row.team2}</TableCell>
                      <TableCell >{row.type}</TableCell>
                      <TableCell>{row.grade}</TableCell>
                      <TableCell>{dateFormat(row.date)}</TableCell>
                      <TableCell>{row.local}</TableCell>
                      <TableCell>
                        <Grid container>
                          <Grid item xs={3}>

                            <Tooltip title={row.scouters["names"] ? row.scouters["names"] : ''}>
                              <IconButton>

                                <Badge badgeContent={row.scouters.count} color="primary">
                                  <PersonSearchIcon />
                                </Badge>
                              </IconButton>
                            </Tooltip>
                          </Grid>

                          <Grid item xs={6}>
                            <Button variant="contained"
                              startIcon={<PersonSearchIcon />}
                              sx={{ backgroundColor: colors.blueAccent[600] }}
                              onClick={() => {
                                openModalScouter(row.id)
                              }}>
                              <span>Adicionar Olheiro</span>
                            </Button >
                          </Grid>
                        </Grid>
                      </TableCell>

                    </TableRow>
                  )
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'Tudo', value: -1 }]}
                    colSpan={7}
                    count={games.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'Linhas por página',
                      },
                      native: true,
                    }}
                    labelRowsPerPage='Linhas por página'
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelDisplayedRows={(page) =>
                      `${page.from}-${page.to === -1 ? page.count : page.to} de ${page.count
                      }`
                    }
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>

        </Grid>
      </Grid>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={closeModal}
        closeAfterTransition
      >
        <Fade in={openModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 600,
              height: 500,
              bgcolor: colors.primary[400],
              boxShadow: 24,
              p: 4,
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{
                paddingBottom: '10px',
                borderStyle: 'solid',
                borderColor: colors.blueAccent[300],
                borderWidth: '0 0 2px 0'
              }}>
                <Typography component={'span'} variant="h3">Escolher Scouter</Typography>
                <IconButton
                  aria-label="close"
                  onClick={closeModal}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Divider flexItem variant="middle" />
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                  <Autocomplete
                    options={scouters}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => value && option === value}
                    renderInput={(params) => (
                      <TextBox {...params} label="Scouter" variant="outlined" />
                    )}
                    onChange={(event, value) => { setScouter(value) }}
                    value={scouter}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextBox
                    id="outlined-multiline-static"
                    multiline
                    fullWidth
                    rows={10}
                    label="Observação"
                    value={first_obs}
                    onChange={(e) => { setFirstObs(e.target.value) }} />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                pt={4}
                spacing={2}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={<AddOutlinedIcon />}
                    onClick={() => { addSchedule(); }}>
                    Adicionar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>

  );
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="Primeira página"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Anterior"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Próxima"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Última página"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


export default GamesSearch;