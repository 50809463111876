import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Box, Button, useTheme, Grid, IconButton, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, 
  TableFooter, TablePagination 
} from "@mui/material";
import Header from "../../components/Header"; 
import { tokens } from "../../theme";
import api from "../../components/confs";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types'; 
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';  

const ShadowTeam = (props) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  const [shadows, setShadows] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); 

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - shadows.length) : 0;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const navigate = useNavigate();
  const callRoute = (id) => {
    navigate('/shadow-team/' + id);
  }

  const get_shadows = () => {
    api.get('/api/shadows', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then((response) => {

        setShadows(response.data)
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }

  // On start
  useEffect(() => {
    get_shadows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <> 
      <Box m="20px" sx={{
        position: "block",
        marginLeft: props.collapse === true ? 15 : 40
      }}>
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Equipas Sombra" />
          <Box>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
              onClick={() => { navigate('/shadow-team/new') }}
            >
              <AddOutlinedIcon sx={{ mr: "10px" }} />
              Adicionar Equipa Sombra
            </Button>
          </Box>
        </Box>
        <Grid container spacing={2}>


          <Grid item xs={12}>

            <Grid container
              backgroundColor={colors.primary[400]}
              alignItems="center"
              justifyContent="center"

            >
              <Grid item xs={12} >
                <div style={{ width: '94%', marginLeft: 28, marginRight: 28, marginTop: 20, marginBottom: 20 }}>
                  <TableContainer sx={{ backgroundColor: colors.primary[400] }}>
                    <Table aria-label="custom pagination table" >
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Descrição</TableCell>
                          <TableCell align="left">Escalão</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(rowsPerPage > 0
                          ? shadows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          : shadows
                        ).map((row) => (
                          <TableRow key={row.id} onClick={() => { callRoute(row.id) }}
                            hover
                            style={{ cursor: 'pointer' }}>
                            <TableCell >{row.description}</TableCell>
                            <TableCell >{row.grade}</TableCell>
                          </TableRow>
                        ))}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={2} />
                          </TableRow>
                        )}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            colSpan={2}
                            count={shadows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                              inputProps: {
                                'aria-label': 'Linhas por página',
                              },
                              native: true,
                            }}
                            labelRowsPerPage='Linhas por página'
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={(page) =>
                              `${page.from}-${page.to === -1 ? page.count : page.to} de ${page.count
                              }`
                            }
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
          </Grid>

        </Grid>

      </Box>



    </>
  );
};


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="Primeira página"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Anterior"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Próxima"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Última página"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};



export default ShadowTeam;