import React, { useContext, useState, useEffect } from "react";
import {
    Box, Button, useTheme, Grid, Typography, IconButton,
    Modal, Fade, Divider, MenuItem, Select, InputLabel,
    FormControl, Radio, RadioGroup, FormControlLabel, FormLabel, Autocomplete
} from "@mui/material";
import TextBox from '../../components/TextBox';

import { useParams } from "react-router-dom";

import Header from "../../components/Header";
import { UserContext } from "../../context/UserContext";
import { tokens } from "../../theme";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import api from "../../components/confs";

import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';



const UserProfile = (props) => {
    // eslint-disable-next-line
    const { token, setToken, user } = useContext(UserContext);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [state, setState] = useState({
        "id": 0,
        "first_name": null,
        "last_name": null,
        "email": null,
        "locality": null,
        "birthday": null,
        "phone": null,
        "sex": null,
        "photo": null,
        "group": null,
        "is_active": null,
        "is_admin": null,
        "created_on": null,
        "updated_on": null,
        "updated_by": null,
        "coordinatorid": null,
        "coordinatorname": null,
    }
    )
    const [sex, setSex] = useState('');
    const [date_birth, setDate] = useState(null);


    const [loader, setLoader] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openModalManagement, setOpenModalManagement] = useState(false);


    const [messageModalName, setMessageName] = useState("");
    const [messageModal, setOpenMessage] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [preview, setPreview] = useState()
    const selectedFile = "photo/" + state.id;
    const [structure, setStructUpdated] = useState({});
    const [managementStruct, setManagementStruct] = useState({});
    const [coordinators, setCoordinators] = useState([]);
    const [coordinator, setCoordinator] = useState([]);

    const [groups, setGroups] = useState([{}]);
    const [value, setValue] = useState();
    const [inputValue, setInputValue] = useState('');
    const [inputValue1, setInputValue1] = useState('');

    const { id } = useParams();

    const checkPermissions = (n) => {
        return (
            user ? (user.is_admin ? true : (user.permissions.find(permission => permission.permissions === n)?.have)) : false
        )
    };

    const submitEditUser = (event) => {
        event.preventDefault();
        setLoader(true)
        const date = new Date(structure.birthday)
        date.setDate(date.getDate());
        const data = {
            first_name: structure.first_name,
            last_name: structure.last_name,
            email: structure.email,
            locality: structure.locality,
            birthday: date.toISOString().slice(0, 10),
            phone: structure.phone,
            sex: structure.sex,
        };
        const config = {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        };
        api.put('/api/user/' + id, data, config)
            .then(response => {
                setMessageName("Editado com sucesso")
                setOpenMessage(true)
            })
            .catch(error => {
                console.error(error);
                if (error.response.data.detail === "unauthorized login") {
                    setToken(null)
                }
            })
            .finally(() => {
                setLoader(false);
                setOpenModal(false);
                get_user();
            });
    }

    const submitEditUserManagement = (event) => {
        event.preventDefault();
        setLoader(true)
        const data = {
            "is_active": managementStruct.is_active,
            "is_admin": managementStruct.is_admin,
            "group_id": managementStruct.group_id,
            "coordinator": coordinator ? coordinator.key : 0,
        };
        const config = {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        };
        api.put('/api/user/management/' + id, data, config)
            .then(response => {
                setMessageName("Editado com sucesso")
                setOpenMessage(true)
            })
            .catch(error => {
                console.error(error);
                if (error.response.data.detail === "unauthorized login") {
                    setToken(null)
                }
            })
            .finally(() => {
                setOpenModalManagement(false);
                setLoader(false);
                get_user();
            });
    }

    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }

        if (openModal === false) {
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [selectedFile])


    const get_user = () => {

        api.get('/api/user/' + id, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                setState(response.data)
                setPreview("photo/" + response.data.id)
                setSex(response.data.sex)
                setDate(response.data.birthday)
                setCoordinator(response.data.coordenatorid)
                setManagementStruct({
                    is_active: response.data.is_active,
                    is_admin: response.data.is_admin,
                    group_id: response.data.group_id,
                })
            }
            )
            .catch(error => {
                console.error(error);
                if (error.response.data.detail === "unauthorized login") {
                    setToken(null)
                }
            })
            .finally(() => {
            });
    }
    const get_groups = () => {
        api.get('/api/groups', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                // eslint-disable-next-line 
                const newData = response.data.map(item => {
                    const { group_name, id } = item;
                    return { label: group_name, key: id, name: id };
                });
                setGroups(newData)
            })
            .catch(error => {
                console.error(error);
                if (error.response.data.detail === "unauthorized login") {
                    setToken(null)
                }
            })
            .finally(() => {
            });
    }
    const get_coordinators = () => {
        api.get('/api/users/coordinators', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                // eslint-disable-next-line 
                const newData = response.data.map(item => {
                    const { name, id } = item;
                    return { label: name, key: id, name: id };
                });
                setCoordinators(newData)
            })
            .catch(error => {
                console.error(error);
                if (error.response.data.detail === "unauthorized login") {
                    setToken(null)
                }
            })
            .finally(() => {
            });
    }

    //Para executar apenas uma vez
    useEffect(() => {
        get_user();
        get_groups();
        get_coordinators();
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, []);



    const openModal_ = () => {
        setStructUpdated(state);
        setOpenModal(true);
    }
    const openModal_management = () => {
        setManagementStruct({
            is_active: state.is_active,
            is_admin: state.is_admin,
            group: state.group,
            group_id: groups[groups.findIndex(item => item.label === state.group)].key
        })
        const cord = coordinators.findIndex(item => item.key === state.coordenatorid)
        setCoordinator(coordinators[cord]);

        setValue(groups[groups.findIndex(item => item.label === state.group)]);
        setOpenModalManagement(true);
    }

    // eslint-disable-next-line no-unused-vars
    const [severity, setSeverity] = useState("success")
    return (
        <>

            <Loader loader={loader} />

            <Message messageModal={messageModal} messageModalName={messageModalName} severity={severity} setOpenMessage={setOpenMessage} />

            <Box m="20px" sx={{
                position: "block",
                marginLeft: props.collapse === true ? 15 : 40
            }}>
                {/* HEADER */}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title="Utilizador" subtitle="Perfil" />

                    <Box>
                        <Button
                            sx={{
                                backgroundColor: colors.blueAccent[700],
                                color: colors.grey[100],
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                            }}
                        >
                            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
                            Other stuff
                        </Button>
                    </Box>
                </Box>


                <Box
                    gridColumn="span 3"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} textAlign="right" marginTop={2} marginRight={2}>
                            <Grid container spacing={2} direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-start">

                                {/** LETS CALL THIS HEADER */}
                                {(checkPermissions(1) || user.id === id) &&
                                    <Grid item textAlign="right">
                                        <Button variant="contained" color="primary" startIcon={< PhotoCameraOutlinedIcon />} onClick={() => { openModal_() }}>
                                            Mudar fotografia
                                        </Button>
                                    </Grid>
                                }
                                {checkPermissions(1) &&
                                    <Grid item textAlign="right">
                                        <Button variant="contained" color="primary" startIcon={<ModeEditOutlineOutlinedIcon />} onClick={() => { openModal_management() }}>
                                            Editar gestão
                                        </Button>
                                    </Grid>}
                                {(checkPermissions(1) || user.id === id) &&
                                    <Grid item textAlign="right">
                                        <Button variant="contained" color="primary" startIcon={<ModeEditOutlineOutlinedIcon />} onClick={() => { openModal_() }}>
                                            Editar perfil
                                        </Button>
                                    </Grid>}
                            </Grid>

                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={3} sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <img
                                alt="profile-user"
                                width="150px"
                                height="150px"
                                src={process.env.REACT_APP_API_BASE_URL + state.photo}
                                style={{ cursor: "pointer", borderRadius: "50%", objectFit: "cover" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={9}>

                            <Grid container spacing={2} sx={{ 'paddingLeft': 6 }}>

                                <Grid item xs={12} >
                                    <Grid container spacing={2}>
                                        <Grid item xs={8} >

                                            <Header title={state.first_name + " " + state.last_name} subtitle={state.group} />
                                        </Grid>

                                        <Grid item xs={4} textAlign="right" paddingRight={3}>
                                            {state.is_active === true ?
                                                <Typography variant="h3">
                                                    <RadioButtonCheckedOutlinedIcon color="success" />     Conta ativa
                                                </Typography>
                                                :
                                                <Typography variant="h3">
                                                    <RadioButtonCheckedOutlinedIcon color="error" />     Conta intativa
                                                </Typography>
                                            }
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4} md={4} lg={4}>
                                            <Typography variant="h5" gutterBottom>
                                                Contactos
                                            </Typography>
                                            <Typography variant="subtitle2" >
                                                {state.email}
                                            </Typography>
                                            <Typography variant="subtitle2" gutterBottom>
                                                {state.phone}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4} lg={4}>
                                            <Typography variant="h5" gutterBottom>
                                                Localidade
                                            </Typography>
                                            <Typography variant="subtitle2" gutterBottom>
                                                {state.locality}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4} lg={4}>
                                            <Typography variant="h5" gutterBottom>
                                                Data de Nascimento
                                            </Typography>
                                            <Typography variant="subtitle2" gutterBottom>
                                                {state.birthday}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>


                            </Grid>





                        </Grid>
                        <Grid item xs={12}></Grid>

                    </Grid>

                </Box>
            </Box >
            {/** Settings Modal */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={() => { setOpenModal(false); }}
                closeAfterTransition
            >
                <Fade in={openModal}>
                    <Box component="form"
                        noValidate
                        onSubmit={submitEditUser}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 1023,
                            height: 550,
                            bgcolor: colors.primary[400],
                            boxShadow: 24,
                            p: 4,
                        }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{
                                paddingBottom: '10px',
                                borderStyle: 'solid',
                                borderColor: colors.blueAccent[300],
                                borderWidth: '0 0 2px 0'
                            }}>
                                <Typography component={'span'} variant="h3">Alterar utilizador</Typography>
                                <IconButton
                                    aria-label="close"
                                    onClick={() => { setOpenModal(false); }}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                            <Divider flexItem variant="middle" />
                            <Grid item xs={12}>
                                {/*<Grid item xs={3}>
                                        <Grid item xs={12} style={{ display: "flex", justifyContent: 'center' }}>

                                            <Avatar src={preview} sx={{ width: 100, height: 100, fontSize: 15 }} />
                                        </Grid>
                                        <Grid item xs={12} mt={2} style={{ display: "flex", justifyContent: 'center' }}>
                                            <input
                                                required
                                                id="photo"
                                                label="Fotografia"
                                                name="photo"
                                                type="file"
                                                onChange={onSelectFile}
                                            />
                                        </Grid>
                                </Grid>*/}
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextBox
                                            fullWidth
                                            name="first_name"
                                            id="first_name"
                                            label="Primeiro Nome"
                                            variant="outlined"
                                            value={structure.first_name || ""}
                                            onChange={(event) => {
                                                setStructUpdated({ ...structure, 'first_name': event.target.value });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextBox
                                            fullWidth
                                            name="last_name"
                                            id="last_name"
                                            label="Último Nome"
                                            variant="outlined"
                                            value={structure.last_name || ""}
                                            onChange={(event) => {
                                                setStructUpdated({ ...structure, 'last_name': event.target.value });
                                            }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextBox
                                            fullWidth
                                            name="email"
                                            id="email"
                                            label="Email"
                                            variant="outlined"
                                            value={structure.email || ""}
                                            onChange={(event) => {
                                                setStructUpdated({ ...structure, 'email': event.target.value });
                                            }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextBox
                                            fullWidth
                                            name="phone"
                                            id="phone"
                                            label="Telemóvel"
                                            variant="outlined"
                                            value={structure.phone || ""}
                                            onChange={(event) => {
                                                setStructUpdated({ ...structure, 'phone': event.target.value });
                                            }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextBox
                                            fullWidth
                                            name="locality"
                                            id="locality"
                                            label="Localidade/Cidade"
                                            variant="outlined"
                                            value={structure.locality || ""}
                                            onChange={(event) => {
                                                setStructUpdated({ ...structure, 'locality': event.target.value });
                                            }} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                views={['day']}
                                                label="Data de nascimento"
                                                value={date_birth}
                                                onChange={(newValue) => {
                                                    setDate(newValue);
                                                    setStructUpdated({ ...structure, 'birthday': newValue });
                                                }}
                                                renderInput={(params) => <TextBox {...params} />}
                                                name="birthday"
                                                id="birthday"
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth>
                                                <InputLabel id="sexLabel">Sexo</InputLabel>
                                                <Select
                                                    required
                                                    fullWidth
                                                    labelId="sexLabel"
                                                    id="sex"
                                                    label="Sexo"
                                                    name="sex"
                                                    value={sex}
                                                    onChange={(event) => {
                                                        setSex(event.target.value);
                                                        setStructUpdated({ ...structure, 'sex': event.target.value });
                                                    }}
                                                >
                                                    <MenuItem value="masc"> Masculino</MenuItem>
                                                    <MenuItem value="fem"> Feminino</MenuItem>
                                                    <MenuItem value="other"> Outro</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                            pt={4}
                            spacing={2}
                        >
                            <Grid item>
                                <Button variant="contained" color="primary" onClick={() => { setOpenModal(false); }} >Cancelar</Button>
                            </Grid>
                            <Grid item>
                                <Button type="submit" variant="contained" color="success" startIcon={<AddOutlinedIcon />} >Editar perfil</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>

            {/** Management Modal */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModalManagement}
                onClose={() => { setOpenModalManagement(false); }}
                closeAfterTransition
            >
                <Fade in={openModalManagement}>
                    <Box component="form"
                        noValidate
                        onSubmit={submitEditUserManagement}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 800,
                            height: 350,
                            bgcolor: colors.primary[400],
                            boxShadow: 24,
                            p: 4,
                        }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{
                                paddingBottom: '10px',
                                borderStyle: 'solid',
                                borderColor: colors.blueAccent[300],
                                borderWidth: '0 0 2px 0'
                            }}>
                                <Typography component={'span'} variant="h3">Alterar gestão de utilizador</Typography>
                                <IconButton
                                    aria-label="close"
                                    onClick={() => { setOpenModalManagement(false); }}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                            <Divider flexItem variant="middle" />
                            <Grid item xs={12}>
                                {/*<Grid item xs={3}>
                                        <Grid item xs={12} style={{ display: "flex", justifyContent: 'center' }}>

                                            <Avatar src={preview} sx={{ width: 100, height: 100, fontSize: 15 }} />
                                        </Grid>
                                        <Grid item xs={12} mt={2} style={{ display: "flex", justifyContent: 'center' }}>
                                            <input
                                                required
                                                id="photo"
                                                label="Fotografia"
                                                name="photo"
                                                type="file"
                                                onChange={onSelectFile}
                                            />
                                        </Grid>
                                </Grid>*/}
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormLabel id="demo-radio-buttons-group-label">Atividade</FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue={state.is_active ? "active" : "inactive"}
                                            onChange={(e) => {
                                                let check = false
                                                if (e.target.value === "active") {
                                                    check = true;
                                                }
                                                setManagementStruct({ ...managementStruct, is_active: check })
                                            }}
                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel value="active" control={<Radio sx={{
                                                color: colors.greenAccent[100],
                                                '&.Mui-checked': {
                                                    color: colors.greenAccent[100],
                                                },
                                            }} />} label="Ativo" />
                                            <FormControlLabel value="inactive" control={<Radio sx={{
                                                color: colors.greenAccent[100],
                                                '&.Mui-checked': {
                                                    color: colors.greenAccent[100],
                                                },
                                            }} />} label="Inativo" />
                                        </RadioGroup>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormLabel id="demo-radio-buttons-group-label">Administrador</FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue={state.is_active ? "yes" : "no"}
                                            onChange={(e) => {
                                                let check = false
                                                if (e.target.value === "yes") {
                                                    check = true;
                                                }
                                                setManagementStruct({ ...managementStruct, is_admin: check })
                                            }}
                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel value="yes" control={
                                                <Radio sx={{
                                                    color: colors.greenAccent[100],
                                                    '&.Mui-checked': {
                                                        color: colors.greenAccent[100],
                                                    },
                                                }} />
                                            } label="Administrador" />
                                            <FormControlLabel value="no" control={
                                                <Radio sx={{
                                                    color: colors.greenAccent[100],
                                                    '&.Mui-checked': {
                                                        color: colors.greenAccent[100],
                                                    },
                                                }} />
                                            } label="Não Administrador" />
                                        </RadioGroup>
                                    </Grid>

                                    <Grid item>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            value={value}
                                            onChange={(event, newValue) => {
                                                setValue(newValue);
                                                setManagementStruct({ ...managementStruct, group_id: newValue.key })
                                            }}
                                            inputValue={inputValue}
                                            onInputChange={(event, newInputValue) => {
                                                setInputValue(newInputValue);
                                            }}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            options={groups}
                                            sx={{ width: 300 }}
                                            renderInput={(params) => <TextBox {...params} label="Grupo" />}
                                        />
                                    </Grid>
                                    <Grid item>


                                        <Autocomplete
                                            disablePortal
                                            name="coordinator"
                                            id="coordinator"
                                            options={coordinators}
                                            getOptionLabel={(option) => option.label.toString()}
                                            renderInput={(params) => <TextBox {...params} label="Coordenador" />}
                                            isOptionEqualToValue={(option, value) => option.label === value.label}
                                            variant="outlined"
                                            sx={{ backgroundColor: colors.primary[400], width: 300 }}
                                            onChange={(event, value) => {
                                                setCoordinator(value);
                                            }}
                                            value={coordinator || null} // Garante que o valor não seja undefined
                                        />

                                    </Grid>


                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                            pt={4}
                            spacing={2}
                        >
                            <Grid item>
                                <Button variant="contained" color="primary"  >Cancelar</Button>
                            </Grid>
                            <Grid item>
                                <Button type="submit" variant="contained" color="success" startIcon={<AddOutlinedIcon />} >Editar perfil</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        </>
    );

};




export default UserProfile;