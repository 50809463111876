import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Box, useTheme, Grid, IconButton,
  Tab, Typography,
  Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, 
  TableFooter, TablePagination

} from "@mui/material";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { tokens } from "../../theme";
import api from "../../components/confs";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import PropTypes from 'prop-types';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import { useNavigate } from "react-router-dom";


import ClearIcon from '@mui/icons-material/Clear';


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="Primeira página"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Anterior"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Próxima"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Última página"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};



const Game = (props) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [reports, setReports] = useState([])
  const [state, setState] = useState({
    "team1":"",
    "team2":"",
    "association1":"",
    "association2":"",
  })
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [messageModalName, setMessageName] = useState("");
  const [messageModal, setOpenMessage] = useState(false);
  const severity = "success";
  
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - reports.length) : 0;



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  //--------------------------- ON START ---------------------------//


  useEffect(() => {
    get_game_data();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  function get_game_data  () {
    api.get('/api/game/full/' + id, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {
        console.log(response)
        setState(response.data["game"])
        setReports(response.data["report"])
      }
      )
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }
  const navigate = useNavigate();
  const callRoute = (id) => {
    navigate('/report/' + id);
  }
 

  const StyledTab = styled((props) => <Tab {...props} />)({
    "&.Mui-selected": {
      color: colors.blueAccent[500],
      fontSize: 16
    }
  });


  return (
    <>
      <Loader loader={loader} />
      <Message messageModal={messageModal} messageModalName={messageModalName} severity={severity} setOpenMessage={setOpenMessage} />
      <Box m="20px" sx={{
        position: "block",
        marginLeft: props.collapse === true ? 15 : 40
      }}>
        <Grid container mt={4} >
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={5}>
                <Grid container spacing={2}>
                  <Grid item xs={12} justifyContent="flex-end" alignItems="flex-end">
                    <Typography variant="h4" align="right">{state["team1"] }</Typography>
                  </Grid>
                  <Grid item xs={12} justifyContent="flex-end" alignItems="flex-end">
                    <Typography align="right">{state["association1"] }</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2} style={{ display: "flex", justifyContent: 'center' }}>
                <ClearIcon sx={{ fontSize: 40 }} />
              </Grid>
              <Grid item xs={5}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h4" >{state["team2"] }</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {state["association2"] }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={4}>
          <TableContainer
            sx={{ backgroundColor: colors.primary[400] }}
          >
            <Table aria-label="custom pagination table"
            >
              <TableHead>
                <TableRow>
                  <TableCell  >Atleta</TableCell>
                  <TableCell  >Posição</TableCell>
                  <TableCell  >Escalão</TableCell>
                  <TableCell  >Avaliação</TableCell>
                  <TableCell  >Scouter</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? reports.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : reports
                ).map((row) => (
                  <TableRow key={row.id} onClick={() => { 
                     callRoute(row.id)
                   }}
                    hover
                    style={{ cursor: 'pointer' }}>
                    <TableCell>{row.player}</TableCell>
                    <TableCell>{row.posicao}</TableCell>
                    <TableCell >{row.grade}</TableCell>
                    <TableCell>{row.avaliacao}</TableCell>
                    <TableCell>{row.scouter}</TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'Tudo', value: -1 }]}
                    colSpan={6}
                    count={reports.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'Linhas por página',
                      },
                      native: true,
                    }}
                    labelRowsPerPage='Linhas por página'
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelDisplayedRows={(page) =>
                      `${page.from}-${page.to === -1 ? page.count : page.to} de ${page.count
                      }`
                    }
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
        </Grid>


      </Box >
    </>
  );
};

export default Game;