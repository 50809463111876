import CloseIcon from '@mui/icons-material/Close';
import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Box, Button, useTheme, Grid, Typography, Modal, Fade, Divider, Alert,
  Stepper, Step, StepLabel, IconButton
} from "@mui/material";
import { tokens } from "../../theme";
import { Pie } from '@nivo/pie';
import api from "../../components/confs";

import Attributes from '../../components/EvaluationProfileModal/Attributes'
import EvaluationProfileFirstData from '../../components/EvaluationProfileModal/EvaluationProfileFirstData'



const NewProfileEvaluationModal = ({ setMessageName,
  setOpenMessage,
  setSeverity,
  setLoader,
  openNewEvaluationProfileModal,
  setOpenNewEvaluationProfileModal,
  evaluationInfo,
  steps,
  getProfiles,
  setSteps,
  setEvaluationInfo,
  forceLoad,
  setForceLoad
}) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);

  const [attributesChecked, setAttributesChecked] = useState([])
  const [profileState, setProfileState] = useState({ "profile": { "name": "", "goalkeeper": false }, "class_values": [], "attributes": [] });
  const [dataToPieChart, setDataToPieChart] = useState([]);
  const [showPassLimit, setShowPassLimit] = useState([]);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    getProfiles();
    get_all_classes_attributes();
    // eslint-disable-next-line
  }, [])


  const get_all_classes_attributes = () => {
    setLoader(true)
    api.get('/api/evaluation_info', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then((response) => {
        setEvaluationInfo(response.data)

        // Cria um novo array apenas com os nomes das classes
        const classNamesArray = response.data.classes.map((c) => ({ "label": c.name, "id": c.id })).filter(item => item.id !== 0);

        // Atualiza o estado com os nomes das classes
        setSteps([{ "label": 'Dados do perfil', "id": 0 }])
        setSteps((steps) => [...steps, ...classNamesArray]);


        const attr_checked = response.data.attributes.map(attr => ({ class: attr.class_, attribute: attr.attribute, attribute_id: attr.attribute_id }))
        setAttributesChecked(attr_checked);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      }).finally(() => {
        setLoader(false)
      });
  } 


  // Quando mudar dados no estado dos perfis de avaliação
  useEffect(() => {
    const data = get_data_to_piechart()
    setDataToPieChart(data)
    // eslint-disable-next-line
  }, [profileState])


  const handleNext = () => {
    if (showPassLimit === true) {
      setSeverity("warning")
      setMessageName("Não pode ultrupassar os 100% !")
      setOpenMessage(true)
      return
    }
    if (activeStep === 0) {
      if (profileState.name === "" || profileState === null || getValueForClassId(0) === null) {
        setSeverity("warning")
        setMessageName("Não pode avançar sem ter os dados introduzidos!")
        setOpenMessage(true)
        return
      }
    }
    else {

      const percentValue = profileState.class_values.find(obj => obj.class_id === steps[activeStep].id);
      if (percentValue === undefined || percentValue.value === '') {
        setSeverity("warning")
        setMessageName("Não pode avançar sem ter os dados introduzidos!")
        setOpenMessage(true)
        return
      }

      const filteredAttributes = evaluationInfo.attributes.filter(attr => attr.class_ === steps[activeStep].label);
      const attributeList = filteredAttributes.map(attr => ({ attribute: attr.attribute, attribute_id: attr.attribute_id }));

      const attributeIds = attributeList.map(item => item.attribute_id); // criar a lista de attributeIds usando o método map()
      const foundMatch = attributeIds.some(id => profileState.attributes.includes(id)); // verificar se há correspondência entre as listas

      if (!foundMatch && parseInt(percentValue.value) > 0) {
        setSeverity("warning")
        setMessageName("Como o valor percentual é superior a 0, tem que escolher atributos!")
        setOpenMessage(true)
        return
      }
      if (foundMatch && parseInt(percentValue.value) === 0) {
        setSeverity("warning")
        setMessageName("Como o valor percentual é nulo, não pode escolher atributos!")
        setOpenMessage(true)
        return
      }
    }

    if (activeStep + 1 === steps.length) {
      sumbitNewEvaluation();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function getStepContent(step) {
    const ids = steps.map(item => item.id);
    if (step === 0)
      return <EvaluationProfileFirstData colors={colors} setProfileState={setProfileState} profileState={profileState} />;

    const filteredAttributes = evaluationInfo.attributes.filter(attr => attr.class_ === steps[step].label);
    const attributeList = filteredAttributes.map(attr => ({ attribute: attr.attribute, attribute_id: attr.attribute_id }));

    if (ids.includes(steps[step].id)) {
      return <Attributes
        className={steps[step].label}
        classId={steps[step].id}
        colors={colors}
        setProfileState={setProfileState}
        attributeList={attributeList}
        setAttributesChecked={setAttributesChecked}
        attributesChecked={attributesChecked}
        profileState={profileState}
      />
    }
  }

  //Fechar modal
  const closeEvaluationProfileModal = () => {
    setProfileState({
      "profile": {
        "name": "",
      },
      "class_values": [],
      "attributes": []
    });
    setActiveStep(0)
    setOpenNewEvaluationProfileModal(false);
  }

  const sumbitNewEvaluation = () => {
    setLoader(true)
    api.post('/api/evaluation_profile', profileState, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then((response) => {
        setOpenNewEvaluationProfileModal(false)
        setActiveStep(0)
        setProfileState({
          "profile": {
            "name": "",
          },
          "class_values": [],
          "attributes": []
        })

        setSeverity("success")
        setMessageName("Criado com sucesso!")
      })
      .catch(error => {
        console.error(error);
        setSeverity("error")
        setMessageName("Algo deu errado, por favor contacte o administrador!")
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      }).finally(() => {
        setForceLoad(!forceLoad)
        getProfiles()
        setLoader(false)
        setOpenMessage(true)
      });
  }



  function getValueForClassId(classId) {
    const foundClass = profileState.class_values.find(
      (classItem) => classItem.class_id === classId
    );
    if (foundClass) {
      if (foundClass.value === "") {
        return null
      }
      return foundClass.value;
    } else {
      return null;
    }
  }

  const get_data_to_piechart = () => {
    setShowPassLimit(false)

    const classes = profileState.class_values.map((classe) => {
      const classeNome = evaluationInfo.classes.find((nome) => nome.id === classe.class_id)?.name || 'Outros';

      return {
        id: classeNome,
        value: classe.value
      };
    });
    const total = classes.reduce((acc, cur) => acc + parseInt(cur.value === '' ? '0' : cur.value), 0);
    if (total !== 100) {
      if (100 - total > 0) {
        classes.push({ id: "Em falta", value: 100 - total })
      }
      if (100 - total < 0) {
        setShowPassLimit(true)
      }
    }


    return classes
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openNewEvaluationProfileModal}
      onClose={closeEvaluationProfileModal}
      closeAfterTransition
    >
      <Fade in={openNewEvaluationProfileModal}>
        <Box component="form"
          noValidate
          //onSubmit={submitNewTeam}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 900,
            height: 650,
            bgcolor: colors.primary[400],
            boxShadow: 24,
            p: 4,
          }}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{
              paddingBottom: '10px',
              borderStyle: 'solid',
              borderColor: colors.blueAccent[300],
              borderWidth: '0 0 2px 0'
            }}>
              <Typography component={'span'} variant="h3" onClick={() => {  }}>Criação de Perfil de Avaliação para Observação</Typography>
              <IconButton
                aria-label="close"
                onClick={closeEvaluationProfileModal}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Divider flexItem variant="middle" />
            <Grid item xs={12}>
              <Box sx={{ width: '100%' }}>
                <Stepper activeStep={activeStep}>
                  {steps.map(({ label, id }, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                      <Step
                        key={label}
                        {...stepProps}
                      >
                        <StepLabel {...labelProps}
                          StepIconProps={{
                            // Define a cor do ícone como azul somente quando ativo ou concluído
                            style: {
                              color: index === activeStep
                                ? colors.blueAccent[500]
                                : index < activeStep
                                  ? colors.greenAccent[500]
                                  : colors.grey[500],
                            },
                          }}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                {activeStep !== steps.length && (
                  <React.Fragment>
                    <Grid container>
                      <Grid item xs={6}>
                        {getStepContent(activeStep)}
                      </Grid>
                      <Grid item xs={6}>
                        <Pie
                          data={dataToPieChart}
                          width={400}
                          height={400}
                          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                          innerRadius={0.9}
                          padAngle={0.7}
                          cornerRadius={3}
                          colors={{ scheme: 'nivo' }}
                          borderWidth={1}
                          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                          arcLinkLabelsTextColor={colors.primary[100]}
                          animate={true}
                          motionStiffness={90}
                          motionDamping={15}
                          tooltip={(tooltipProps) => {
                            const { datum } = tooltipProps;
                            return (
                              <div style={{ background: '#f1f1f1', color: '#333', border: '1px solid #ccc', borderRadius: '5px', padding: '5px' }}>
                                <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>{datum.label} {`${datum.value}%`}</div>
                              </div>
                            );
                          }}
                        />
                        {
                          showPassLimit &&
                          <Alert variant="filled" severity="warning">
                            Os valores percentuais para a avaliação, ultrupassam os 100% !
                          </Alert>
                        }
                      </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                        variant="contained" color="success"
                      >
                        Voltar
                      </Button>
                      <Box sx={{ flex: '1 1 auto' }} />
                      <Button onClick={handleNext} variant="contained" color="success">
                        {activeStep === steps.length - 1 ? 'Guardar' : 'Próximo'}
                      </Button>
                    </Box>
                  </React.Fragment>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};


export default NewProfileEvaluationModal;