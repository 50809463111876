import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Box, Button, useTheme, Grid, Typography, Tab, Tabs,
  Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow, Paper, IconButton
} from "@mui/material";

import TableHead from '@mui/material/TableHead';

import Header from "../../components/Header";
import Loader from "../../components/Loader";
import AddIcon from '@mui/icons-material/Add';

import { tokens } from "../../theme";
import NewProfileEvaluationModal from './new_profile_evaluation'
import PropTypes from 'prop-types';

import Message from "../../components/Message";
import { styled } from "@mui/material/styles";
import api from "../../components/confs";


import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import EvaluationProfiles from '../../components/EvaluationProfile'
import NewSeasonModal from "./new_season_modal";

import Classes from './classes';


const Settings = (props) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  const [value, setValue] = React.useState(0);
  const [loader, setLoader] = useState(false);
  const [messageModalName, setMessageName] = useState("");
  const [messageModal, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("success")
  const [activeSeason, setActiveSeason] = useState({ name: "", secondary_name: "" })
  const [allSeasons, setAllSeason] = useState([])
  const [openNewEvaluationProfileModal, setOpenNewEvaluationProfileModal] = useState(false)
  const [evaluationInfo, setEvaluationInfo] = useState({ "attributes": [], "class_values": [] })
  const [steps, setSteps] = useState([{ "label": 'Dados do perfil', "id": 0 }]);
  const [grades, setGrades] = useState([]);
  const [gradesInfo, setGradesInfo] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allSeasons.length) : 0;
  const [profiles, setProfiles] = useState([]);

  const [forceLoad, setForceLoad] = useState(false);

  //Quando inicar
  useEffect(() => {
    get_active_season();
    get_all_seasons();
    get_grades();
    getProfiles();
    // eslint-disable-next-line
  }, [])

  //Quando inicar
  useEffect(() => {
    get_active_season();
    get_all_seasons();
    get_grades();
    getProfiles();
    // eslint-disable-next-line
  }, [forceLoad])




  //Para mudar tab
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //Estilo para a tab
  const StyledTab = styled((props) => <Tab {...props} />)({
    "&.Mui-selected": {
      color: colors.blueAccent[500],
      fontSize: 16
    }
  });

  /**------------------ Begin Épocas --------------------------- */
  //Buscar época ativa
  const get_active_season = () => {
    api.get('/api/active_season', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then((response) => {
        setActiveSeason({
          name: response.data.name,
          secondary_name: response.data.secondary_name
        }
        )
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }

  //Buscar todas as épocas
  const get_all_seasons = () => {
    api.get('/api/seasons', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then((response) => {
        setAllSeason(response.data)
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }


  //Para mudar de página na tabela
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  /**------------------ End Épocas --------------------------- */






  //-----------------Perfis de avaliação ------------------------------------//

  //Abrir modal
  function openNewEvaluationProfileModal_function() {
    setOpenNewEvaluationProfileModal(true)
  }



  function get_grades() {

    api.get('/api/grades', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then((response) => {
        const new_data = response.data.map((item) => {
          return {
            label: item.name,
            id: item.age,
          };
        });
        setGrades(new_data)
        setGradesInfo(response.data)
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }



  function getProfiles() {
    setLoader(true)
    api.get('/api/evaluation_profiles', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then((response) => {

        setProfiles(response.data)

      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      }).finally(() => {
        setLoader(false)
      });
  }
  //-----------------END STEPPER ------------------------------------//


  // -----------------Begin New Season -------------------------------//
  const [openSeasonModal, setOpenSeasonModel] = useState(false);

  function openNewSeason_function() {
    setOpenSeasonModel(true)
  }
  // -----------------End New Season -------------------------------//



  return (
    <>
      <Loader loader={loader} />
      <Message messageModal={messageModal} messageModalName={messageModalName} severity={severity} setOpenMessage={setOpenMessage} />
      <Box m="20px" sx={{
        position: "block",
        marginLeft: props.collapse === true ? 15 : 40
      }}>
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Definições" subtitle="Personalize a forma de uso da aplicação" />
        </Box>

        <Box
          sx={{ flexGrow: 1, bgcolor: colors.primary[400], display: 'flex', height: 720 }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            TabIndicatorProps={{ style: { background: colors.blueAccent[500] } }}
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <StyledTab label="Épocas" {...a11yProps(0)} />
            <StyledTab label="Idades por futebol" {...a11yProps(1)} />
            <StyledTab label="Perfis de Avaliação" {...a11yProps(2)} />
            <StyledTab label="Classes de Observação" {...a11yProps(3)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h3">Épocas</Typography>
              </Grid>
              <Grid item xs={6} >

                <Box sx={{ textAlign: 'right' }}>
                  <Button
                    onClick={openNewSeason_function}
                    sx={{
                      backgroundColor: colors.blueAccent[700],
                      color: colors.grey[100],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                    }}
                  >
                    <AddIcon sx={{ mr: "10px" }} />
                    Nova época
                  </Button>

                </Box>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h5">Época ativa: {activeSeason.name + " " + activeSeason.secondary_name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 500, bgcolor: colors.primary[400] }} aria-label="custom pagination table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Nome</TableCell>
                        <TableCell align="center">Nome Secundário</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? allSeasons.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : allSeasons
                      ).map((row) => (
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell style={{ width: 160 }} align="right">
                            {row.secondary_name}
                          </TableCell>
                        </TableRow>
                      ))}

                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                          colSpan={3}
                          count={allSeasons.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: {
                              'aria-label': 'rows per page',
                            },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>


              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h3"> Escalões</Typography>
              </Grid>
              <Grid item xs={12}>
                <div style={{ width: '94%', marginLeft: 28, marginRight: 28, marginTop: 20, marginBottom: 20 }}>
                  <TableContainer sx={{ backgroundColor: colors.primary[400] }}>
                    <Table aria-label="custom pagination table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Escalão</TableCell>
                          <TableCell align="center">Idade</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(gradesInfo).map((row) => (
                          <TableRow key={row.id}
                            hover
                            style={{ cursor: 'pointer' }}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell >{row.age}</TableCell>
                          </TableRow>
                        ))}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Typography variant="h3">Perfis de Avaliação</Typography>
              </Grid>
              <Grid item xs={3} >
                <Box>
                  <Button
                    onClick={openNewEvaluationProfileModal_function}
                    sx={{
                      backgroundColor: colors.blueAccent[700],
                      color: colors.grey[100],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                    }}
                  >
                    <AddIcon sx={{ mr: "10px" }} />
                    Novo Perfil
                  </Button>

                </Box>
              </Grid>
              <Grid item xs={12}>
                <EvaluationProfiles
                  token={token}
                  setToken={setToken}
                  setLoader={setLoader}

                  colors={colors}
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h3">Classes de Avaliação</Typography>
              </Grid>
              {/*<Grid item xs={3} >
                <Box>
                  <Button
                    onClick={openNewClassModal}
                    sx={{
                      backgroundColor: colors.blueAccent[700],
                      color: colors.grey[100],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                    }}
                  >
                    <AddIcon sx={{ mr: "10px" }} />
                    Nova Classe
                  </Button>

                </Box>
                  </Grid>*/}
              <Grid item xs={12}>
                <Classes
                  setLoader={setLoader}
                />
              </Grid>
            </Grid>
          </TabPanel>

        </Box>


      </Box>





      <NewProfileEvaluationModal
        setMessageName={setMessageName}
        setOpenMessage={setOpenMessage}
        setSeverity={setSeverity}
        setLoader={setLoader}
        openNewEvaluationProfileModal={openNewEvaluationProfileModal}
        setOpenNewEvaluationProfileModal={setOpenNewEvaluationProfileModal}
        evaluationInfo={evaluationInfo}
        steps={steps}
        grades={grades}
        getProfiles={getProfiles}
        setSteps={setSteps}
        setEvaluationInfo={setEvaluationInfo}
        forceLoad={forceLoad}
        setForceLoad={setForceLoad}
      />
      <NewSeasonModal
        openModal={openSeasonModal}
        setOpenModal={setOpenSeasonModel}
        grades={gradesInfo}
        setLoader={setLoader}
        setMessageName={setMessageName}
        setOpenMessage={setOpenMessage}
        setSeverity={setSeverity}
        forceLoad={forceLoad}
        setForceLoad={setForceLoad}
      />



    </>
  );
};


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};




function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{
        width: '100%',
      }}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
export default Settings;