import React  from "react";
import {
  Grid, Typography, Divider,Checkbox, FormControlLabel,
} from "@mui/material";

import TextBox from '../../components/TextBox';

export default function EvaluationProfileFirstData({ colors, setProfileState, profileState }) {
  
  
  function updateOrAddClass(newClass) {
      setProfileState((prevState) => {
        // Verifica se o objeto com o mesmo class_id já existe
        const existingClassIndex = prevState.class_values.findIndex(
          (classObj) => classObj.class_id === newClass.class_id
        );
  
        // Se o objeto existir, atualize o valor
        if (existingClassIndex !== -1) {
          return {
            ...prevState,
            class_values: prevState.class_values.map((classObj, index) =>
              index === existingClassIndex ? { ...classObj, value: newClass.value } : classObj
            ),
          };
        } else {
          // Se não existir, adicione um novo objeto
          return {
            ...prevState,
            class_values: [...prevState.class_values, newClass],
          };
        }
      });
    }
    
    const findValueByClassId = (classId) => {
      const classObj = profileState.class_values.find((classObj) => classObj.class_id === classId);
  
      if (classObj) {
        return classObj.value;
      } else {
        // Caso o objeto com a class_id especificada não seja encontrado, retorna um valor padrão (por exemplo, null)
        return "";
      }
    };
    

    return (
      <>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <Typography variant="h3">Dados do perfil:</Typography>
  
          </Grid>
          <Grid item xs={8}>
            <TextBox
              fullWidth
              name="name"
              id="name"
              label="Nome de perfil"
              variant="outlined"
              sx={{ backgroundColor: colors.primary[400] }}
              value={profileState.name}
              onChange={event => {
                setProfileState((prevState) => ({
                  ...prevState,
                  profile: { ...prevState.profile, name: event.target.value }
                }));
              }}
            />
          </Grid>
          <Grid item xs={12} >
          <FormControlLabel
              ml={2}
              control={
                <Checkbox
                  ml={2}
                  checked={profileState.goalkeeper}
                  onChange={event => {
                    setProfileState((prevState) => ({
                      ...prevState,
                      profile: { ...prevState.profile, goalkeeper: !profileState.goalkeeper }
                    }));
                  }}

                  color={profileState.goalkeeper ? 'success' : 'default'}
                />
              }
              label="Guarda Redes"
            />
          </Grid>
        </Grid>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={12} >
            <Typography variant="h3"  >Avaliação:</Typography>
          </Grid>
  
          <Grid item xs={6} >
            <TextBox
              fullWidth
              inputProps={{
                type: 'number',
                inputMode: 'numeric',
              }}
              name="other"
              id="other"
              label="Outros (%)"
              variant="outlined"
              sx={{ backgroundColor: colors.primary[400] }}
              onChange={event => {
                updateOrAddClass({ "class_id": 0, "value": event.target.value })
              }}
              value={findValueByClassId(0)}
            />
          </Grid>

           
        </Grid>
      </>
    )
  }