import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Box, useTheme, Grid, Typography, Modal, Fade,
  Divider, IconButton, Tab, Tabs, TableFooter, TablePagination,
  Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Paper,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { tokens } from "../../theme";
import api from "../../components/confs";

import { styled } from "@mui/material/styles";
import ClearIcon from '@mui/icons-material/Clear';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import PropTypes from 'prop-types';


const ShowGameModal = ({ openModal, setOpenModal, setLoader, GameId }) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);

  const [gameData, setGameData] = useState({
    "association1": "",
    "team1": "",
    "association2": "",
    "team2": "",
    "date": "",
    "local": "",
    "tipo": "",
    "grade": "",
    "obs": "",
    "players1": [],
    "players2": []
  });

  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };


  const StyledTab = styled((props) => <Tab {...props} />)({
    "&.Mui-selected": {
      color: colors.blueAccent[500],
      fontSize: 16
    }
  });


  useEffect(() => {

    if (GameId != null) {

      api.get('/api/game?id=' + GameId, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
        .then(response => {
          setGameData(response.data);
        })
        .catch(error => {
          console.error(error);
          if (error.response.data.detail === "unauthorized login") {
            setToken(null)
          }
        });
    }

    // eslint-disable-next-line
  }, [GameId])

  const [page, setPage] = useState(0);
  const [page2, setPage2] = useState(0);
  const rowsPerPage = 4;
  const rowsPerPage2 = 4;
  // to table 
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - gameData["players1"].length) : 0;
  const emptyRows2 = page2 > 0 ? Math.max(0, (1 + page2) * rowsPerPage2 - gameData["players2"].length) : 0;

  // to table
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangePage2 = (event, newPage) => {
    setPage2(newPage);
  };



  function closeModal() {
    setOpenModal(false);
  }
  const data_ = () => {
    const dateTime = new Date(gameData["date"]);

    const day = dateTime.getDate();
    const month = dateTime.getMonth() + 1; // Os meses no JavaScript começam em 0, então adicionamos 1
    const year = dateTime.getFullYear();
    const hour = dateTime.getHours();
    const minute = dateTime.getMinutes();

    return `${day}/${month}/${year} ${hour}:${minute}`;
  }
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={closeModal}
        closeAfterTransition
      >
        <Fade in={openModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 900,
              height: 700,
              bgcolor: colors.primary[400],
              boxShadow: 24,
              p: 4,
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{
                paddingBottom: '10px',
                borderStyle: 'solid',
                borderColor: colors.blueAccent[300],
                borderWidth: '0 0 2px 0'
              }}>
                <Typography component={'span'} variant="h3">Jogo</Typography>
                <IconButton
                  aria-label="close"
                  onClick={closeModal}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Divider flexItem variant="middle" />
              <Grid item xs={12} mt={2}>

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={5}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} justifyContent="flex-end" alignItems="flex-end">
                          <Typography variant="h4" align="right">{gameData["team1"]}</Typography>
                        </Grid>
                        <Grid item xs={12} justifyContent="flex-end" alignItems="flex-end">
                          <Typography align="right">{gameData["association1"]}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2} style={{ display: "flex", justifyContent: 'center' }}>
                      <ClearIcon sx={{ fontSize: 40 }} />
                    </Grid>
                    <Grid item xs={5}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h4" >{gameData["team2"]}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {gameData["association2"]}

                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} mt={2}>
                  <Tabs value={selectedTab} onChange={handleTabChange}
                    TabIndicatorProps={{ style: { background: colors.blueAccent[500] } }}>
                    <StyledTab label="Dados do Jogo" />
                    <StyledTab label="Atletas a Observar" />
                    <StyledTab label="Relatórios realizados" />
                  </Tabs>
                  <React.Fragment>
                    {selectedTab === 0 && (
                      <Grid container spacing={2} mt={2}>
                        <Grid item xs={12} mt={2} mx={2}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <Typography variant="h4" >
                                    Data e Hora
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography>
                                    {data_()}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}  >
                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <Typography variant="h4" >
                                    Local
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography>
                                    {gameData["local"]}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <Typography variant="h4" >
                                    Escalão
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography>
                                    {gameData["grade"]}
                                  </Typography>
                                </Grid>
                              </Grid>

                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <Typography variant="h4" >
                                    Tipo de jogo
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography>
                                    {gameData["tipo"]}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <Typography variant="h4" >
                                    Observações
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography>
                                    {gameData["obs"]}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {selectedTab === 1 && (
                      <Grid container spacing={2} mt={2}>
                        <Grid item xs={6}>
                          <Paper>
                            <Grid container spacing={2} mt={2}>
                              <Grid item xs={12}>

                                <Typography variant="h4" style={{ display: "flex", justifyContent: 'center' }}>
                                  {gameData["team1"]}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>

                                <Grid container spacing={2} >
                                  <Grid item xs={12}>
                                    <TableContainer sx={{}}>
                                      <Table aria-label="custom pagination table" >
                                        <TableHead>
                                          <TableRow>
                                            <TableCell  >Nome</TableCell>
                                            <TableCell  >Número</TableCell>
                                            <TableCell  >Idade</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {(gameData["players1"].length > 0
                                            ? gameData["players1"].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : gameData["players1"]
                                          ).map((row) => (
                                            <TableRow key={row.id}>
                                              <TableCell>{row.name}</TableCell>
                                              <TableCell>{row.name}</TableCell>
                                              <TableCell >{row.name}</TableCell>
                                            </TableRow>
                                          ))}
                                          {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                              <TableCell colSpan={3} />
                                            </TableRow>
                                          )}
                                        </TableBody>
                                        <TableFooter>
                                          <TableRow>
                                            <TablePagination
                                              rowsPerPageOptions={[2]}
                                              colSpan={3}
                                              count={gameData["players1"].length}
                                              rowsPerPage={rowsPerPage}
                                              page={page}

                                              onPageChange={handleChangePage}
                                              ActionsComponent={TablePaginationActions}
                                              labelDisplayedRows={(page) =>
                                                `${page.from}-${page.to === -1 ? page.count : page.to} de ${page.count
                                                }`
                                              }
                                            />
                                          </TableRow>
                                        </TableFooter>
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                        <Grid item xs={6}>
                          <Paper>
                            <Grid container spacing={2} mt={2}>
                              <Grid item xs={12}>

                                <Typography variant="h4" style={{ display: "flex", justifyContent: 'center' }}>
                                  {gameData["team2"]}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>

                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <TableContainer>
                                      <Table aria-label="custom pagination table" >
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>Nome</TableCell>
                                            <TableCell>Número</TableCell>
                                            <TableCell>Idade</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {(gameData["players2"].length > 0
                                            ? gameData["players2"].slice(page2 * rowsPerPage2, page2 * rowsPerPage2 + rowsPerPage2)
                                            : gameData["players2"]
                                          ).map((row) => (
                                            <TableRow key={row.id}>
                                              <TableCell>{row.name}</TableCell>
                                              <TableCell>{row.name}</TableCell>
                                              <TableCell >{row.name}</TableCell>
                                            </TableRow>
                                          ))}
                                          {emptyRows2 > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows2 }}>
                                              <TableCell colSpan={3} />
                                            </TableRow>
                                          )}
                                        </TableBody>
                                        <TableFooter>
                                          <TableRow>
                                            <TablePagination
                                              rowsPerPageOptions={[2]}
                                              colSpan={3}
                                              count={gameData["players2"].length}
                                              rowsPerPage={rowsPerPage2}
                                              page={page2}

                                              onPageChange={handleChangePage2}
                                              ActionsComponent={TablePaginationActions}
                                              labelDisplayedRows={(page2) =>
                                                `${page2.from}-${page2.to === -1 ? page2.count : page2.to} de ${page2.count
                                                }`
                                              }
                                            />
                                          </TableRow>
                                        </TableFooter>
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                </Grid>

                              </Grid>

                            </Grid>
                          </Paper>
                        </Grid>
                      </Grid>

                    )}
                    {selectedTab === 2 && (
                      <Typography>Relatórios realizados</Typography>
                    )}
                  </React.Fragment>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Fade >
      </Modal >
    </>
  );
};


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="Primeira página"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Anterior"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Próxima"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Última página"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default ShowGameModal;