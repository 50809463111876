import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Box, Button, useTheme, Tab, Tabs,  IconButton, Grid, Autocomplete,
  Table, TableHead, TableBody, TableRow, TableCell, TableContainer, TableFooter, TablePagination,
} from "@mui/material";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import { styled } from "@mui/material/styles";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import TextBox from "../../components/TextBox";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { pt } from "date-fns/locale";
import { useNavigate } from 'react-router-dom';
import { tokens } from "../../theme";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import api from "../../components/confs";

import PropTypes from 'prop-types';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import DownloadIcon from '@mui/icons-material/Download';
const Expenses = (props) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  const [loader, setLoader] = useState(false);
  // eslint-disable-next-line

  const [ExpensesOpened, setExpensesOpened] = useState([])
  const [ExpensesClosed, setExpensesClosed] = useState([])
  const [selectedTab, setSelectedTab] = useState(0);
  let navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [selectedDateStart, setSelectedDateStart] = useState(new Date());
  const [selectedDateEnd, setSelectedDateEnd] = useState(new Date());
  const [state, setState] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const [page1, setPage1] = useState(0);
  const [rowsPerPage1, setRowsPerPage1] = useState(8);

  const page2 = 0;
  const rowsPerPage2 = 8;

  useEffect(() => {
    getExpensesOpened();
    getExpensesClosed();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getExpensesFilter();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [selectedDateStart, selectedDateEnd, state]);


  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0);
  };


  const handleChangePage2 = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage2 = (event) => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const StyledTab = styled((props) => <Tab {...props} />)({
    "&.Mui-selected": {
      color: colors.blueAccent[500],
      fontSize: 16
    }
  });

  function getExpensesOpened() {
    setLoader(true);
    api.get('/api/expenses/open',
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then(response => {
        setExpensesOpened(response.data);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      }).finally(() => {
        setLoader(false);

      });
  }

  function getExpensesClosed() {
    setLoader(true);
    api.get('/api/expenses/close',
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then(response => {
        setExpensesClosed(response.data);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      }).finally(() => {
        setLoader(false);

      });
  }

  function getExpensesFilter() {
    setLoader(true);

    const data = {
      'start': selectedDateStart.getFullYear() + "-" + (selectedDateStart.getMonth() + 1) + "-" + selectedDateStart.getDate(),
      'end': selectedDateEnd.getFullYear() + "-" + (selectedDateEnd.getMonth() + 1) + "-" + selectedDateEnd.getDate(),
      'state': state,
    }
    api.post('/api/expenses/filter', data,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then(response => {
        setSearchResult(response.data);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      }).finally(() => {
        setLoader(false);

      });
  }

  function obterData(data) {
    var dataObj = new Date(data);
    var dia = dataObj.getDate();
    var mes = dataObj.getMonth() + 1; // Lembrando que os meses são indexados em 0
    var ano = dataObj.getFullYear();
    return dia + '-' + mes + '-' + ano;
  }



  const handleDownload = async () => {
    try {
      const data = {
        'start': selectedDateStart.getFullYear() + "-" + (selectedDateStart.getMonth() + 1) + "-" + selectedDateStart.getDate(),
        'end': selectedDateEnd.getFullYear() + "-" + (selectedDateEnd.getMonth() + 1) + "-" + selectedDateEnd.getDate(),
        'state': state,
      }

      const response = await api.post('/api/expenses/report',  data,
      {
        responseType: 'blob', // Definir responseType como 'blob' para receber dados binários
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
      });

      // Criar um URL temporário para o arquivo
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Criar um link de download no navegador e forçar o clique nele
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'dados.xlsx');
      document.body.appendChild(link);
      link.click();

      // Limpar o URL temporário
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Erro ao fazer o download:', error);
    }
  };


  return (
    <>
      <Loader loader={loader} />

      <Box m="20px" sx={{
        position: "block",
        marginLeft: props.collapse === true ? 15 : 40
      }}>
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Despesas" />
          <Box>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
              onClick={() => { navigate('/expenses/new') }}
            >
              <AddOutlinedIcon sx={{ mr: "10px" }} />
              Adicionar Despesa
            </Button>
          </Box>

        </Box>

        <Tabs value={selectedTab} onChange={handleTabChange}
          TabIndicatorProps={{ style: { background: colors.blueAccent[500] } }}>
          <StyledTab label="Em aberto" />
          <StyledTab label="Submetidas" />
          <StyledTab label="Pesquisar" />

        </Tabs>
        {selectedTab === 0 && (
          <Grid container
            backgroundColor={colors.primary[400]}
            alignItems="center"
            justifyContent="center"
            mt={4} >
            <Grid item xs={12} ml={4} mr={4} mt={4} mb={4}>
              <TableContainer sx={{ backgroundColor: colors.primary[400] }}>
                <Table aria-label="custom pagination table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Data</TableCell>
                      <TableCell>Valor</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? ExpensesOpened.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : ExpensesOpened
                    ).map((row, i) => (
                      <TableRow key={"expense-" + i} 
                      onClick={() => { navigate('/expense/'+row.id) }}
                      hover
              >
                        <TableCell>{row.date}</TableCell>
                        <TableCell>{row.amount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[8]}
                        colSpan={8}
                        count={ExpensesOpened.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            'aria-label': 'Linhas por página',
                          },
                          native: true,
                        }}
                        labelRowsPerPage='Linhas por página'
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        labelDisplayedRows={(page) =>
                          `${page.from}-${page.to === -1 ? page.count : page.to} de ${page.count
                          }`
                        }>
                      </TablePagination>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
        {selectedTab === 1 && (

          <Grid container
            backgroundColor={colors.primary[400]}
            alignItems="center"
            justifyContent="center"
            mt={4} >
            <Grid item xs={12} ml={4} mr={4} mt={4} mb={4}>
              <TableContainer sx={{ backgroundColor: colors.primary[400] }}>
                <Table aria-label="custom pagination table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Data</TableCell>
                      <TableCell>Data Submição</TableCell>
                      <TableCell>Valor</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? ExpensesClosed.slice(page1 * rowsPerPage1, page1 * rowsPerPage1 + rowsPerPage1)
                      : ExpensesClosed
                    ).map((row, i) => (
                      <TableRow key={"expense-" + i}>
                        <TableCell>{row.date}</TableCell>
                        <TableCell>{row.data_submicao}</TableCell>
                        <TableCell>{row.amount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[8]}
                        colSpan={8}
                        count={ExpensesClosed.length}
                        rowsPerPage={rowsPerPage1}
                        page={page1}
                        SelectProps={{
                          inputProps: {
                            'aria-label': 'Linhas por página',
                          },
                          native: true,
                        }}
                        labelRowsPerPage='Linhas por página'
                        onPageChange={handleChangePage1}
                        onRowsPerPageChange={handleChangeRowsPerPage1}
                        ActionsComponent={TablePaginationActions}
                        labelDisplayedRows={(page) =>
                          `${page.from}-${page.to === -1 ? page.count : page.to} de ${page.count
                          }`
                        }>
                      </TablePagination>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

        )}
        {selectedTab === 2 && (

          <Grid container
            backgroundColor={colors.primary[400]}
            alignItems="center"
            justifyContent="center"
            mt={4} >
            <Grid item xs={12} ml={4} mr={4} mt={4} mb={4}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pt}>
                    <DatePicker
                      fullWidth
                      label="Data Início"
                      value={selectedDateStart}
                      onChange={(newDate) => { setSelectedDateStart(newDate); }}
                      renderInput={(props) => <TextBox {...props} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pt}>
                    <DatePicker
                      fullWidth
                      label="Data Fim"
                      value={selectedDateEnd}
                      onChange={(newDate) => { setSelectedDateEnd(newDate); }}
                      renderInput={(props) => <TextBox {...props} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    options={["", "Todos", "Submetido", "Por Submeter"]}
                    renderInput={(params) => (
                      <TextBox {...params} label="Estado" variant="outlined" />
                    )}
                    onChange={(event, value) => { setState(value) }}
                    value={state}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Box display="flex" justifyContent="flex-end" alignItems="center">
                    <Button
                      sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                      }}
                      onClick={() => { handleDownload(); }}
                    >
                      <DownloadIcon sx={{ mr: "10px" }} />
                      Exportar Excel
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} ml={4} mr={4} mt={4} mb={4}>
              <TableContainer sx={{ backgroundColor: colors.primary[400] }}>
                <Table aria-label="custom pagination table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Data</TableCell>
                      <TableCell>Data Submição</TableCell>
                      <TableCell>Quantidades</TableCell>
                      <TableCell>Valor</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? searchResult.slice(page2 * rowsPerPage2, page2 * rowsPerPage2 + rowsPerPage2)
                      : searchResult
                    ).map((row, i) => (
                      <TableRow key={"expense-" + i}>
                        <TableCell>{obterData(row.date)}</TableCell>
                        <TableCell>{row.data_submicao ? obterData(row.data_submicao) : ""}</TableCell>
                        <TableCell>{row.count}</TableCell>
                        <TableCell>{row.amount + " €"}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[8]}
                        colSpan={8}
                        count={searchResult.length}
                        rowsPerPage={rowsPerPage2}
                        page={page2}
                        SelectProps={{
                          inputProps: {
                            'aria-label': 'Linhas por página',
                          },
                          native: true,
                        }}
                        labelRowsPerPage='Linhas por página'
                        onPageChange={handleChangePage2}
                        onRowsPerPageChange={handleChangeRowsPerPage2}
                        ActionsComponent={TablePaginationActions}
                        labelDisplayedRows={(page) =>
                          `${page.from}-${page.to === -1 ? page.count : page.to} de ${page.count
                          }`
                        }>
                      </TablePagination>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
      </Box >

    </>
  );
};




function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="Primeira página"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Anterior"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Próxima"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Última página"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};



export default Expenses;