import React from "react";

import {
    Snackbar
} from "@mui/material";

import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const vertical = 'bottom';
const horizontal = 'right';

const Message = ({ messageModal, messageModalName, severity, setOpenMessage }) => (
    <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={messageModal}
        onClose={() => { setOpenMessage(false) }}
        key={vertical + horizontal}
    >
        <Alert severity={severity}>{messageModalName}</Alert>
    </Snackbar>
)
export default Message;