import { useState, useContext, useEffect } from "react";
import { ProSidebar, Menu, MenuItem, SidebarFooter } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import GroupsIcon from '@mui/icons-material/Groups';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import Groups3OutlinedIcon from '@mui/icons-material/Groups3Outlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import PaymentsIcon from '@mui/icons-material/Payments';
import AttributionIcon from '@mui/icons-material/Attribution';
import { UserContext } from '../../context/UserContext';
import api from "../../components/confs";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);


  const [selected, setSelected] = useState("Dashboard");
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const handleLogout = () => {
    setToken(null);
  };

  useEffect(() => {
    props.setIsCollaps(isCollapsed)
    const config = { 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    };
    api.get('/api/reports/events' , config)
      .then((response) => {
        setTempAthletesCount(response.data)
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      })
      .finally(() => {
      });
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [isCollapsed]);


  const [tempAthletesCount, setTempAthletesCount] = useState(0);


  const checkPermission = (id) => {
    return (
      user ? (user.is_admin ? true : (user.permissions.find(permission => permission.permissions === id)?.have)) : false
    )
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >

      <ProSidebar collapsed={isCollapsed} sx={{
        position: "sticky",
      }}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  APP SCOUTING
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)} title="Maximizar/Minimizar menu lateral">
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              {/* <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={props.photo}
                  style={{ cursor: "pointer", borderRadius: "50%", objectFit: "cover" }}
                />
              </Box> */}
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {props.name}
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  {props.group}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {(checkPermission(1) || checkPermission(37) || checkPermission(38) || checkPermission(39)) &&
              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                Administração
              </Typography>
            }


            {checkPermission(1) &&
              <Item
                title="Utilizadores"
                to="/users"
                icon={<PeopleOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />}

            {checkPermission(1) &&
              <Item
                title="Grupos"
                to="/groups"
                icon={<GroupsIcon />}
                selected={selected}
                setSelected={setSelected}
              />}

{/* Dispensado */}
            {/* {(checkPermission(37) || checkPermission(38) || checkPermission(39)) &&
              <Item
                title="Despesas"
                to="/expenses"
                icon={<EuroSymbolOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            }

            {(checkPermission(39)) &&
              <Item
                title="Finanças"
                to="/expense/management"
                icon={<PaymentsIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            } */}


            {(checkPermission(2) || checkPermission(3) || checkPermission(4) ||
              checkPermission(7) || checkPermission(8) || checkPermission(9) ||
              checkPermission(15) || checkPermission(16) || checkPermission(17)
            ) &&
              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                Gestão
              </Typography>
            }
            {(checkPermission(2) || checkPermission(3) || checkPermission(4)) &&
              <Item
                title="Associações"
                to="/associations"
                icon={<AccountBalanceOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            }
            {(checkPermission(7) || checkPermission(8) || checkPermission(9)) &&
              <Item
                title="Equipas"
                to="/teams"
                icon={<ShieldOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            }

            {(checkPermission(15) || checkPermission(16) || checkPermission(17)) &&
              <Item
                title="Atletas"
                to="/players"
                icon={<Groups3OutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            }

          <Item
               title={`Temporários (${tempAthletesCount})`}
                to="/players/temp"
                icon={<AttributionIcon />}
                selected={selected}
                setSelected={setSelected}
              />


            {(checkPermission(26) || checkPermission(27) ||
              checkPermission(24) || checkPermission(25) || checkPermission(34) || checkPermission(35) || checkPermission(36) ||
              checkPermission(31) || checkPermission(32) || checkPermission(33) || checkPermission(28))
              &&
              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                Observações
              </Typography>
            }
            {(checkPermission(26) || checkPermission(27)) &&
              <Item
                title="Planeamento"
                to="/schedule"
                icon={<CalendarMonthIcon />}
                selected={selected}
                setSelected={setSelected}
              />}

            {(checkPermission(24) || checkPermission(25)) &&
              <Item
                title="Jogos"
                to="/games"
                icon={<SportsSoccerIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            }
            {(checkPermission(31) || checkPermission(32) || checkPermission(33)) &&
              <Item
                title="Relatórios"
                to="/report"
                icon={<ContentPasteIcon />}
                selected={selected}
                setSelected={setSelected}
              />}




            {checkPermission(28) &&
              <Item
                title="Planeamentos - Scouters"
                to="/scouter/schedule"
                icon={<CalendarMonthIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            }
            {(checkPermission(34) || checkPermission(35) || checkPermission(36)) &&
              <Item
                title="Equipas Sombra"
                to="/shadow-team"
                icon={<BubbleChartIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            }



          </Box>
        </Menu>
        <SidebarFooter>
          <Menu iconShape="square">
            <MenuItem icon={<LogoutIcon />} onClick={handleLogout} >Logout</MenuItem>
          </Menu>
        </SidebarFooter>
      </ProSidebar>

    </Box>
  );
};

export default Sidebar;