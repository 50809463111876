import React, { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { Box, Button, Typography, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Link } from 'react-router-dom';

const dateConvert = (originalDate) => {
  const date = new Date(originalDate);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  
  if (month < 10) {
    month = `0${month}`;
  }
  
  if (day < 10) {
    day = `0${day}`;
  }
 
  return `${year}-${month}-${day}`
}

const Dashboard = (props) => {
// eslint-disable-next-line
  const {token, setToken, user} = useContext(UserContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px" sx={{
      position: "block",
      marginLeft: props.collapse === true ? 15 : 40
    }}>
    {/* HEADER */}
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Header title="Perfil" subtitle="Bem vindo ao seu perfil" />

      <Box>
        <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }
        }
        component={Link} to="/edit_profile"
        >
          <EditOutlinedIcon sx={{ mr: "10px" }} />
          Editar Perfil
        </Button>
      </Box>
    </Box>

    {/* GRID & CHARTS */}
    <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="250px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 8"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >

          <Box >
            <Box display="flex" justifyContent="center" alignItems="center" mt="50px">
              <img
                alt="profile-user"
                width="100px"
                height="100px"
                src={`../../assets/user.png`}
                style={{ cursor: "pointer", borderRadius: "50%" }}
              />
         
            </Box>
            <Box textAlign="center" mt="10px">

            <Button  variant="filled" >Alterar imagem</Button>


              <Typography
                variant="h2"
                color={colors.grey[100]}
                fontWeight="bold"
                sx={{ m: "10px 0 0 0" }}
              >
                {user.first_name + " " + user.last_name}
              </Typography>
              <Typography variant="h5" color={colors.greenAccent[500]}>
                {user.group}
              </Typography>
              <Typography variant="h5" color={colors.greenAccent[500]}>
                {dateConvert(user.birthday)}
              </Typography>
              <Typography variant="h5" color={colors.greenAccent[500]}>
                {user.email}
              </Typography>
              <Typography variant="h5" color={colors.greenAccent[500]}>
                {user.phone}
              </Typography>
              <Typography variant="h5" color={colors.greenAccent[500]}>
                {user.locality}
              </Typography>
            </Box>
          </Box>
          
          
        </Box>
        </Box>

    </Box>

  );
};

export default Dashboard;