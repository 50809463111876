import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Box, Button, useTheme, Grid,
   IconButton, Table, TableBody, Divider, Paper,
  TableCell, TableContainer, TableHead, TableRow,  
  TableFooter, TablePagination, Typography,   Tab, Tabs, Modal, Fade,
    CardActionArea, CardContent, Card,  Alert
} from "@mui/material";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import { styled } from "@mui/material/styles";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { tokens } from "../../theme";
import api from "../../components/confs";

import PropTypes from 'prop-types';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import CloseIcon from '@mui/icons-material/Close';
import Message from "../../components/Message";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Calendar from "./calendar";
const ScouterSchedule = (props) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  const [loader, setLoader] = useState(false);
  const [messageModalName, setMessageName] = useState("");
  const [messageModal, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("success")

  const [instantDate, setInstantDate] = useState(new Date());
  const [changeView, setChangeView] = useState(new Date());

  const [data, setData] = useState([]);
  const [scheduleData, setScheduleData] = useState(
    {
      "game": {
        "date": "",
        "team1": "",
        "id": 0,
        "team2": "",
        "grade": "",
        "local": "",
        "lat": "",
        "long": "",
        "addredd": "",
        "association1": "",
        "association2": "",
        "season": "",
        "type_of_game": "",
        "obs": "",
      },
      "schedule": {
        "game": 0,
        "first_obs": "",
        "user": 0,
        "aproved": false,
        "view": false,
        "id": 0,
        "answeres": false
      }
    }
  );




  useEffect(() => {
    getImportantDates();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    getImportantDates();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [instantDate])
  useEffect(() => {
    getImportantDates();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [changeView])

  function getImportantDates() {
    const month = changeView.getMonth() + 1;
    const year = changeView.getFullYear();
    api.get('/api/schedules/' + month + '/' + year,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      }).finally(() => {
        setLoader(false);
      });
  }
  function formatarData(data) {
    const dia = data.getDate();
    const mes = data.getMonth() + 1; // Adiciona 1, pois os meses são indexados de 0 a 11
    const ano = data.getFullYear();

    const diaFormatado = dia < 10 ? `0${dia}` : dia;
    const mesFormatado = mes < 10 ? `0${mes}` : mes;

    return `${diaFormatado}/${mesFormatado}/${ano}`;
  }

  function formatarHora(data) {
    const hora = data.getHours();
    const minuto = data.getMinutes();

    const horaFormatada = hora < 10 ? `0${hora}` : hora;
    const minutoFormatado = minuto < 10 ? `0${minuto}` : minuto;

    return `${horaFormatada}:${minutoFormatado}`;
  }

  function filtrarPorData(date) {
    const resultado = data.filter((objeto) => {
      const objetoData = new Date(objeto.date);
      const objetoDataFormatada = objetoData.toLocaleDateString('pt-PT');
      const dataFormatada = date.toLocaleDateString('pt-PT');
      return objetoDataFormatada === dataFormatada && objeto.aproved === true;
    });
    resultado.sort((a, b) => {
      const horaA = new Date(a.date).getHours();
      const horaB = new Date(b.date).getHours();

      // Ordenar em ordem crescente (da menor para a maior hora)
      return horaA - horaB;
    });
    return resultado;
  }

  function filtrarPorDataSchedules(date) {
    const resultado = data.filter((objeto) => {
      const objetoData = new Date(objeto.date);
      const objetoDataFormatada = objetoData.toLocaleDateString('pt-PT');
      const dataFormatada = date.toLocaleDateString('pt-PT');
      return objetoDataFormatada === dataFormatada;
    });
    resultado.sort((a, b) => {
      const horaA = new Date(a.date).getHours();
      const horaB = new Date(b.date).getHours();

      // Ordenar em ordem crescente (da menor para a maior hora)
      return horaA - horaB;
    });
    return resultado;
  }

  function showSchedule(id) {
    setLoader(true);

    api.get('/api/showSchedule/' + id,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then(response => { 
        setScheduleData(response.data);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      }).finally(() => {
        setLoader(false);
    setOpenModal(true)

      });
  }
  function openSchedule(id) {
    showSchedule(id);
    getImportantDates();

  }
  const [openModal, setOpenModal] = useState(false);
  function closeModal() {
    setOpenModal(false)
  }

  const data_ = () => {
    const dateTime = new Date(scheduleData.game["date"]);

    const day = dateTime.getDate();
    const month = dateTime.getMonth() + 1; // Os meses no JavaScript começam em 0, então adicionamos 1
    const year = dateTime.getFullYear();
    const hour = dateTime.getHours();
    const minute = dateTime.getMinutes();

    return `${day}/${month}/${year} ${hour}:${minute}`;
  }
  const [page, setPage] = useState(0);
  const [page2, setPage2] = useState(0);
  const rowsPerPage =4;
  const rowsPerPage2 = 4;
  // to table 
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - scheduleData["players1"].length) : 0;
  const emptyRows2 = page2 > 0 ? Math.max(0, (1 + page2) * rowsPerPage2 - scheduleData["players2"].length) : 0;

  // to table
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangePage2 = (event, newPage) => {
    setPage2(newPage);
  };

  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };


  const StyledTab = styled((props) => <Tab {...props} />)({
    "&.Mui-selected": {
      color: colors.blueAccent[500],
      fontSize: 16
    }
  });

  function reproveSchedule(id) {
    Responder(id, "reprove");
  }
  function aproveSchedule(id) {
    Responder(id, "aprove");
  }
  function Responder(id, response) {
    setLoader(true);

    api.get('/api/schedule/' + response + "/" + id,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then(response => {
        setMessageName("Respondido com sucesso!")
        setSeverity("success")
      })
      .catch(error => {
        console.error(error);
        setMessageName(error)
        setSeverity("error")
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      }).finally(() => {
        getImportantDates();
        setLoader(false);
        setOpenMessage(true);
        setOpenModal(false)
      });
  }

  return (
    <>
      <Loader loader={loader} />
      <Message messageModal={messageModal} messageModalName={messageModalName} severity={severity} setOpenMessage={setOpenMessage} />
      <Box m="20px" sx={{
        position: "block",
        marginLeft: props.collapse === true ? 15 : 40
      }}>
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Planeamentos" subtitle="Pesquise um planeamento" />
        </Box>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={5} lg={4} xl={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container
                  backgroundColor='#121212'
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <Calendar
                      value={instantDate}
                      setValue={setInstantDate}
                      setChangeView={setChangeView}
                      importantDates={data} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container
                  backgroundColor={colors.primary[400]}
                  spacing={2}  >
                  <Grid item xs={12}>
                    <Typography variant="h3" mb={2}> Jogos - {formatarData(instantDate)}</Typography>
                    <Box sx={{ maxHeight: 335, overflow: 'auto' }}>
                      <Grid container spacing={2} my={2}>
                        {filtrarPorData(instantDate).map((element) => {
                          return (
                            <Grid item xs={12} mr={1} key={'card-item-' + element.schedule_id}>
                              <Card sx={{ maxWidth: 720, border: '1px solid ' + colors.blueAccent[400], bgcolor: colors.blueAccent[800] }} key={'card-' + element.schedule_id}>
                                <CardActionArea key={'card-action-area-' + element.schedule_id}>
                                  <CardContent key={'card-content-' + element.schedule_id}>
                                    <Grid container>
                                      <Grid item xs={12}>
                                        <Typography gutterBottom variant="h5" component="div">
                                          {element.team1}   vs   {element.team2}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={10}>
                                        <Typography variant="body1" color="text.secondary" key={'card-time-' + element.schedule_id}>
                                          <strong>Hora:</strong> {formatarHora(new Date(element.date))} h
                                        </Typography>
                                        <Typography variant="body1" color="text.secondary" key={'card-local-' + element.schedule_id}>
                                          <strong>Local:</strong> {element.local}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </CardActionArea>
                              </Card>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={8} xl={9}>
            <Grid container
              backgroundColor={colors.primary[400]}
              spacing={2}>
              <Grid item xs={9}>
                <Box sx={{ maxHeight: 720, overflow: 'auto' }}>
                  <Grid container
                    mb={2}
                    spacing={2} >
                    {filtrarPorDataSchedules(instantDate).map((element) => {
                      var color = "#2e7d32";
                      var state = "success";
                      if (element.view) {
                        if (element.answered) {
                          if (element.aproved) {
                            color = "#2e7d32";
                            state = "success";
                          } else {
                            color = "#d32f2f";
                            state = "rejected";
                          }
                        } else {
                          color = "#014361";
                          state = "not answered";
                        }
                      } else {
                        color = "#ed6c02";
                        state = "not view";
                      }
                      return (
                        <Grid item xs={12} key={'card-item-' + element.schedule_id}>
                          <Card sx={{ maxWidth: 720, bgcolor: color }} key={'card-' + element.schedule_id} onClick={() => { openSchedule(element.schedule_id) }}>
                            <CardActionArea key={'card-action-area-' + element.schedule_id}>
                              <CardContent key={'card-content-' + element.schedule_id}>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography gutterBottom variant="h3" component="div">
                                      {element.team1}   vs   {element.team2}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={10}>
                                    <Typography variant="body1" color="text.secondary" key={'card-date-' + element.schedule_id}>
                                      <strong>Data:</strong> {formatarData(new Date(element.date))}
                                    </Typography>
                                    <Typography variant="body1" color="text.secondary" key={'card-time-' + element.schedule_id}>
                                      <strong>Hora:</strong> {formatarHora(new Date(element.date))} h
                                    </Typography>
                                    <Typography variant="body1" color="text.secondary" key={'card-local-' + element.schedule_id}>
                                      <strong>Local:</strong> {element.local}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={2}>
                                    {state === "success" &&
                                      <DoneAllIcon sx={{ fontSize: 75 }} />
                                    }
                                    {state === "rejected" &&
                                      <ClearIcon sx={{ fontSize: 75 }} />
                                    }
                                    {state === "not answered" &&
                                      <VisibilityIcon sx={{ fontSize: 75 }} />
                                    }
                                    {state === "not view" &&
                                      <VisibilityOffIcon sx={{ fontSize: 75 }} />
                                    }
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h3" gutterBottom>
                  Legenda
                </Typography>
                <Grid container spacing={2} mt={2} mb={2} >
                  <Grid item xs={12} mr={2}>
                    <Alert severity="success" variant="filled" icon={<DoneAllIcon />}>
                      Aprovado
                    </Alert>
                  </Grid>
                  <Grid item xs={12} mr={2}>
                    <Alert severity="error" variant="filled" icon={<ClearIcon />}>
                      Rejeitado
                    </Alert>
                  </Grid>
                  <Grid item xs={12} mr={2}>
                    <Alert severity="info" variant="filled" icon={<VisibilityIcon />}>
                      Visto, mas não respondido
                    </Alert>
                  </Grid>
                  <Grid item xs={12} mr={2}>
                    <Alert severity="warning" variant="filled" icon={<VisibilityOffIcon />}>
                      Não visto
                    </Alert>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={closeModal}
        closeAfterTransition
      >
        <Fade in={openModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 900,
              height: 700,
              bgcolor: colors.primary[400],
              boxShadow: 24,
              p: 4,
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{
                paddingBottom: '10px',
                borderStyle: 'solid',
                borderColor: colors.blueAccent[300],
                borderWidth: '0 0 2px 0'
              }}>
                <Typography component={'span'} variant="h3">Planeamento</Typography>
                <IconButton
                  aria-label="close"
                  onClick={closeModal}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Divider flexItem variant="middle" />
              <Grid item xs={12} mt={2}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={5}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} justifyContent="flex-end" alignItems="flex-end">
                          <Typography variant="h4" align="right">{scheduleData.game["team1"]}</Typography>
                        </Grid>
                        <Grid item xs={12} justifyContent="flex-end" alignItems="flex-end">
                          <Typography align="right">{scheduleData.game["association1"]} </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2} style={{ display: "flex", justifyContent: 'center' }}>
                      <ClearIcon sx={{ fontSize: 40 }} />
                    </Grid>
                    <Grid item xs={5}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h4" align="left">{scheduleData.game["team2"]}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography align="left">{scheduleData.game["association2"]} </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} mt={2}>
                  <Tabs value={selectedTab} onChange={handleTabChange}
                    TabIndicatorProps={{ style: { background: colors.blueAccent[500] } }}>
                    <StyledTab label="Dados do Jogo" />
                    <StyledTab label="Atletas a Observar" />
                    <StyledTab label="Observações para o Observador" />

                  </Tabs>
                  <React.Fragment>
                    {selectedTab === 0 && (
                      <Grid container spacing={2} mt={2}>
                        <Grid item xs={12} mt={2} mx={2}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <Typography variant="h4" >
                                    Data e Hora
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography>
                                    {data_()}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}  >
                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <Typography variant="h4" >
                                    Local
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography>
                                    {scheduleData.game["local"]}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <Typography variant="h4" >
                                    Escalão
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography>
                                    {scheduleData.game["grade"]
                                    }
                                  </Typography>
                                </Grid>
                              </Grid>

                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <Typography variant="h4" >
                                    Tipo de jogo
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography>
                                    {scheduleData.game["type_of_game"]}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <Typography variant="h4" >
                                    Observações
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Box sx={{ overflow: "auto", maxHeight: 150 }}>

                                  <Typography >
                                    {scheduleData.game["obs"]}
                                  </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {selectedTab === 1 && (
                      <Grid container spacing={2} mt={2}>
                        <Grid item xs={6}>
                          <Paper>
                            <Grid container spacing={2} mt={2}>
                              <Grid item xs={12}>

                                <Typography variant="h4" style={{ display: "flex", justifyContent: 'center' }}>
                                  {scheduleData.game["team1"]}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>

                                <Grid container spacing={2} >
                                  <Grid item xs={12}>
                                    <TableContainer sx={{}}>
                                      <Table aria-label="custom pagination table" >
                                        <TableHead>
                                          <TableRow>
                                            <TableCell  >Nome</TableCell>
                                            <TableCell  >Número</TableCell>
                                            <TableCell  >Idade</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {(scheduleData.game["players1"].length > 0
                                            ? scheduleData.game["players1"].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : scheduleData.game["players1"]
                                          ).map((row) => (
                                            <TableRow key={row.id}>
                                              <TableCell>{row.name}</TableCell>
                                              <TableCell>{row.number}</TableCell>
                                              <TableCell >{row.age}</TableCell>
                                            </TableRow>
                                          ))}
                                          {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                              <TableCell colSpan={3} />
                                            </TableRow>
                                          )}
                                        </TableBody>
                                        <TableFooter>
                                          <TableRow>
                                            {<TablePagination
                                              rowsPerPageOptions={[2]}
                                              colSpan={3}
                                              count={scheduleData.game["players1"].length}
                                              rowsPerPage={rowsPerPage}
                                              page={page}

                                              onPageChange={handleChangePage}
                                              ActionsComponent={TablePaginationActions}
                                              labelDisplayedRows={(page) =>
                                                `${page.from}-${page.to === -1 ? page.count : page.to} de ${page.count
                                                }`
                                              }
                                            />}
                                          </TableRow>
                                        </TableFooter>
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>

                          </Paper>
                        </Grid>
                        <Grid item xs={6}>
                          {<Paper>
                            <Grid container spacing={2} mt={2}>
                              <Grid item xs={12}>

                                <Typography variant="h4" style={{ display: "flex", justifyContent: 'center' }}>
                                  {scheduleData.game["team2"]}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>

                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <TableContainer>
                                      <Table aria-label="custom pagination table" >
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>Nome</TableCell>
                                            <TableCell>Número</TableCell>
                                            <TableCell>Idade</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {(scheduleData.game["players2"].length > 0
                                            ? scheduleData.game["players2"].slice(page2 * rowsPerPage2, page2 * rowsPerPage2 + rowsPerPage2)
                                            : scheduleData.game["players2"]
                                          ).map((row) => (
                                            <TableRow key={row.id}>
                                              <TableCell>{row.name}</TableCell>
                                              <TableCell>{row.number}</TableCell>
                                              <TableCell >{row.age}</TableCell>
                                            </TableRow>
                                          ))}
                                          {emptyRows2 > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows2 }}>
                                              <TableCell colSpan={3} />
                                            </TableRow>
                                          )}
                                        </TableBody>
                                        <TableFooter>
                                          <TableRow>
                                            <TablePagination
                                              rowsPerPageOptions={[2]}
                                              colSpan={3}
                                              count={scheduleData.game["players2"].length}
                                              rowsPerPage={rowsPerPage2}
                                              page={page2}

                                              onPageChange={handleChangePage2}
                                              ActionsComponent={TablePaginationActions}
                                              labelDisplayedRows={(page2) =>
                                                `${page2.from}-${page2.to === -1 ? page2.count : page2.to} de ${page2.count
                                                }`
                                              }
                                            />
                                          </TableRow>
                                        </TableFooter>
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                </Grid>

                              </Grid>

                            </Grid>
                          </Paper>
                          }
                        </Grid>
                      </Grid>

                    )}
                    {selectedTab === 2 && (
                      <Grid container spacing={2} mt={3}>
                        <Grid item xs={12}>
                          <Typography variant="h4" >
                            Observações
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                                  <Box sx={{ overflow: "auto", maxHeight: 300 }}>
                          <Typography>
                            {scheduleData.schedule["first_obs"]}
                          </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    )}

                  </React.Fragment>

                </Grid>
                <Grid item xs={12} mt={2}>
                  {
                    !scheduleData.schedule["answeres"] && (
                      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button
                          sx={{ mr: 1 }}
                          variant="contained" color="error"
                          onClick={() => { reproveSchedule(scheduleData.schedule["id"]) }}>
                          Rejeitar
                        </Button>
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => { aproveSchedule(scheduleData.schedule["id"]) }}>
                          Aprovar
                        </Button>
                      </Box>
                    )
                  }
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Fade >
      </Modal >
    </>
  );
};
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="Primeira página"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Anterior"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Próxima"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Última página"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default ScouterSchedule;