import { useState, forwardRef } from 'react';

import {
  Avatar, Button, CssBaseline, MenuItem,
  Select, Link, Grid, Box, Typography, Container, InputLabel, FormControl
} from '@mui/material';

import { createTheme } from '@mui/material/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Loader from "../../components/Loader";
import TextBox from '../../components/TextBox';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


import MuiAlert from '@mui/material/Alert';
import api from "../../components/confs";
const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
// eslint-disable-next-line no-unused-vars
const theme = createTheme();

export default function SignUp() {
  const [sex, setSex] = useState('');
  const [date_birth, setDate] = useState(null);
  const [errorMessage, setError] = useState(false);
  const [passError, setErrorPass] = useState(false);
  const [passSize, setPassSize] = useState(false);
  const [open, setOpen] = useState(false);
  const [ResponseError, setResponseError] = useState(null);
  const [ResponseSaved, setResponseSaved] = useState(null);



  const checkInputs = (event) => {
    const inputs = event.target.querySelectorAll('input');
    // Loop through all inputs and check if they are empty
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].value === "") {
        setError(true);
        return false;
      }
    }

    const data = new FormData(event.currentTarget);
    if (data.get('password').length < 6) {
      setPassSize(true);
      return false;
    }
    if (data.get('password') !== data.get('password2')) {
      setErrorPass(true);
      return false;

    }

    return true
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setOpen(true);
    setError(false);
    setErrorPass(false);
    setPassSize(false);
    setResponseSaved(null);




    if (!checkInputs(event)) {
      setOpen(false);

      return;
    }

    const data = new FormData(event.currentTarget);
    const date = new Date(date_birth)
    date.setDate(date.getDate() + 1);
    data.append("birthday", date.toISOString().slice(0, 10));

    api.post('/api/users', data,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then(response => {
        if (response.data.ok) {
          setResponseError(null);
          setResponseSaved("Registo feito com sucesso!\nAguarde que o Administrador confirme, para poder aceder à sua página!");
        } else {
          setResponseError(response.detail);
          setResponseSaved(null);
        }
      })
      .catch(error => {
        setResponseError(error.response.data.detail);
        setResponseSaved(null);
      });

    setOpen(false);

  };

 

  return (
    <>

      <Loader loader={open} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextBox
                  autoComplete="given-name"
                  name="first_name"
                  required
                  fullWidth
                  id="first_name"
                  label="Primeiro nome"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextBox
                  required
                  fullWidth
                  id="last_name"
                  label="Último nome"
                  name="last_name"
                  autoComplete="last_name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextBox
                  required
                  fullWidth
                  id="email"
                  label="Email "
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextBox
                  required
                  fullWidth
                  id="phone"
                  label="Telemóvel"
                  name="phone"
                  autoComplete="phone"
                />
              </Grid>
              <Grid item xs={12}>
                <TextBox
                  required
                  fullWidth
                  id="locality"
                  label="Localidade/Cidade"
                  name="locality"
                  autoComplete="locality"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    views={['day']}
                    label="Data de nascimento"
                    value={date_birth}
                    onChange={(newValue) => { setDate(newValue); }}
                    renderInput={(params) => <TextBox {...params} />}
                    name="birthday"
                    id="birthday"
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="sexLabel">Sexo</InputLabel>
                    <Select
                      required
                      fullWidth
                      labelId="sexLabel"
                      id="sex"
                      label="Sexo"
                      name="sex"
                      value={sex}
                      onChange={(event) => { setSex(event.target.value) }}
                    >
                      <MenuItem value="masc"> Masculino</MenuItem>
                      <MenuItem value="fem"> Feminino</MenuItem>
                      <MenuItem value="other"> Outro</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextBox
                  required
                  fullWidth
                  id="photo"
                  label="Fotografia"
                  name="photo"
                  type="file"
                />
              </Grid>




              <Grid item xs={12}>
                <TextBox
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete='new-password'
                />
              </Grid>

              <Grid item xs={12}>
                <TextBox
                  required
                  fullWidth
                  name="password2"
                  label="Repetir password"
                  type="password"
                  id="password2"
                  autoComplete='new-password'
                />
              </Grid>
              <Grid item xs={12}>
                {errorMessage ? <Alert severity="warning">Todos os campos são obrigatórios!</Alert> : ""}
              </Grid>
              <Grid item xs={12}>
                {passError ? <Alert severity="info">As passwords não coincidem!</Alert> : ""}
              </Grid>
              <Grid item xs={12}>
                {passSize ? <Alert severity="info">A password tem que ter mais que 6 caracteres!</Alert> : ""}
              </Grid>
              <Grid item xs={12}>
                {ResponseError ? <Alert severity="error">{ResponseError}!</Alert> : ""}
              </Grid>
              <Grid item xs={12}>
                {ResponseSaved ? <Alert severity="success">{ResponseSaved}</Alert> : ""}
              </Grid>

            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="\signin" variant="body2">
                  Já tem uma conta? Entre
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
}

