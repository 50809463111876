import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Box, Button, useTheme, Grid, Typography, Modal, Fade, Divider, Autocomplete, IconButton
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';


import { tokens } from "../../theme";
import api from "../../components/confs";



import TextBox from "../../components/TextBox";



const NewSeasonModal = ({ openModal, setOpenModal, grades, setLoader, setMessageName,
  setOpenMessage, forceLoad,
  setForceLoad,
  setSeverity }) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [profileToSeason, setProfileToSeason] = useState([]);

  const [profilesPlayer, setProfilesPlayer] = useState([]);
  const [profilesGoalKeeper, setProfilesGoalKeeper] = useState([]);


  function changeProfileByGrade(newProfileId, gradeId, goalkeeper) {
    const updatedList = profileToSeason.map(item => {
      if (item.grade_id === gradeId && item.goalkeeper === goalkeeper) {
        return { ...item, profile_id: newProfileId.id };
      }
      return item;
    });
    if (!updatedList.some(item => item.grade_id === gradeId && item.goalkeeper === goalkeeper)) {
      updatedList.push({ grade_id: gradeId, profile_id: newProfileId.id, goalkeeper: goalkeeper });
    }
    setProfileToSeason(updatedList);
  }


  useEffect(() => {
    get_profiles();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    get_profiles();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [openModal])


  function get_profiles() {
    setLoader(true)
    api.get('/api/evaluation_profiles', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then((response) => {
        setProfilesPlayer(response.data.filter(member => member.goalkeeper === false))
        setProfilesGoalKeeper(response.data.filter(member => member.goalkeeper === true))
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      }).finally(() => {
        setLoader(false)
      });


  }

  function closeModal() {
    setOpenModal(false)
  }

  function submitNewSeaston() {
    if (grades.length * 2 !== profileToSeason.length) {
      setMessageName("É necessário preencher todos os campos de perfis de avaliação!");
      setSeverity("warning");
      setOpenMessage(true)
      return
    }
    if (fname.length <= 0) {
      setMessageName("É necessário preencher o nome da época!");
      setSeverity("warning");
      setOpenMessage(true)
      return
    }

    setLoader(true)
    api.post(`/api/season?name=${fname}&secondary_name=${lname}`, profileToSeason,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then((response) => {
        closeModal();
        setMessageName("Iniciada nova época!");
        setSeverity("success");
        setOpenMessage(true)

      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      }).finally(() => {
        setLoader(false)
        setForceLoad(!forceLoad)
      });
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={closeModal}
      closeAfterTransition
    >
      <Fade in={openModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 900,
            height: 730,
            bgcolor: colors.primary[400],
            boxShadow: 24,
            p: 4,
          }}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{
              paddingBottom: '10px',
              borderStyle: 'solid',
              borderColor: colors.blueAccent[300],
              borderWidth: '0 0 2px 0'
            }}>
              <Typography component={'span'} variant="h3">Nova Época</Typography>
              <IconButton
                aria-label="close"
                onClick={closeModal}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Divider flexItem variant="middle" />
            <Grid item xs={12}>
              <Typography variant="h5" m={2} onClick={() => {  }}>Dados da nova época</Typography>

              <Grid container spacing={2} direction="column">
                <Grid item xs={6}>

                  <TextBox
                    name="name"
                    id="name"
                    label="Nome da época"
                    variant="outlined"
                    sx={{ backgroundColor: colors.primary[400] }}
                    onChange={event => { setfname(event.target.value); }}
                    style={{ width: 400 }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextBox
                    fullWidth
                    name="lname"
                    id="lname"
                    label="Nome secundário da época"
                    variant="outlined"
                    sx={{ backgroundColor: colors.primary[400] }}
                    onChange={event => { setlname(event.target.value); }}
                    style={{ width: 400 }}

                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>

                    <Grid item xs={6}>
                      <Typography variant="h5">Perfis de avaliação (Jogador)</Typography>
                      <Box sx={{ overflow: "auto", maxHeight: 300, maxWidth: 320 }} m={2}>
                        {grades.map((item) => (
                          <Box key={item.id} maxWidth={300} mb={2}>
                            <div key={item.id}>
                              <label>{item.name}</label>
                              <Autocomplete
                                options={profilesPlayer}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                  <TextBox {...params} variant="outlined" />
                                )}
                                onChange={(event, value) => { changeProfileByGrade(value, item.id, false) }}
                              />
                            </div>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h5">Perfis de avaliação (Guarda-Redes)</Typography>
                      <Box sx={{ overflow: "auto", maxHeight: 300, maxWidth: 320 }} m={2}>
                        {grades.map((item) => (
                          <Box key={item.id} maxWidth={300} mb={2}>
                            <div key={item.id}>
                              <label>{item.name}</label>
                              <Autocomplete
                                options={profilesGoalKeeper}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                  <TextBox {...params} variant="outlined" />
                                )}
                                onChange={(event, value) => { changeProfileByGrade(value, item.id, true) }}
                              />
                            </div>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>

            </Grid>
            <Grid container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              pt={4}
              spacing={2}>
              <Grid item>
                <Button variant="contained" color="primary" onClick={closeModal}>Cancelar</Button>
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="success" onClick={submitNewSeaston} startIcon={<AddOutlinedIcon />} >Criar</Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>



  );
};


export default NewSeasonModal;