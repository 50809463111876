import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Box, Button, useTheme, Grid,
  Avatar, InputBase, IconButton, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow,
  TableFooter, TablePagination, Typography, Autocomplete, Divider, Modal, Fade
} from "@mui/material";
import Header from "../../components/Header";
import Loader from "../../components/Loader";

import { tokens } from "../../theme";

import api from "../../components/confs";

import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';

import TextBox from '../../components/TextBox';


import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SearchIcon from "@mui/icons-material/Search";
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import CloseIcon from '@mui/icons-material/Close';
import Message from "../../components/Message";


const Teams = (props) => {
  // eslint-disable-next-line
  const { token, setToken, user } = useContext(UserContext);
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  const [state, setState] = useState([])
  const [search_name, setSearchName] = useState('');
  const [loader, setLoader] = useState(false);
  const [associations, setAssociations] = useState();
  const [associationsToNewTeam, setAssociationsToNewTeam] = useState();


  const [messageModal, setOpenMessage] = useState(false);
  const [messageModalName, setMessageName] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);


  const [association_selected, setAssociationSelected] = useState(0);
  const [association_selected_to_new_team, setAssociationSelectedToNewTeam] = useState(0);


  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState()

  const [openTeamModal, setOpenTeamModal] = useState(false);
  const [severity, setSeverity] = useState("success")



  // to table 
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - state.length) : 0;

  // to table
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  // to table
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };





  // To navigate to team page
  const navigate = useNavigate();
  const callRoute = (id) => {
    navigate('/team/' + id);
  }

  // function to create new team

  const submitNewTeam = (event) => {
    event.preventDefault();
    setLoader(true)
    const formData = new FormData(event.currentTarget);
    const data = {
      "name": formData.get('name'),
      "address": formData.get('address'),
      "city": formData.get('city'),
      "lat": formData.get('lat'),
      "long": formData.get('long'),
      "email": formData.get('email'),
      "fax": formData.get('fax'),
      "phone": formData.get('phone'),
      "federation_url": formData.get('federation_url'),
      "website": formData.get('website'),
      "association_id": association_selected_to_new_team,
    }

    const formData2 = new FormData();
    formData2.append('photo', formData.get('photo'));


    if (data.name.trim().length === 0) {
      setSeverity("warning")
      setMessageName("O nome da equipa é um campo obrigatório")
      setOpenMessage(true);
      setLoader(false)
      return;
    }


    api.post('/api/team', { 'photo': formData.get('photo') }, {
      params: data,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {
        setSeverity("success")
        setMessageName("Equipa criada!")
        setOpenMessage(true);
        setPreview("")
        setOpenTeamModal(false);
      })
      .catch(error => {
        setSeverity("error")
        setMessageName(error)
        setOpenMessage(true);
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      })
      .finally(() => {
        get_teams();
        setLoader(false);

      });
  }



  // To show image on create team
  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    setSelectedFile(e.target.files[0])
  }

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  //get all teams
  const get_teams = () => {
    api.get('api/teams', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {
        console.log(response)

        setState(response.data);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }

  // function to get team with filter
  function get_teams_searching() {
    api.get('api/teams/search',
      {
        params: { name: search_name, association: association_selected },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then(response => {
        console.log(response)
        setState(response.data);
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }


  


  // Get Associations to combobox Autocomplete filter
  const get_associations = () => {
    api.get('/api/associations', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then((response) => {
        const all_groups = [{ label: "Todos", id: 0 }]
        const _associations = []
        

        response.data.forEach(g => {
          all_groups.push({ label: g.name, id: g.id, value: g.name });
          _associations.push({ label: g.name, id: g.id, value: g.name });
        });
        
        setAssociations(all_groups)
        setAssociationsToNewTeam(_associations)
      })
      .catch(error => {
        console.error(error);
        if (error.response.data.detail === "unauthorized login") {
          setToken(null)
        }
      });
  }

  // On start
  useEffect(() => {
    get_associations();
    get_teams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      <Loader loader={loader} />
      <Message messageModal={messageModal} messageModalName={messageModalName} severity={severity} setOpenMessage={setOpenMessage} />
      <Box m="20px" sx={{
        position: "block",
        marginLeft: props.collapse === true ? 15 : 40
      }}>
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Equipas" subtitle="Pesquise uma equipa" />
          <Box>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
              onClick={() => { setOpenTeamModal(true); }}
            >
              <AddOutlinedIcon sx={{ mr: "10px" }} />
              Criar Equipa
            </Button>
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box
              display="flex"
              backgroundColor={colors.primary[400]}
              borderRadius="3px"
            >
              <InputBase sx={{ ml: 2, mt: 1, mb: 1, flex: 1 }} placeholder="Procurar Equipas"
                onChange={event => {
                  setSearchName(event.target.value);
                }}
                value={search_name}
                inputProps={{ style: { fontSize: 20 } }} />
              <IconButton type="button" sx={{ p: 1 }}>
                <SearchIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={associations || []}
              renderInput={(params) => <TextBox {...params} label="Associação" />}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              sx={{ backgroundColor: colors.primary[400] }}
              onChange={(event, newvalue) => { setAssociationSelected(newvalue.id) }}
            />
          </Grid>
          <Grid item xs={2}>
          <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
              onClick={() => { get_teams_searching() }}
            >
              <SearchIcon sx={{ mr: "10px" }} />
              Pesquisar
            </Button>
          </Grid>
        </Grid>
        <Grid container
          backgroundColor={colors.primary[400]}
          alignItems="center"
          justifyContent="center"
          mt={4}
        >
          <Grid item xs={12} >
            <div style={{ width: '94%', marginLeft: 28, marginRight: 28, marginTop: 20, marginBottom: 20 }}>
              <TableContainer
                sx={{ backgroundColor: colors.primary[400] }}
              >
                <Table aria-label="custom pagination table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Fotografia</TableCell>
                      <TableCell>Nome</TableCell>
                      <TableCell>Associação</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? state.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : state
                    ).map((row) => (
                      <TableRow key={row.id} onClick={() => { callRoute(row.id) }}
                        hover
                        style={{ cursor: 'pointer' }}>
                        <TableCell sx={{ textAlign: 'center' }}>
                          <img alt={row.name} src={process.env.REACT_APP_API_BASE_URL + "team/photo/" + row.id} style={{ margin: '0 auto', display: 'block' }} width={50} />
                        </TableCell> 
                        <TableCell>{row.name}</TableCell>
                        <TableCell >{row.association}</TableCell>
                      </TableRow>
                    ))}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'Tudo', value: -1 }]}
                        colSpan={4}
                        count={state.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            'aria-label': 'Linhas por página',
                          },
                          native: true,
                        }}
                        labelRowsPerPage='Linhas por página'
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        labelDisplayedRows={(page) =>
                          `${page.from}-${page.to === -1 ? page.count : page.to} de ${page.count
                          }`
                        }
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </div>
          </Grid>
        </Grid>



      </Box>
      {/** Team Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openTeamModal}
        onClose={() => { setOpenTeamModal(false); setPreview(""); }}
        closeAfterTransition
      >
        <Fade in={openTeamModal}>
          <Box component="form"
            noValidate
            onSubmit={submitNewTeam}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 800,
              height: 750,
              bgcolor: colors.primary[400],
              boxShadow: 24,
              p: 4,
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{
                paddingBottom: '10px',
                borderStyle: 'solid',
                borderColor: colors.blueAccent[300],
                borderWidth: '0 0 2px 0'
              }}>
                <Typography component={'span'} variant="h3">Criação de Equipa para a associação</Typography>
                <IconButton
                  aria-label="close"
                  onClick={() => { setOpenTeamModal(false); setPreview(""); }}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Divider flexItem variant="middle" />
              <Grid item xs={12}>
                <Grid container spacing={2} mt={2}>
                  <Grid item xs={3}  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} style={{ display: "flex", justifyContent: 'center' }}>
                        <Avatar alt="Remy Sharp" src={preview} sx={{ width: 100, height: 100, fontSize: 15 }} />
                      </Grid>
                      <Grid item xs={12} style={{ display: "flex", justifyContent: 'center' }}>
                        <input
                          required
                          id="photo"
                          label="Fotografia"
                          name="photo"
                          type="file"
                          onChange={onSelectFile}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={9} >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>

                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={associationsToNewTeam || []}
                          renderInput={(params) => <TextBox {...params} label="Associação" />}
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          sx={{ backgroundColor: colors.primary[400] }}
                          onChange={(event, newvalue) => { setAssociationSelectedToNewTeam(newvalue.id) }}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextBox fullWidth name="name" id="name" label="Nome da Equipa" variant="outlined" required />
                      </Grid>
                      <Grid item xs={8}>
                        <TextBox fullWidth name="address" id="address" label="Morada" variant="outlined" />
                      </Grid>
                      <Grid item xs={4}>
                        <TextBox fullWidth name="city" id="city" label="Cidade" variant="outlined" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextBox fullWidth name="lat" id="lat" label="Latitude" variant="outlined" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextBox fullWidth name="long" id="long" label="Longitude" variant="outlined" />
                      </Grid>
                      <Grid item xs={12}>
                        <TextBox fullWidth name="email" id="email" label="E-mail" variant="outlined" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextBox fullWidth name="fax" id="fax" label="Fax" variant="outlined" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextBox fullWidth name="phone" id="phone" label="Telefone" variant="outlined" />
                      </Grid>
                      <Grid item xs={12}>
                        <TextBox fullWidth name="federation_url" id="federation_url" label="Site na Federação" variant="outlined" />
                      </Grid>
                      <Grid item xs={12}>
                        <TextBox fullWidth name="website" id="website" label="Site" variant="outlined" />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              pt={4}
              spacing={2}
            >
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => { setOpenTeamModal(false); setPreview(""); }}>Cancelar</Button>
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="success" startIcon={<AddOutlinedIcon />} >Criar</Button>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>

    </>
  );
};


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="Primeira página"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Anterior"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Próxima"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Última página"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


export default Teams;